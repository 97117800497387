import React from 'react'
import classNames from 'classnames/bind'

import { RowType, ColumnType } from './GridTypes'
import * as s from './Grid.module.scss'

const cn = classNames.bind(s)

export const Row: RowType = ({ className, verticalGutter, spacing = 'regular', as: Component = 'div', ...props }) => (
  <Component className={cn('row', { verticalGutter, [spacing]: true }, className)} {...props} />
)

const is = Number.isFinite

/*
 * classnames like `colMedium${medium}` are converted to `col--medium--${medium}`
 * in the css module file. For some reason, using hyphens straight away does not seem to work.
 */
export const Column: ColumnType = ({
  initial = 6,
  medium,
  desktop,
  large,
  className,
  spacing = 'regular',
  as: Component = 'div',
  ...props
}) => (
  <Component
    className={cn(
      `col`,
      `col${initial}`,
      !!is(medium) && `colMedium${medium}`,
      !!is(desktop) && `colDesktop${desktop}`,
      !!is(large) && `colLarge${large}`,
      { [spacing]: true },
      className,
    )}
    {...props}
  />
)
