import { useEffect, useState } from 'react'
import { getDatabase, Database } from 'firebase/database'

import { useFirebase } from 'lib/firebase'

export default function useDatabase() {
  const firebase = useFirebase()
  const [database, setDatabase] = useState<undefined | Database>()

  useEffect(() => {
    if (!firebase) return
    setDatabase(getDatabase())
  }, [firebase])

  return database
}
