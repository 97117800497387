import React, { useState, useEffect } from 'react'
import { useRafLoop } from 'react-use'
import classNames from 'classnames/bind'
import { motion, useSpring } from 'framer-motion'

import useUIContext from 'context/ui'
import Clickable from 'components/core/Clickable'

import { ISpinningButton } from './SpinningButtonTypes'
import * as s from './SpinningButton.module.scss'

const cn = classNames.bind(s)

const SpinningButton = ({
  children,
  className,
  imageClassName,
  spinningText,
  label,
  onClick,
  to,
  ...props
}: ISpinningButton) => {
  const [hover, setHover] = useState<boolean>(false)
  const rotate = useSpring(0, { stiffness: 300 })
  const setTheme = useUIContext(s => s.setTheme)

  const [loopStop, loopStart] = useRafLoop(() => {
    rotate.set(rotate.get() + (hover ? 3 : 0.6))
  })

  useEffect(() => {
    loopStart()
    return () => loopStop()
  }, [loopStart, loopStop])

  return (
    <motion.div
      className={cn('container', className)}
      onPointerEnter={() => {
        setTheme('dark')
        setHover(true)
      }}
      onPointerLeave={() => setHover(false)}
    >
      <Clickable className={cn('clickable')} to={to} onClick={onClick} aria-label={label} {...props}>
        <div className={cn('content')}>
          <motion.div
            style={{ rotate }}
            className={cn('image', imageClassName)}
            dangerouslySetInnerHTML={{ __html: spinningText }}
            aria-hidden='true'
          />
          {children}
        </div>
      </Clickable>
    </motion.div>
  )
}

export default SpinningButton
