import React from 'react'
import classNames from 'classnames/bind'
import { Link } from 'gatsby'

import Cross from 'assets/svg/cross.react.svg'
import useCopyContext from 'context/copy'

import { CloseButtonProps } from './CloseButtonTypes'
import * as s from './CloseButton.module.scss'

const cn = classNames.bind(s)

const CloseButton = ({ to, className, state }: CloseButtonProps) => {
  const copy = useCopyContext(s => s.copy)
  return (
    <Link to={to} className={cn('wrapper', className)} state={state} aria-label={copy.back_to_home}>
      <Cross className={cn('icon')} aria-hidden='true' />
    </Link>
  )
}

export default CloseButton
