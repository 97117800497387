import React from 'react'
import classNames from 'classnames/bind'
import { graphql, useStaticQuery } from 'gatsby'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'
import { useLocation } from '@reach/router'

import { CircularHeading4, MonumentHashtag, CircularBody, MonumentSubtitle } from 'components/ui/Text'
import getLocalizedData from 'lib/getLocalizedData'
import Spacer from 'components/core/Spacer'
import { FullscreenPopup } from 'components/ui/Popup'
import Logo from 'assets/svg/undp_logo.react.svg'

import { IContainer } from './FeedbackFormTypes'
import * as s from './Container.module.scss'

const cn = classNames.bind(s)

const FeedbackFormContainerQuery = graphql`
  query FeedbackFormContainerQuery {
    allPrismicSiteSettings {
      edges {
        node {
          _previewable
          lang
          data {
            form_description
            form_title {
              text
            }
          }
        }
      }
    }
  }
`

const Container = ({
  children,
  delay,
  description,
  keepNoScrollOnClose,
  onClose,
  open,
  popupX,
  popupY,
  title,
  titleNarrow,
  inFocus,
}: IContainer) => {
  const staticData = useStaticQuery(FeedbackFormContainerQuery)
  const { data } = useMergePrismicPreviewData(staticData)
  const { pathname } = useLocation()
  const prismicSiteSettings = getLocalizedData(pathname, data.allPrismicSiteSettings)
  if (!prismicSiteSettings?.data) return null

  return (
    <FullscreenPopup
      open={open}
      title={prismicSiteSettings.data.form_description}
      onClose={onClose}
      popupX={popupX || 0}
      popupY={popupY || 0}
      delay={delay || 0}
      keepNoScrollOnClose={keepNoScrollOnClose || false}
      inFocus={inFocus}
    >
      <div className={cn('content')}>
        <div className={cn('top')}>
          <Logo className={cn('logo')} />
          <Spacer initial='0.75rem' desktop='1.11vw' />
          <MonumentHashtag className={cn('description')}>{prismicSiteSettings.data.form_title.text}</MonumentHashtag>
          <Spacer initial='1.5625rem' desktop='2.7vw' />
          {description ? (
            <>
              <MonumentSubtitle className={cn('titleLarge', { narrow: titleNarrow })}>{title}</MonumentSubtitle>
              <Spacer initial='0.25rem' desktop='1.4vw' />
              <CircularBody weight='book' className={cn('description')}>
                {description}
              </CircularBody>
            </>
          ) : (
            <CircularHeading4 className={cn('title', { narrow: titleNarrow })}>{title}</CircularHeading4>
          )}
        </div>
        <div className={cn('children')}>{children}</div>
      </div>
    </FullscreenPopup>
  )
}

export default Container
