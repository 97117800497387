export const variants = {
  animate: {
    y: '0vw',
    x: '-50%',
    opacity: 1,
    transition: {
      y: {
        type: 'spring',
        stiffness: 150,
      },
    },
  },
  exit: {
    y: '1vw',
    x: '-50%',
    opacity: 0,
    transition: {
      y: {
        type: 'spring',
        stiffness: 150,
      },
    },
  },
}
