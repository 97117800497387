import React from 'react'
import Helmet from 'react-helmet'

import useUIContext from 'context/ui'

const ThemeMode = () => {
  const theme = useUIContext(s => s.theme)
  const themeSecondary = useUIContext(s => s.themeSecondary)
  return <Helmet htmlAttributes={{ 'data-theme': theme, 'data-theme-secondary': themeSecondary }} />
}

export default ThemeMode
