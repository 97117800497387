import React from 'react'
import classNames from 'classnames/bind'

import * as s from './Highlight.module.scss'
import { HighlightProps } from './HighlightTypes'

const cn = classNames.bind(s)

const Highlight = ({ children, highlightColor }: HighlightProps) => {
  return (
    <strong
      className={cn('text', { customHighlightColor: !!highlightColor })}
      style={{ '--custom-highlight-color': highlightColor } as React.CSSProperties}
    >
      {children}
    </strong>
  )
}

export default Highlight
