import React from 'react'
import classNames from 'classnames/bind'
import { motion, AnimatePresence } from 'framer-motion'

import { CircularCaption } from 'components/ui/Text'
import useCopyContext from 'context/copy'

import { containerVariants, plusOneVariants } from './variants'
import * as s from './Notification.module.scss'

const cn = classNames.bind(s)

const Notification = ({
  animate,
  availableVotes,
  viewed,
}: {
  animate: number | boolean | undefined
  availableVotes: number
  viewed: boolean
}) => {
  const copy = useCopyContext(s => s.copy)

  return (
    <motion.div
      className={cn('container', { viewed, glow: animate, large: availableVotes > 9 })}
      variants={containerVariants}
      initial='initial'
      animate='animate'
      exit='exit'
    >
      <div className={cn('text')}>
        <AnimatePresence>
          <motion.span key={`span-${availableVotes}`} initial={{ scale: 0 }} animate={{ scale: 1 }}>
            <CircularCaption
              as='p'
              aria-live='polite'
              aria-label={`${availableVotes} ${copy.available_votes?.toLowerCase()}`}
              role='status'
            >
              {availableVotes}
            </CircularCaption>
          </motion.span>
          <motion.span
            aria-hidden='true'
            className={cn('plus')}
            variants={plusOneVariants}
            initial={false}
            transition={{ duration: 1.5, ease: [0.175, 0.885, 0.32, 1.05] }}
            animate={animate ? 'visible' : 'hidden'}
          >
            +1
          </motion.span>
        </AnimatePresence>
      </div>
    </motion.div>
  )
}

export default Notification
