import React from 'react'
import classNames from 'classnames/bind'
import { MotionValue } from 'framer-motion'

import { MonumentHeading2 } from 'components/ui/Text'
import ScrollParallax from 'components/core/ScrollParallax'

import * as s from './Intro.module.scss'
const cn = classNames.bind(s)

const Section4 = ({ scrollY, toggle }: { scrollY: MotionValue; toggle: (exit: boolean) => void }) => (
  <section className={cn('section', 'center', 'section4')}>
    <ScrollParallax
      absolute
      className={cn('fullscreen')}
      motionValue={scrollY}
      keyframes={{ opacity: [0, 1] }}
      times={{
        opacity: [0, 1],
      }}
      startOffset='800vh'
      stopOffset='800vh'
    >
      <div className={cn('bg', 'fullscreen')} />
    </ScrollParallax>
    <ScrollParallax
      absolute
      className={cn('fullscreen', 'center')}
      motionValue={scrollY}
      keyframes={{ y: ['0vh', '-20vh'] }}
      onChange={(progress: number) => {
        if (progress > 0.2) toggle(true)
        else if (progress < 0.2) toggle(false)
      }}
      times={{
        y: [0, 1],
      }}
      startOffset='1070vh'
      stopOffset='1070vh'
    >
      <div className={cn('wrapper', 'center')} aria-hidden='true'>
        <ScrollParallax
          motionValue={scrollY}
          keyframes={{ opacity: [0, 1], scale: [0.7, 1] }}
          times={{
            opacity: [0, 0.5],
            scale: [0, 0.75],
          }}
          startOffset='865vh'
          stopOffset='865vh'
        >
          <div className={cn('top', 'padSafari')}>
            <MonumentHeading2 className={cn('text')}>Förstå världen</MonumentHeading2>
            <div className={cn('clipped1')}>
              <ScrollParallax
                motionValue={scrollY}
                keyframes={{ opacity: [0, 1] }}
                times={{
                  opacity: [0, 0.5],
                }}
                startOffset='1040vh'
                stopOffset='1040vh'
              >
                <MonumentHeading2 className={cn('text')}>Förstå världen</MonumentHeading2>
              </ScrollParallax>
            </div>
            <div className={cn('clipped2')}>
              <ScrollParallax
                motionValue={scrollY}
                keyframes={{ opacity: [0, 1] }}
                times={{
                  opacity: [0, 0.5],
                }}
                startOffset='1045vh'
                stopOffset='1045vh'
              >
                <MonumentHeading2 className={cn('text')}>Förstå världen</MonumentHeading2>
              </ScrollParallax>
            </div>
            <div className={cn('clipped3')}>
              <ScrollParallax
                motionValue={scrollY}
                keyframes={{ opacity: [0, 1] }}
                times={{
                  opacity: [0, 0.5],
                }}
                startOffset='1050vh'
                stopOffset='1050vh'
              >
                <MonumentHeading2 className={cn('text')}>Förstå världen</MonumentHeading2>
              </ScrollParallax>
            </div>
            <div className={cn('clipped4')}>
              <ScrollParallax
                motionValue={scrollY}
                keyframes={{ opacity: [0, 1] }}
                times={{
                  opacity: [0, 0.5],
                }}
                startOffset='1055vh'
                stopOffset='1055vh'
              >
                <MonumentHeading2 className={cn('text')}>Förstå världen</MonumentHeading2>
              </ScrollParallax>
            </div>
            <div className={cn('clipped5')}>
              <ScrollParallax
                motionValue={scrollY}
                keyframes={{ opacity: [0, 1] }}
                times={{
                  opacity: [0, 0.5],
                }}
                startOffset='1060vh'
                stopOffset='1060vh'
              >
                <MonumentHeading2 className={cn('text')}>Förstå världen</MonumentHeading2>
              </ScrollParallax>
            </div>
            <div className={cn('clipped6')}>
              <ScrollParallax
                motionValue={scrollY}
                keyframes={{ opacity: [0, 1] }}
                times={{
                  opacity: [0, 0.5],
                }}
                startOffset='1065vh'
                stopOffset='1065vh'
              >
                <MonumentHeading2 className={cn('text')}>Förstå världen</MonumentHeading2>
              </ScrollParallax>
            </div>
            <div className={cn('clipped7')}>
              <ScrollParallax
                motionValue={scrollY}
                keyframes={{ opacity: [0, 1] }}
                times={{
                  opacity: [0, 0.5],
                }}
                startOffset='1075vh'
                stopOffset='1075vh'
              >
                <MonumentHeading2 className={cn('text')}>Förstå världen</MonumentHeading2>
              </ScrollParallax>
            </div>
          </div>
        </ScrollParallax>
        <ScrollParallax
          motionValue={scrollY}
          keyframes={{ opacity: [1, 0.25, 0], y: ['0%', '100%'], scale: [1, 0.8] }}
          times={{
            opacity: [0, 0.3, 0.45],
            scale: [0, 0.75],
            y: [0, 0.5],
          }}
          startOffset='1020vh'
          stopOffset='1020vh'
        >
          <div>
            <ScrollParallax
              motionValue={scrollY}
              keyframes={{ opacity: [0, 1], scale: [0.7, 1] }}
              times={{
                opacity: [0, 0.5],
                scale: [0, 0.75],
              }}
              startOffset='885vh'
              stopOffset='885vh'
            >
              <MonumentHeading2 className={cn('text')}>bättre och var med och</MonumentHeading2>
            </ScrollParallax>
            <ScrollParallax
              motionValue={scrollY}
              keyframes={{ opacity: [0, 1], scale: [0.7, 1] }}
              times={{
                opacity: [0, 0.5],
                scale: [0, 0.75],
              }}
              startOffset='905vh'
              stopOffset='905vh'
            >
              <MonumentHeading2 className={cn('text')}>forma framtiden.</MonumentHeading2>
            </ScrollParallax>
          </div>
        </ScrollParallax>
      </div>
      <p className={cn('srOnly')}>Förstå världen bättre och var med och forma framtiden.</p>
    </ScrollParallax>
  </section>
)

export default Section4
