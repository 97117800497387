// extracted by mini-css-extract-plugin
export var colorBlack = "#000";
export var colorGray = "gray";
export var colorDarkGray = "#262626";
export var colorLightGray = "#e5e5e5";
export var colorWhite = "#fff";
export var colorElectricBlue = "#0000fe";
export var colorPastelBlue = "#bbe5fe";
export var colorCandyPink = "#f9709a";
export var colorPastelPink = "#ffcdd2";
export var colorDefaultLime = "#cef564";
export var colorPastelLime = "#eeffbe";
export var colorBlueLagoon = "#9cf0e1";
export var colorPastelLagoon = "#ceffec";
export var colorDefaultLavender = "#8738f6";
export var colorPastelLavender = "#eadcff";
export var colorBrightYellow = "#ffe500";
export var colorDefaultGreen = "#12b968";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var colCount = "6";
export var colWidth = "16.6666666667%";
export var monumentRegular = "Text-module--monumentRegular--2PQiI";
export var monumentBlack = "Text-module--monumentBlack--pVVgp";
export var monumentH1 = "Text-module--monumentH1--1jkuZ";
export var monumentH2 = "Text-module--monumentH2--256iE";
export var monumentSubtitle = "Text-module--monumentSubtitle--6mXEX";
export var monumentCard = "Text-module--monumentCard--1AHi7";
export var monumentHashtag = "Text-module--monumentHashtag--11KYN";
export var monumentNavigation = "Text-module--monumentNavigation--1jD6J";
export var monumentCaption = "Text-module--monumentCaption--1Lff1";
export var monumentProgress = "Text-module--monumentProgress--3aOy1";
export var monumentSteps = "Text-module--monumentSteps--3osvT";
export var circularBook = "Text-module--circularBook--1h4Xe";
export var circularBold = "Text-module--circularBold--1gnMv";
export var circularH1 = "Text-module--circularH1--2PeL8";
export var circularH2 = "Text-module--circularH2--ykoS9";
export var circularH3 = "Text-module--circularH3--iZMXi";
export var circularH4 = "Text-module--circularH4--1EDV2";
export var circularBodyLarge = "Text-module--circularBodyLarge--1ENz_";
export var circularBody = "Text-module--circularBody--QFNXh";
export var circularCaption = "Text-module--circularCaption--2aq9o";
export var circularStory = "Text-module--circularStory--27_F-";
export var circularSteps = "Text-module--circularSteps--2U9Q6";