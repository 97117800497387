import React, { useMemo } from 'react'
import classNames from 'classnames/bind'
import { MotionValue } from 'framer-motion'
import { easeSinOut } from 'd3-ease'

import { MonumentHeading1, MonumentSubtitle } from 'components/ui/Text'
import ScrollParallax from 'components/core/ScrollParallax'

import * as s from './Intro.module.scss'
const cn = classNames.bind(s)

const Section3 = ({ scrollY }: { scrollY: MotionValue }) => {
  const text = useMemo(() => ['vårt sätt att', 'leva utsätter', 'planeten för', 'stor press'], [])

  return (
    <section className={cn('section', 'center', 'section3')}>
      <ScrollParallax
        absolute
        className={cn('fullscreen')}
        motionValue={scrollY}
        keyframes={{ opacity: [0, 1] }}
        times={{
          opacity: [0, 1],
        }}
        startOffset='580vh'
        stopOffset='580vh'
      >
        <div className={cn('bg', 'fullscreen')} />
      </ScrollParallax>
      <ScrollParallax
        absolute
        className={cn('fullscreen', 'center')}
        motionValue={scrollY}
        keyframes={{ x: ['100%', '0%'], skewX: [-8, 0] }}
        times={{
          x: [0, 0.75],
          skewX: [0.1, 1],
        }}
        startOffset='600vh'
        stopOffset='600vh'
        ease={easeSinOut}
        aria-hidden='true'
      >
        <div className={cn('wrapper', 'center')}>
          <ScrollParallax
            motionValue={scrollY}
            keyframes={{ x: ['0%', '-8vw'] }}
            times={{
              x: [0, 0.5],
            }}
            startOffset='700vh'
            stopOffset='700vh'
          >
            <div className={cn('left', 'center')}>
              <MonumentHeading1 as='span' className={cn('heroLetter')}>
                <ScrollParallax
                  motionValue={scrollY}
                  keyframes={{ y: ['0vh', '-80vh'], opacity: [1, 0] }}
                  times={{
                    y: [0, 1],
                    opacity: [0, 0.4],
                  }}
                  startOffset='820vh'
                  stopOffset='820vh'
                >
                  M
                </ScrollParallax>
              </MonumentHeading1>
            </div>
          </ScrollParallax>
          <ScrollParallax
            motionValue={scrollY}
            keyframes={{ x: ['0%', '11vw'] }}
            times={{
              x: [0.25, 0.6],
            }}
            startOffset='700vh'
            stopOffset='700vh'
          >
            <div className={cn('right', 'center')}>
              <MonumentHeading1 as='span' className={cn('heroLetter')}>
                <ScrollParallax
                  motionValue={scrollY}
                  keyframes={{ y: ['0vh', '20vh'], opacity: [1, 0] }}
                  times={{
                    y: [0, 1],
                    opacity: [0, 0.4],
                  }}
                  startOffset='820vh'
                  stopOffset='820vh'
                >
                  e
                </ScrollParallax>
              </MonumentHeading1>
              <MonumentHeading1 as='span' className={cn('heroLetter')}>
                <ScrollParallax
                  motionValue={scrollY}
                  keyframes={{ y: ['0vh', '80vh'], opacity: [1, 0] }}
                  times={{
                    y: [0, 1],
                    opacity: [0, 0.4],
                  }}
                  startOffset='820vh'
                  stopOffset='820vh'
                >
                  n
                </ScrollParallax>
              </MonumentHeading1>
            </div>
          </ScrollParallax>
          <MonumentSubtitle as='div' className={cn('heroInfo')}>
            <ScrollParallax
              motionValue={scrollY}
              keyframes={{ y: ['0vh', '-20vh'], opacity: [1, 0] }}
              times={{
                y: [0, 1],
                opacity: [0, 0.4],
              }}
              startOffset='820vh'
              stopOffset='820vh'
            >
              <div className={cn('mask')}>
                {text.map((item, index) => (
                  <ScrollParallax
                    key={`maskedMobile-${index}`}
                    motionValue={scrollY}
                    keyframes={{ x: ['-100%', '0%'] }}
                    times={{ x: [0.2 + index * 0.05, 0.9] }}
                    startOffset='680vh'
                    stopOffset='680vh'
                  >
                    <p className={index === 0 ? cn('padSafari') : ''}>{item}</p>
                  </ScrollParallax>
                ))}
              </div>
            </ScrollParallax>
          </MonumentSubtitle>
        </div>
      </ScrollParallax>
      <p className={cn('srOnly')}>Men vårt sätt att leva utsätter planeten för stor press.</p>
    </section>
  )
}

export default Section3
