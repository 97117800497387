import React from 'react'
import { Link } from 'gatsby'

import { AnchorProps, ClickableProps, ButtonProps } from './ClickableTypes'

const externalProps = { target: '_blank', rel: 'noopener noreferrer' }

const Anchor = ({ to, state, className, style, children, target, onClick, tabIndex, ...p }: AnchorProps) => {
  const propsHash = { className, style, target, tabIndex }
  const props = { ...propsHash, ...p, onClick }

  const onClickHash = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    const element = document.querySelector(to)

    if (element) {
      element.scrollIntoView(
        p.alignToTop
          ? { block: 'start', inline: 'nearest', behavior: 'smooth' }
          : { behavior: 'smooth', block: 'start' },
      )
      event.preventDefault() // prevent default to enable smooth scrolling
    }

    if (onClick) onClick(event)
  }

  // internal links
  if (/^[.#]/.test(to)) {
    return (
      <Link {...propsHash} to={to} state={state} onClick={onClickHash} tabIndex={tabIndex}>
        {children}
      </Link>
    )
  }

  if (/^[./]/.test(to)) {
    return (
      <Link {...props} to={to} state={state}>
        {children}
      </Link>
    )
  }

  // external links
  if (/^https?:/.test(to))
    return (
      <a {...p} {...props} href={to} {...externalProps}>
        {children}
      </a>
    )

  // fallback for anything strange
  return (
    <a {...p} {...props} href={to}>
      {children}
    </a>
  )
}

const Clickable = ({ to, state, ...props }: ClickableProps) => {
  if (!to) return <button {...(props as ButtonProps)} />

  return <Anchor {...(props as AnchorProps)} to={to} state={state} />
}

export default Clickable
