import React, { useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useLocation } from '@reach/router'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'
import atob from 'atob'
import classNames from 'classnames/bind'

import getLocalizedData from 'lib/getLocalizedData'
import getLocalizedPath from 'lib/getLocalizedPath'
import Play from 'assets/svg/play.react.svg'

import { defaultLanguage } from '../../../../prismic-configuration'
import * as s from './SpinningButtonStories.module.scss'
import SpinningButton from './SpinningButton'

const cn = classNames.bind(s)

const getDecodedData = (str: string) => {
  const dataStr = 'data:image/svg+xml;base64,'
  return str.slice(str.indexOf(dataStr) + dataStr.length, str.length)
}

const StoriesQuery = graphql`
  query StoriesQuery {
    allPrismicSiteSettings {
      edges {
        node {
          _previewable
          lang
          data {
            videos_cta_label
            videos_cta_image {
              fixed {
                base64
              }
            }
            navigation_links {
              label
              icon {
                gatsbyImageData
              }
              link {
                uid
                type
              }
            }
          }
        }
      }
    }
  }
`

const SpinningButtonStories = ({ className }: { className?: string }) => {
  const { pathname } = useLocation()
  const staticData = useStaticQuery(StoriesQuery)
  const { data } = useMergePrismicPreviewData(staticData)
  const prismicSiteSettings = getLocalizedData(pathname, data?.allPrismicSiteSettings)

  const html = useMemo(() => {
    if (!prismicSiteSettings?.data?.videos_cta_image?.fixed?.base64) return ''
    const base64 = prismicSiteSettings.data?.videos_cta_image?.fixed?.base64
    if (!base64.startsWith('data:image/svg+xml;base64')) return `<img src="${base64}" alt='preview svg' width="100%" />`
    return atob(getDecodedData(base64))
  }, [prismicSiteSettings?.data?.videos_cta_image?.fixed?.base64])

  const path = useMemo(() => {
    const obj = prismicSiteSettings?.data?.navigation_links?.find(l => l.link?.type === 'videos_page')
    if (!obj?.link?.uid) return '/'
    return getLocalizedPath(obj.link.uid, prismicSiteSettings?.lang || defaultLanguage)
  }, [prismicSiteSettings?.data?.navigation_links, prismicSiteSettings?.lang])

  if (!html || !path) return null

  return (
    <SpinningButton
      className={className}
      spinningText={html}
      label={prismicSiteSettings?.data?.videos_cta_label || ''}
      to={path}
    >
      <div className={cn('iconContainer')}>
        <Play className={cn('icon')} />
      </div>
    </SpinningButton>
  )
}

export default SpinningButtonStories
