const { defaultLanguage } = require('../../prismic-configuration')

exports.linkResolver = ({ type, uid, lang }) => {
  const isDefaultLang = lang === defaultLanguage

  switch (type) {
    case 'homepage': {
      return isDefaultLang ? '/' : `/${lang}/`
    }
    case 'fact':
    case 'solutions_page':
    case 'progress':
    case 'about_page':
    case 'privacy_page':
    case 'videos_page': {
      return isDefaultLang ? `/${uid}/` : `/${lang}/${uid}/`
    }
    default:
      return '/'
  }
}
