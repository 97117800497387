import { useEffect, useState } from 'react'
import { signInAnonymously, User } from 'firebase/auth'

import { useAuth } from 'lib/firebase'

export default function useUser() {
  const auth = useAuth()
  const [user, setUser] = useState<undefined | User>()

  useEffect(() => {
    if (!auth) return
    signInAnonymously(auth).then(data => setUser(data.user))
  }, [auth])

  return user
}
