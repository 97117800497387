import React, { useRef, useCallback, useState, memo, useEffect } from 'react'
import classNames from 'classnames/bind'
import { motion } from 'framer-motion'

import readFromLocalStorage from 'lib/readFromLocalStorage'
import useUIContext from 'context/ui'

import CardDesktop from './CardDesktop'
import { viewVariants } from './variants'
import { ViewDesktopTypes, FactType } from './CardsLayoutTypes'
import * as s from './CardsLayout.module.scss'
const cn = classNames.bind(s)

const ViewDesktop = ({
  category,
  isSelected,
  lang,
  wrapperOffset,
  wrapperWidth,
  mouseX,
  mouseY,
  onSelectedReady,
  allowAllToMount,
  visible,
}: ViewDesktopTypes) => {
  const isActive = useUIContext(s => s.isCardsLayoutActive)
  const [allowPointer, setAllowPointer] = useState(false)
  const [shouldHide, setShouldHide] = useState(true)
  const view = useRef<HTMLUListElement>(null)
  const readStatusUpdated = useUIContext(s => s.readStatusUpdated)
  const [sortedFacts, setSortedFacts] = useState<FactType[] | undefined[]>([])

  const onAnimationStart = useCallback(() => {
    if (!view.current) return
    setAllowPointer(false)
    isSelected && setShouldHide(false)
  }, [isSelected, setShouldHide])

  const onAnimationComplete = useCallback(() => {
    if (!view.current) return
    !isSelected && setShouldHide(true)
    isSelected && setAllowPointer(true)
  }, [isSelected, setShouldHide])

  /* Triggered when last card element has finished animating */
  const handleLastAnimationComplete = useCallback(() => {
    if (isSelected && !allowAllToMount) onSelectedReady()
  }, [isSelected, allowAllToMount, onSelectedReady])

  const updateFactsOrdering = useCallback(() => {
    const readFactsUids = readFromLocalStorage('readFacts')
    const unreadFacts = category.facts.filter((fact: FactType) => !readFactsUids.includes(fact.uid))
    const readFacts = readFactsUids
      ? readFactsUids
          .filter((uid: string) => category.facts.find((fact: FactType) => fact.uid === uid))
          .map((uid: string) => category.facts.find((fact: FactType) => fact.uid === uid))
      : []
    setSortedFacts([...unreadFacts, ...readFacts])
  }, [category])

  useEffect(updateFactsOrdering, [readStatusUpdated, updateFactsOrdering])

  return (
    <motion.ul
      ref={view}
      className={cn('viewDesktop', { isActive, hide: shouldHide, visible })}
      variants={viewVariants}
      animate='animate'
      initial='initial'
      custom={isSelected}
      onAnimationStart={onAnimationStart}
      onAnimationComplete={onAnimationComplete}
    >
      {sortedFacts.map((fact: FactType, i: number) => (
        <CardDesktop
          key={fact.uid}
          uid={fact.uid}
          index={i}
          // indexY is used to position cards vertically symmetrically around '0'
          // e.g: -2,-1,0,1,2
          // todo: improve start
          indexY={
            category?.facts.length % 2 === 0
              ? i <= category.facts.length / 2 - 1
                ? i - category.facts.length / 2
                : i - 1 === 0
                ? i
                : i - 1
              : category.facts.length === 1 // ¯\_(ツ)_/¯
              ? 0
              : i - (category.facts.length + 1) / 2
          }
          // todo: improve end
          total={category.facts.length}
          category={category.title}
          theme={fact.theme}
          backgroundLayer={fact.hero.primary.background_layer}
          foregroundLayer={fact.hero.primary.foreground_layer}
          titleLayer={fact.hero.primary.title_layer || null}
          text={fact.hero.primary.text || {}}
          lang={lang}
          mouseX={isActive && isSelected && allowPointer ? mouseX : null}
          mouseY={isActive && isSelected && allowPointer ? mouseY : null}
          allowPointer={allowPointer}
          isInCurrentView={isSelected}
          wrapperOffset={wrapperOffset}
          wrapperWidth={wrapperWidth}
          onAnimationComplete={handleLastAnimationComplete}
        />
      ))}
    </motion.ul>
  )
}

export default memo(ViewDesktop)
