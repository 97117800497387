import React from 'react'
import classNames from 'classnames/bind'

import * as s from './Spacer.module.scss'
import { CSSVariablesProps, SpacerProps } from './SpacerTypes'

const cn = classNames.bind(s)

const getCSSVariables = ({ initial, medium, desktop, large }: CSSVariablesProps) =>
  ({
    '--space': initial,
    '--medium-space': medium || initial,
    '--desktop-space': desktop || medium || initial,
    '--large-space': large || desktop || medium || initial,
  } as React.CSSProperties)

const Spacer = ({
  initial = '9rem',
  medium,
  desktop,
  large,
  className,
  hideOnMobile = false,
  hideOnDesktop = false,
  ...props
}: SpacerProps) => (
  <div
    className={cn('spacer', className, { hideOnMobile, hideOnDesktop })}
    style={getCSSVariables({ initial, medium, desktop, large })}
    {...props}
  />
)

export default Spacer
