import React, { useState, useMemo, useEffect } from 'react'
import classNames from 'classnames/bind'

import { BulletTypes } from './BulletTypes'
import * as s from './Bullets.module.scss'
const cn = classNames.bind(s)

const Bullets = ({ total, onBulletClick, setVisibleIndex, progress, labels }: BulletTypes) => {
  const range = useMemo(() => [...new Array(total).keys()], [total])
  const [updatedIndex, setUpdatedIndex] = useState(0)

  useEffect(() => {
    progress.onChange(v => {
      const continualIndex = Math.min(Math.max(Math.floor(v * total), 0), total - 1)
      if (updatedIndex === continualIndex) return
      setUpdatedIndex(continualIndex)
    })
  }, [total, progress, updatedIndex])

  useEffect(() => {
    if (setVisibleIndex) setVisibleIndex(updatedIndex)
  }, [setVisibleIndex, updatedIndex])

  return (
    <ul className={cn('bullets')}>
      {range.map(i => (
        <li key={i} className={cn('bullet', { isActive: i === updatedIndex })} data-index={i}>
          <button
            onClick={() => onBulletClick(i)}
            className={cn('line')}
            {...(labels ? { 'aria-label': labels[i] } : { tabIndex: -1 })}
          />
        </li>
      ))}
    </ul>
  )
}

export default Bullets
