import { defaultLanguage } from '../../prismic-configuration'

/* Regex used to match language in pathname */
const regex = /^\/([(?-i)a-z]{2}-[(?-i)a-z]{2})(\/|$)/

export const getCurrentLanguage = (pathname: string) =>
  pathname.match(regex) ? pathname.match(regex)?.[1] : defaultLanguage

/* Get current language's data (if it doesn't exist, get default language) */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getLocalizedData = (pathname: string, object: { edges: { node: { lang: string; data: any } }[] }) => {
  const lang = getCurrentLanguage(pathname)

  if (!lang) return null

  if (object?.edges?.length > 1) {
    const arr = object?.edges?.filter(edge => edge.node.lang === lang).map(({ node }) => node)
    if (arr?.length) return arr
  }

  let data = object?.edges?.find(edge => edge.node.lang === lang)?.node

  if (!data) data = object?.edges?.find(edge => edge.node.lang === defaultLanguage)?.node

  return data
}

export default getLocalizedData
