import { easeBackIn, easeQuadInOut } from 'd3-ease'

export const viewVariants = {
  initial: {
    opacity: 0,
  },
  animate: (isActive: boolean) => ({
    opacity: isActive ? 1 : 0,
    transition: {
      duration: isActive ? 0.2 : 0.4,
      delay: isActive ? 0.6 : 0.2,
    },
  }),
}

export const cardDesktopElementVariants = {
  initial: {
    x: '300%',
  },
  animate: (data: { index: number }) => ({
    x: ['300%', '0%'],
    opacity: 1,
    transition: {
      x: {
        duration: 1,
        delay: data.index * 0.1 + 0.4,
        ease: [0.175, 0.885, 0.32, 1.05], // 1. custom tweaks
      },
      opacity: {
        delay: data.index * 0.1 + 0.4,
      },
    },
  }),
  exit: (data: { index: number; total: number }) => ({
    x: ['0%', '-300%'],
    opacity: 0,
    transition: {
      x: {
        duration: 0.7,
        delay: data.index * 0.1,
        ease: easeBackIn,
      },
      opacity: {
        delay: data.index * 0.1 + 0.7,
      },
    },
  }),
}

export const cardMobileElementVariants = {
  initial: {
    x: '200%',
  },
  animate: (data: { index: number }) => ({
    x: ['200%', '0%'],
    opacity: 1,
    transition: {
      x: {
        duration: 1,
        delay: data.index * 0.1 + 0.4,
        ease: [0.175, 0.885, 0.32, 1.05], // custom tweaks
      },
      opacity: {
        delay: data.index * 0.1 + 0.4,
      },
    },
  }),
  exit: (data: { index: number; total: number }) => ({
    x: ['0%', '-200%'],
    opacity: 0,
    transition: {
      x: {
        duration: 0.7,
        delay: data.index,
        ease: easeBackIn,
      },
      opacity: {
        delay: data.index + 0.7,
      },
    },
  }),
}

export const displayVariants = {
  initial: {
    y: '3vw',
    scale: 0.75,
    opacity: 0,
  },
  animate: {
    y: '0vw',
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.6,
      ease: easeQuadInOut,
      delay: 0.5,
    },
  },
  exit: {
    y: '-4vw',
    opacity: 0,
    transition: {
      duration: 0.4,
      ease: easeQuadInOut,
    },
  },
}

export const videosCTAVariants = {
  initial: {
    x: '5vw',
    opacity: 0,
  },
  animate: (data: { total: number }) => ({
    x: '0vw',
    opacity: 1,
    transition: {
      duration: 1,
      ease: [0.175, 0.885, 0.32, 1.05], // 1.
      delay: data.total * 0.1 + 0.9,
    },
  }),
}
