import React, { useMemo } from 'react'
import classNames from 'classnames/bind'
import { MotionValue } from 'framer-motion'
import { easeSinOut } from 'd3-ease'

import { MonumentHeading1, MonumentSubtitle } from 'components/ui/Text'
import ScrollParallax from 'components/core/ScrollParallax'

import * as s from './Intro.module.scss'
const cn = classNames.bind(s)

const Section3Mobile = ({ scrollY }: { scrollY: MotionValue }) => {
  const text = useMemo(() => ['vårt sätt', 'att leva', 'utsätter', 'planeten', 'för stor', 'press'], [])

  return (
    <section className={cn('section', 'center', 'section3', 'mobile')}>
      <ScrollParallax
        absolute
        className={cn('fullscreen')}
        motionValue={scrollY}
        keyframes={{ opacity: [0, 1] }}
        times={{ opacity: [0, 0.5] }}
        startOffset='240vh'
        stopOffset='240vh'
      >
        <div className={cn('bg', 'fullscreen')} />
      </ScrollParallax>
      <ScrollParallax
        absolute
        className={cn('fullscreen', 'center')}
        motionValue={scrollY}
        keyframes={{ x: ['100%', '0%'], skewX: [-8, 0] }}
        times={{
          x: [0, 0.5],
          skewX: [0.1, 0.5],
        }}
        startOffset='245vh'
        stopOffset='245vh'
        ease={easeSinOut}
      >
        <div className={cn('wrapper', 'center')} aria-hidden='true'>
          <div className={cn('left', 'center')}>
            <MonumentHeading1 as='span' className={cn('heroLetter')}>
              <ScrollParallax
                motionValue={scrollY}
                keyframes={{ y: ['0vh', '-80vh'], opacity: [1, 0] }}
                times={{ y: [0, 1], opacity: [0.4, 1] }}
                startOffset='310vh'
                stopOffset='310vh'
              >
                M
              </ScrollParallax>
            </MonumentHeading1>
          </div>

          <div className={cn('right', 'center')}>
            <MonumentHeading1 as='span' className={cn('heroLetter')}>
              <ScrollParallax
                motionValue={scrollY}
                keyframes={{ y: ['0vh', '50vh'], opacity: [1, 0] }}
                times={{ y: [0, 1], opacity: [0.4, 1] }}
                startOffset='310vh'
                stopOffset='310vh'
              >
                e
              </ScrollParallax>
            </MonumentHeading1>
            <MonumentHeading1 as='span' className={cn('heroLetter')}>
              <ScrollParallax
                motionValue={scrollY}
                keyframes={{ y: ['0vh', '80vh'], opacity: [1, 0] }}
                times={{
                  y: [0, 1],
                  opacity: [0.4, 1],
                }}
                startOffset='310vh'
                stopOffset='310vh'
              >
                n
              </ScrollParallax>
            </MonumentHeading1>
          </div>
        </div>
      </ScrollParallax>
      <MonumentSubtitle as='div' className={cn('heroInfo')}>
        <ScrollParallax
          motionValue={scrollY}
          keyframes={{ y: ['20vh', '-45vh'], opacity: [1, 0] }}
          times={{ y: [0, 1], opacity: [0.7, 1] }}
          startOffset='305vh'
          stopOffset='305vh'
        >
          <div className={cn('heroInfoContent')} aria-hidden='true'>
            {text.map((item, index) => (
              <ScrollParallax
                key={`maskedMobile-${index}`}
                motionValue={scrollY}
                keyframes={{ opacity: [0, 1] }}
                times={{ opacity: [0, 0.3] }}
                startOffset='305vh'
                stopOffset='305vh'
              >
                <p className={index === 0 ? cn('padSafari') : ''}>{item}</p>
              </ScrollParallax>
            ))}
          </div>
        </ScrollParallax>
      </MonumentSubtitle>
      <p className={cn('srOnly')}>Men vårt sätt att leva utsätter planeten för stor press.</p>
    </section>
  )
}

export default Section3Mobile
