import React, { useState } from 'react'
import classNames from 'classnames/bind'
import { motion, AnimatePresence } from 'framer-motion'

import { CircularBody } from 'components/ui/Text'
import Clickable from 'components/core/Clickable'

import * as s from './../Buttons.module.scss'
import { VoteButtonProps } from './VoteButtonTypes'
const cn = classNames.bind(s)

const VoteButton = ({
  votes,
  children,
  to,
  onClick,
  onMouseEnter,
  onMouseLeave,
  className,
  classNameLabel,
  inverted = false,
  narrow = false,
  disabled = false,
  votingPossible = false,
  type,
}: VoteButtonProps) => {
  const [clicked, setClicked] = useState<boolean>(false)

  const handleClick = () => {
    setClicked(true)
    if (onClick) onClick()

    const timerId = setTimeout(() => setClicked(false), 550)
    return () => timerId && clearTimeout(timerId)
  }

  return (
    <Clickable
      className={cn('container', { inverted, votingPossible }, className)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={handleClick}
      to={to}
      disabled={disabled}
      type={type}
    >
      <div className={cn('content')}>
        {votes && (
          <CircularBody className={cn('text', 'votes', { border: votes, narrow })}>
            <AnimatePresence exitBeforeEnter>
              <motion.span
                initial={{ y: '120%' }}
                animate={{ y: '0%' }}
                exit={{ y: '-120%' }}
                transition={{ type: 'spring', duration: 0.5 }}
                key={votes}
              >
                {votes}
              </motion.span>
            </AnimatePresence>
          </CircularBody>
        )}
        <CircularBody
          className={cn('text', 'label', { narrow, disabled: votes && !votingPossible && clicked }, classNameLabel)}
        >
          {children}
        </CircularBody>
      </div>
    </Clickable>
  )
}

export default VoteButton
