import { KeyboardEvent, RefObject } from 'react'

const FOCUSABLE_ELEMENTS = 'button, input, textarea, select, a'

const trapFocusInModal = (e: KeyboardEvent, ref: RefObject<HTMLDivElement>) => {
  if (e.key !== 'Tab') return
  // @ts-ignore
  const elements = [...ref.current?.querySelectorAll(FOCUSABLE_ELEMENTS)].filter(el => !el.hidden && !el.disabled)

  const index = elements.indexOf(e.target)
  const last = elements[elements.length - 1]

  if (index === -1) return

  if (index === 0 && e.shiftKey) {
    last.focus()
    e.preventDefault()
  } else if (e.target === last && !e.shiftKey) {
    elements[0].focus()
    e.preventDefault()
  }
}

export default trapFocusInModal
