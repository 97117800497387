import create from 'zustand'

export interface Copy {
  author_prefix?: string
  available_votes?: string
  back_to_home?: string
  close?: string
  dialog_description?: string
  facts_progress_prefix?: string
  facts?: string
  hotspot_size_label?: string
  menu?: string
  mobile_cards_label?: string
  open?: string
  presents?: string
  reaction_aria_label?: string
  scroll_text?: string
  source?: string
  sources_title?: string
  sources?: string
  stories_progress_prefix?: string
  videos?: string
}

export interface Store {
  copy: Copy
  setCopy: (sources: Copy) => void
}

const useCopyContext = create<Store>((set, get) => ({
  copy: {
    author_prefix: '',
    available_votes: '',
    back_to_home: '',
    close: '',
    dialog_description: '',
    facts_progress_prefix: '',
    facts: '',
    hotspot_size_label: '',
    menu: '',
    mobile_cards_label: '',
    open: '',
    presents: '',
    reaction_aria_label: '',
    scroll_text: '',
    source: '',
    sources_title: '',
    sources: '',
    stories_progress_prefix: '',
    videos: '',
  },
  setCopy: next => {
    const copy = get().copy
    set({ copy: { ...copy, ...next } })
  },
}))

export default useCopyContext
