import React from 'react'
import classNames from 'classnames/bind'
import { MotionValue } from 'framer-motion'
import { easeCircleOut, easeSinOut, easeSinIn } from 'd3-ease'

import { MonumentHeading1, MonumentSubtitle } from 'components/ui/Text'
import ScrollParallax from 'components/core/ScrollParallax'

import * as s from './Intro.module.scss'
const cn = classNames.bind(s)

const Section2Mobile = ({ scrollY }: { scrollY: MotionValue }) => (
  <section className={cn('section', 'center', 'section2', 'mobile')}>
    <ScrollParallax
      absolute
      className={cn('fullscreen')}
      motionValue={scrollY}
      keyframes={{ opacity: [0, 1] }}
      times={{ opacity: [0, 0.35] }}
      startOffset='150vh'
      stopOffset='105vh'
      ease={easeCircleOut}
    >
      <div className={cn('bg', 'fullscreen')} />
    </ScrollParallax>
    <div className={cn('wrapper', 'center')} aria-hidden='true'>
      <div className={cn('left', 'center')}>
        <MonumentHeading1 as='span' className={cn('heroLetter')}>
          <ScrollParallax
            motionValue={scrollY}
            keyframes={keyframes.heroLetterFirst}
            times={times.heroLetter}
            startOffset='95vh'
            stopOffset='95vh'
            ease={[easeSinOut, easeSinOut, easeSinIn]}
          >
            J
          </ScrollParallax>
        </MonumentHeading1>
      </div>

      <div className={cn('right', 'center')}>
        <MonumentHeading1 as='span' className={cn('heroLetter')}>
          <ScrollParallax
            motionValue={scrollY}
            keyframes={keyframes.heroLetterSecond}
            times={times.heroLetter}
            startOffset='95vh'
            stopOffset='95vh'
            ease={[easeSinOut, easeSinOut, easeSinIn]}
          >
            a
          </ScrollParallax>
        </MonumentHeading1>
        <MonumentHeading1 as='span' className={cn('heroLetter')}>
          <ScrollParallax
            motionValue={scrollY}
            keyframes={keyframes.heroLetterThird}
            times={times.heroLetter}
            startOffset='95vh'
            stopOffset='95vh'
            ease={[easeSinOut, easeSinOut, easeSinIn]}
          >
            !
          </ScrollParallax>
        </MonumentHeading1>
      </div>
    </div>
    <MonumentSubtitle as='div' className={cn('heroInfo', 'mask', 'center')} aria-hidden='true'>
      <ScrollParallax
        motionValue={scrollY}
        keyframes={keyframes.heroInfo}
        times={times.heroInfo}
        ease={easeSinOut}
        startOffset='154vh'
        stopOffset='100vh'
      >
        <div>
          <ScrollParallax
            motionValue={scrollY}
            keyframes={{ x: ['0%', '-125%'], skewX: [0, -10] }}
            times={{ x: [0, 0.8], skewX: [0.1, 0.8] }}
            startOffset='185vh'
            stopOffset='185vh'
            ease={easeSinIn}
          >
            <p>Vi lever </p>
            <p>längre,</p>
            <p>friskare</p>
            <p>och</p>
            <p>rikare liv </p>
          </ScrollParallax>
        </div>
      </ScrollParallax>
    </MonumentSubtitle>
    <p className={cn('srOnly')}>Ja! Vi lever längre, friskare och rikare liv.</p>
  </section>
)

const keyframes = {
  heroInfo: { scale: [0, 1], opacity: [0, 1] },
  heroLetterFirst: {
    y: ['40vh', '0vh', '0vh', '60vh'],
    opacity: [0, 1],
    x: ['0.5vh', '0.5vh'],
  },
  heroLetterSecond: {
    y: ['60vh', '0vh', '0vh', '-60vh'],
    x: ['-0.5vh', '-0.5vh'],
    opacity: [0, 1],
  },
  heroLetterThird: {
    y: ['80vh', '0vh', '0vh', '-80vh'],
    x: ['-0.8vh', '-0.8vh'],
    opacity: [0, 1],
  },
}

const times = {
  heroLetter: { y: [0, 0.35, 0.45, 1], opacity: [0, 0.4] },
  heroInfo: { scale: [0, 0.6], opacity: [0, 0.3] },
}

export default Section2Mobile
