import React from 'react'
import classNames from 'classnames/bind'
import { AnimatePresence, motion } from 'framer-motion'

import { SpinningButtonStories } from 'components/ui/SpinningButton'
import { Row, Column } from 'components/core/Grid'
import Container from 'components/core/Container'
import useUIContext from 'context/ui'

import { videosCTAVariants } from './variants'
import * as s from './VideosCTAContainer.module.scss'

const cn = classNames.bind(s)

const VideosCTAContainer = ({ totalCards }: { totalCards?: number }) => {
  const showFeedbackCTA = useUIContext(s => s.showFeedbackCTA)

  return (
    <Container className={cn('container')}>
      <Row className={cn('row')}>
        <Column className={cn('col')} medium={5} />
        <Column className={cn('col')} medium={1}>
          <AnimatePresence exitBeforeEnter>
            {totalCards && !showFeedbackCTA && (
              <motion.div
                className={cn('link')}
                variants={videosCTAVariants}
                initial='initial'
                animate='animate'
                exit='exit'
                custom={{ total: totalCards }}
              >
                <SpinningButtonStories />
              </motion.div>
            )}
          </AnimatePresence>
        </Column>
      </Row>
    </Container>
  )
}

export default VideosCTAContainer
