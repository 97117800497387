// extracted by mini-css-extract-plugin
export var colorBlack = "#000";
export var colorGray = "gray";
export var colorDarkGray = "#262626";
export var colorLightGray = "#e5e5e5";
export var colorWhite = "#fff";
export var colorElectricBlue = "#0000fe";
export var colorPastelBlue = "#bbe5fe";
export var colorCandyPink = "#f9709a";
export var colorPastelPink = "#ffcdd2";
export var colorDefaultLime = "#cef564";
export var colorPastelLime = "#eeffbe";
export var colorBlueLagoon = "#9cf0e1";
export var colorPastelLagoon = "#ceffec";
export var colorDefaultLavender = "#8738f6";
export var colorPastelLavender = "#eadcff";
export var colorBrightYellow = "#ffe500";
export var colorDefaultGreen = "#12b968";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var colCount = "6";
export var colWidth = "16.6666666667%";
export var card = "CardMobile-module--card--1F2Ek";
export var cardWrapper = "CardMobile-module--cardWrapper--14LJU";
export var cardInner = "CardMobile-module--cardInner--3epMy";
export var cardRef = "CardMobile-module--cardRef--3kXlt";
export var link = "CardMobile-module--link--3zPZ9";
export var frontface = "CardMobile-module--frontface--2h0cm";
export var isRead = "CardMobile-module--isRead--1yv8c";
export var backface = "CardMobile-module--backface--YLT06";
export var white = "CardMobile-module--white--157Mg";
export var button = "CardMobile-module--button--3OgXL";
export var label = "CardMobile-module--label--1TOLr";