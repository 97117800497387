import React from 'react'
import classNames from 'classnames/bind'

import * as s from './Container.module.scss'
import { ContainerType } from './ContainerTypes'
const cn = classNames.bind(s)

const Container: ContainerType = ({ as: Component = 'div', className, children, ...props }) => (
  <Component {...props} className={cn('wrapper', className)}>
    {children}
  </Component>
)

export default Container
