import React from 'react'
import classNames from 'classnames/bind'
import { MotionValue, motion } from 'framer-motion'

import { PrimaryButton } from 'components/ui/Buttons'
import ScrollIndicator from 'components/ui/ScrollIndicator'
import Logo from 'assets/svg/undp_logo.react.svg'
import { MonumentHeading1 } from 'components/ui/Text'
import Container from 'components/core/Container'
import ScrollParallax from 'components/core/ScrollParallax'
import { scrollToWindowY } from 'lib/scrollTo'

import * as s from './Intro.module.scss'
const cn = classNames.bind(s)

const Section1Mobile = ({
  scrollY,
  onSkip,
  showSkip,
  data: { skip_text, scroll_text },
}: {
  scrollY: MotionValue
  onSkip: () => void
  showSkip: boolean
  data: { skip_text: string; scroll_text: string }
}) => (
  <Container as='section' className={cn('section', 'center', 'section1')}>
    <div className={cn('inner')}>
      <div className={cn('wrapper', 'center')}>
        <motion.div
          className={cn('credits')}
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: {
              duration: 1,
              delay: 0.2,
            },
          }}
        >
          <ScrollParallax
            motionValue={scrollY}
            keyframes={{ scale: [1, 0.75], opacity: [1, 0] }}
            times={{ scale: [0, 0.3], opacity: [0, 0.3] }}
            startOffset='85vh'
            stopOffset='85vh'
          >
            <Logo className={cn('logo')} />
          </ScrollParallax>
        </motion.div>

        <h1 className={cn('siteTitle')}>
          <span className={cn('srOnly')}>Blir världen bättre</span>
          <MonumentHeading1 as='span' aria-hidden='true'>
            <ScrollParallax
              motionValue={scrollY}
              keyframes={{ y: ['0vh', '-65vh'], scale: [1, 0.8], opacity: [1, 1, 0] }}
              times={{
                y: [0, 0.66],
                scale: [0, 0.5],
                opacity: [0, 0.25, 0.66],
              }}
              startOffset='60vh'
              stopOffset='60vh'
            >
              <motion.div
                initial={{ y: '5vw', scale: 0.75, opacity: 0 }}
                animate={{
                  y: '0vw',
                  opacity: 1,
                  scale: 1,
                  transition: {
                    duration: 1,
                    delay: 0.8,
                  },
                }}
              >
                Blir världen bättre?
              </motion.div>
            </ScrollParallax>
          </MonumentHeading1>
        </h1>
      </div>
      {/* SKIP BUTTON */}
      {showSkip && (
        <motion.div
          className={cn('skip')}
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: {
              duration: 1,
              delay: 2.2,
            },
          }}
        >
          <ScrollParallax
            motionValue={scrollY}
            keyframes={{ y: ['0vh', '-50vh'], scale: [1, 0.8], opacity: [1, 0] }}
            times={{
              y: [0, 0.75],
              scale: [0, 0.66],
              opacity: [0, 0.5],
            }}
            startOffset='35vh'
            stopOffset='35vh'
          >
            <PrimaryButton inverted className={cn('button')} onClick={onSkip}>
              {skip_text || ''}
            </PrimaryButton>
          </ScrollParallax>
        </motion.div>
      )}
    </div>
    {/* SCROLL INDICATOR */}
    <motion.div
      className={cn('scrollIndicator')}
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: {
          duration: 1,
          delay: 1.7,
        },
      }}
    >
      <ScrollParallax
        motionValue={scrollY}
        keyframes={{ y: ['0vh', '-55vh'], scale: [1, 0.8], opacity: [1, 0] }}
        times={{
          y: [0, 0.75],
          scale: [0, 0.66],
          opacity: [0, 0.5],
        }}
        startOffset='30vh'
        stopOffset='10vh'
      >
        <ScrollIndicator onClick={() => scrollToWindowY(window.innerHeight * 1.05, 650)}>
          {scroll_text || ''}
        </ScrollIndicator>
      </ScrollParallax>
    </motion.div>
  </Container>
)

export default Section1Mobile
