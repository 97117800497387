import React, { useMemo } from 'react'
import { motion } from 'framer-motion'

import useIsDesktop from 'lib/useIsDesktop'

import { InteractableTypes } from './InteractableTypes'

const Interactable = ({ onEnter, onLeave, onClick, children, className, ...props }: InteractableTypes) => {
  const isDesktop = useIsDesktop()
  const interactionType = useMemo(() => (isDesktop ? 'onHoverStart' : 'onTap'), [isDesktop])
  return (
    <motion.button
      className={className}
      {...(isDesktop && {
        onMouseLeave: onLeave,
        onClick: onClick,
      })}
      {...{
        [interactionType]: onEnter,
      }}
      {...props}
    >
      {children}
    </motion.button>
  )
}

export default Interactable
