// extracted by mini-css-extract-plugin
export var colorBlack = "#000";
export var colorGray = "gray";
export var colorDarkGray = "#262626";
export var colorLightGray = "#e5e5e5";
export var colorWhite = "#fff";
export var colorElectricBlue = "#0000fe";
export var colorPastelBlue = "#bbe5fe";
export var colorCandyPink = "#f9709a";
export var colorPastelPink = "#ffcdd2";
export var colorDefaultLime = "#cef564";
export var colorPastelLime = "#eeffbe";
export var colorBlueLagoon = "#9cf0e1";
export var colorPastelLagoon = "#ceffec";
export var colorDefaultLavender = "#8738f6";
export var colorPastelLavender = "#eadcff";
export var colorBrightYellow = "#ffe500";
export var colorDefaultGreen = "#12b968";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var colCount = "6";
export var colWidth = "16.6666666667%";
export var interactiveText = "InteractiveText-module--interactiveText--3sCvA";
export var idle = "InteractiveText-module--idle--3qkyB";
export var trigger = "InteractiveText-module--trigger--1e5uA";
export var isTriggerFullScreen = "InteractiveText-module--isTriggerFullScreen--3aGV1";
export var debug = "InteractiveText-module--debug--XsbmM";
export var primary = "InteractiveText-module--primary--u4uEy";
export var primaryOutline = "InteractiveText-module--primaryOutline--1mv03";
export var hasHoverColor = "InteractiveText-module--hasHoverColor--IXGy2";
export var isActive = "InteractiveText-module--isActive--1rdgT";
export var secondary = "InteractiveText-module--secondary--1s5Vh";
export var label = "InteractiveText-module--label--2Z3Bu";