import React, { useState, useMemo, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'
import classNames from 'classnames/bind'
import { useLocation, navigate } from '@reach/router'

import useIsDesktop from 'lib/useIsDesktop'
import useCopyContext from 'context/copy'
import useUIContext from 'context/ui'
import HashtagCollection from 'components/ui/HashtagCollection'
import getLocalizedData from 'lib/getLocalizedData'

import { defaultLanguage } from '../../../../prismic-configuration'
import HashtagDisplay from './HashtagDisplay'
import VideosCTAContainer from './VideosCTAContainer'
import Cards from './Cards'
import { CardsLayoutTypes, CategoriesType, StructuredCategory } from './CardsLayoutTypes'
import * as s from './CardsLayout.module.scss'

const cn = classNames.bind(s)

const cardsQuery = graphql`
  query cardsQuery {
    allPrismicHomepage {
      edges {
        node {
          _previewable
          lang
          data {
            body {
              ... on PrismicHomepageDataBodyCategory {
                primary {
                  category_title
                }
                items {
                  category_fact {
                    document {
                      ... on PrismicFact {
                        uid
                        data {
                          theme_color
                          body {
                            ... on PrismicFactDataBodySparkSentenceFullscreen {
                              slice_type
                              primary {
                                background_layer {
                                  alt
                                  thumbnails {
                                    card {
                                      gatsbyImageData
                                    }
                                  }
                                }
                                foreground_layer {
                                  alt
                                  thumbnails {
                                    card {
                                      gatsbyImageData
                                    }
                                  }
                                }
                                title_layer {
                                  raw
                                }
                              }
                            }
                            ... on PrismicFactDataBodySparkSentenceTwoColumn {
                              slice_type
                              primary {
                                background_layer {
                                  alt
                                  thumbnails {
                                    card {
                                      gatsbyImageData
                                    }
                                  }
                                }
                                foreground_layer {
                                  alt
                                  thumbnails {
                                    card {
                                      gatsbyImageData
                                    }
                                  }
                                }
                                text {
                                  raw
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicSiteSettings {
      edges {
        node {
          _previewable
          lang
          data {
            mobile_cards_label
            open
            close
            menu
          }
        }
      }
    }
  }
`

const CardsLayout = ({ preselectedCategory, isFactPage }: CardsLayoutTypes) => {
  const staticData = useStaticQuery(cardsQuery)
  const { data } = useMergePrismicPreviewData(staticData)
  const location = useLocation()
  const setGlobalFactsData = useUIContext(s => s.setGlobalFactsData)
  const setPreloadCategory = useUIContext(s => s.setPreloadCategory)
  // active category/hashtag and an array of all hashtags
  const [selectedCategory, setSelectedCategory] = useState<string>('')
  const [hashtags, setHashtags] = useState<string[]>([])
  const [open, setOpen] = useState<boolean>(false)
  // active state of the event listeners, marquee etc.
  const isActive = useUIContext(s => s.isCardsLayoutActive)
  // update copy with button label
  const setCopy = useCopyContext(s => s.setCopy)

  const prismicHomepage = getLocalizedData(location?.pathname, data.allPrismicHomepage)
  const prismicSiteSettings = getLocalizedData(location?.pathname, data.allPrismicSiteSettings)
  const isDesktop = useIsDesktop()

  const categories = useMemo(
    () =>
      prismicHomepage?.data?.body?.map((i: CategoriesType) => ({
        title: i.primary.category_title,
        facts: i.items
          .filter(({ category_fact: { document } }) => !!document)
          .map(({ category_fact: { document } }) => ({
            uid: document.uid,
            theme: document.data.theme_color,
            hero:
              document.data.body.length &&
              document.data.body[0].slice_type.includes('spark_sentence') &&
              document.data.body[0], // Setting fact card data from the first spark sentence slice (i.e. fullscreen or two column one)
          })),
      })),
    [prismicHomepage?.data?.body],
  )

  const totalCards = useMemo(() => {
    const current: StructuredCategory = categories.find((c: { title: string }) => c.title === selectedCategory)
    if (current) return current.facts.length
  }, [categories, selectedCategory])

  useEffect(() => {
    if (!prismicSiteSettings) return
    const { mobile_cards_label, open, close, menu } = prismicSiteSettings.data
    setCopy({ mobile_cards_label, open, close, menu })
  }, [setCopy, prismicSiteSettings])

  // Sets array of all hashtags and populates application state with facts data(uid, categories fact belongs to)
  useEffect(() => {
    if (!categories) return
    setHashtags(categories.map((c: { title: string }) => c.title))

    const factsData: { uid: string; categories: string[] }[] = []

    categories.forEach((c: { title: string; facts: { uid: string }[] }) => {
      const category = c.title
      const facts = c.facts

      facts.forEach(f => {
        for (let i = 0; i < factsData.length; i++) {
          if (factsData[i].uid === f.uid) {
            factsData[i].categories.push(category)
            return
          }
        }

        factsData.push({ uid: f.uid, categories: [category] })
      })
    })

    setGlobalFactsData(factsData)
  }, [categories, setGlobalFactsData])

  // Select category (hashtag)
  const handleHashtagChange = (next: string) => {
    // preload
    if (!isDesktop) setPreloadCategory(next)
    setSelectedCategory(next)
  }

  const handleHashtagHover = (next: string) => {
    setPreloadCategory(next)
  }

  useEffect(() => {
    let preselected
    // No hash in the URL and the site is loaded on the fact page, use the default/preselected category of the fact
    if (!location?.hash && preselectedCategory) {
      preselected = preselectedCategory
    } else {
      // Read from URL or get a random hashtag
      preselected = location?.hash || hashtags[Math.floor(Math.random() * hashtags.length)]
    }

    setSelectedCategory(preselected)
    setPreloadCategory(preselected) // Used by cards component
  }, [hashtags, location?.hash, setSelectedCategory, preselectedCategory, setPreloadCategory])

  // Sanitize URL if non-matching
  useEffect(() => {
    if (!selectedCategory || !hashtags.length) return
    if (!hashtags.includes(selectedCategory)) {
      navigate(location.pathname)
      return
    }
  }, [categories, hashtags, location.pathname, selectedCategory])

  return (
    <div className={cn('cardsLayout', { open })} {...(isFactPage && { 'aria-hidden': true })}>
      <HashtagDisplay text={selectedCategory} />
      {isActive && <VideosCTAContainer totalCards={totalCards} />}
      {categories && (
        <Cards
          categories={categories}
          lang={prismicHomepage?.lang || defaultLanguage}
          selectedCategory={selectedCategory}
          isActive={isActive}
        />
      )}
      <HashtagCollection
        selected={selectedCategory}
        hashtags={categories}
        onClick={handleHashtagChange}
        onHover={handleHashtagHover}
        isActive={isActive}
        handleOpen={open => setOpen(open)}
        isFactPage={isFactPage}
      />
    </div>
  )
}

export default CardsLayout
