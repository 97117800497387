import React from 'react'
import classNames from 'classnames/bind'
import { RichText } from 'prismic-reactjs'
import { motion } from 'framer-motion'

import { CircularCaption } from 'components/ui/Text'
import { serializerSmall } from 'prismic/htmlSerializer'

import { BoxProps } from './BoxTypes'
import * as s from './Box.module.scss'
import { defaultVariants } from './variants'
const cn = classNames.bind(s)

const Box = ({ children, className, variants, custom }: BoxProps) => {
  return (
    <motion.div
      className={cn('container', className)}
      variants={variants || defaultVariants}
      custom={custom || false}
      initial='exit'
      animate='animate'
      exit='exit'
    >
      {typeof children === 'string' ? (
        <CircularCaption weight='book' as='p'>
          {children}
        </CircularCaption>
      ) : (
        <RichText render={children} htmlSerializer={serializerSmall()} />
      )}
    </motion.div>
  )
}

export default Box
