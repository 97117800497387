import React, { useEffect, useState, useRef } from 'react'
import classNames from 'classnames/bind'
import { useWindowSize } from '@react-hook/window-size'

import useUIContext from 'context/ui'

import View from './View'
import { InteractiveTextTypes } from './InteractiveTextTypes'
import * as s from './InteractiveText.module.scss'

const cn = classNames.bind(s)

const InteractiveText = ({
  className = '',
  textColor = s.colorBlack,
  outlineColor = s.colorWhite,
  primaryOutline = true,
  idle = false,
  triggerScaleY = 1,
  triggerScaleX = 1,
  intensity = 1,
  debug = false,
  textHoverColor,
  children,
}: InteractiveTextTypes) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
  const ref = useRef<HTMLDivElement>(null)
  const [width, height] = useWindowSize({ wait: 300 })
  const isFontAvailable = useUIContext(s => s.isFontAvailable)

  useEffect(() => {
    if (!ref.current) return
    setDimensions({
      width: ref.current.clientWidth,
      height: ref.current.clientHeight,
    })
  }, [ref, width, height, isFontAvailable])

  return (
    <div
      className={cn('interactiveText', className, { idle, debug, primaryOutline, hasHoverColor: textHoverColor })}
      ref={ref}
      style={
        {
          '--text': textColor,
          '--hover': textHoverColor,
          '--outline': outlineColor,
          '--x': triggerScaleX,
          '--y': triggerScaleY,
        } as React.CSSProperties
      }
    >
      <View
        viewWidth={dimensions.width}
        viewHeight={dimensions.height}
        multiplierX={1 / triggerScaleX}
        multiplierY={1 / triggerScaleY}
        intensity={intensity}
      >
        {children}
      </View>
    </div>
  )
}
export default InteractiveText
