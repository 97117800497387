//t = current time
//b = start value
//c = change in value
//d = duration
const easeInOutQuad = function (t: number, b: number, c: number, d: number) {
  t /= d / 2
  if (t < 1) return (c / 2) * t * t + b
  t--
  return (-c / 2) * (t * (t - 2) - 1) + b
}

export const scrollToX = (el: HTMLElement, to: number, duration: number): void => {
  const start = el.scrollLeft
  const change = to - start
  let currentTime = 0
  const increment = 10

  const animateScroll = (id?: number) => {
    currentTime += increment
    const val = easeInOutQuad(currentTime, start, change, duration)
    el.scrollLeft = val
    currentTime < duration ? requestAnimationFrame(animateScroll) : id && cancelAnimationFrame(id)
  }
  animateScroll()
}

export const scrollToY = (el: HTMLElement, to: number, duration: number): void => {
  const start = el.scrollTop
  const change = to - start
  let currentTime = 0
  const increment = 10

  const animateScroll = (id?: number) => {
    currentTime += increment
    const val = easeInOutQuad(currentTime, start, change, duration)
    el.scrollTop = val
    currentTime < duration ? requestAnimationFrame(animateScroll) : id && cancelAnimationFrame(id)
  }
  animateScroll()
}

export const scrollToWindowY = (to: number, duration: number) => {
  const element = document.scrollingElement
  const start = (element && element.scrollTop) || window.scrollY
  const change = to - start
  let currentTime = 0
  const increment = 10

  const animateScroll = (id?: number) => {
    currentTime += increment
    const val = easeInOutQuad(currentTime, start, change, duration)
    window.scrollTo(0, val)
    currentTime < duration ? requestAnimationFrame(animateScroll) : id && cancelAnimationFrame(id)
  }
  animateScroll()
}
