// extracted by mini-css-extract-plugin
export var colorBlack = "#000";
export var colorGray = "gray";
export var colorDarkGray = "#262626";
export var colorLightGray = "#e5e5e5";
export var colorWhite = "#fff";
export var colorElectricBlue = "#0000fe";
export var colorPastelBlue = "#bbe5fe";
export var colorCandyPink = "#f9709a";
export var colorPastelPink = "#ffcdd2";
export var colorDefaultLime = "#cef564";
export var colorPastelLime = "#eeffbe";
export var colorBlueLagoon = "#9cf0e1";
export var colorPastelLagoon = "#ceffec";
export var colorDefaultLavender = "#8738f6";
export var colorPastelLavender = "#eadcff";
export var colorBrightYellow = "#ffe500";
export var colorDefaultGreen = "#12b968";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var colCount = "6";
export var colWidth = "16.6666666667%";
export var footer = "Footer-module--footer--2YbcH";
export var upper = "Footer-module--upper--36Lj9";
export var logos = "Footer-module--logos--bdy4f";
export var image = "Footer-module--image--1qO5E";
export var description = "Footer-module--description--2mCpK";
export var below = "Footer-module--below--3XTGr";
export var text = "Footer-module--text--3BS6k";
export var border = "Footer-module--border--2Digo";
export var meta = "Footer-module--meta--3bPPu";
export var copyright = "Footer-module--copyright--nuuXw";
export var socmed = "Footer-module--socmed--17-UY";
export var link = "Footer-module--link--1sNVe";