import React, { useMemo, useEffect, useRef, KeyboardEvent } from 'react'
import { AnimatePresence, motion, useAnimation } from 'framer-motion'
import { graphql, useStaticQuery } from 'gatsby'
import { useLocation } from '@reach/router'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'
import atob from 'atob'
import classNames from 'classnames/bind'
import throttle from 'lodash.throttle'

import Feedback from 'assets/svg/feedback.react.svg'
import getLocalizedData from 'lib/getLocalizedData'
import readFromLocalStorage from 'lib/readFromLocalStorage'
import useFirebaseContext from 'context/firebase'
import useUIContext from 'context/ui'

import { feedbackButtonVariants } from './variants'
import * as s from './SpinningButtonFeedback.module.scss'
import SpinningButton from './SpinningButton'

const cn = classNames.bind(s)

const getDecodedData = (str: string) => {
  const dataStr = 'data:image/svg+xml;base64,'
  return str.slice(str.indexOf(dataStr) + dataStr.length, str.length)
}

const FeedbackQuery = graphql`
  query FeedbackQuery {
    allPrismicSiteSettings {
      edges {
        node {
          _previewable
          lang
          data {
            feedback_form_cta_label
            feedback_form_cta_image {
              fixed {
                base64
              }
            }
            navigation_links {
              label
              icon {
                gatsbyImageData
              }
              link {
                uid
                type
              }
            }
          }
        }
      }
    }
  }
`

const TRANSITION_OPTS = { y: { duration: 0.3, type: 'spring', stiffness: 170 }, opacity: { duration: 0.2, delay: 0.1 } }
const TRANSITION_ENTER_OPTS = { ...TRANSITION_OPTS, opacity: { duration: 0.3 } }

const SpinningButtonFeedback = ({ hideOnScroll }: { hideOnScroll: boolean }) => {
  const lastScrollTop = useRef(0)
  const scrollControls = useAnimation()
  const initialScrollPosition = useRef<number | undefined>(undefined)

  const feedbackCTAShown = useUIContext(s => s.feedbackCTAShown)
  const setFeedbackCTAShown = useUIContext(s => s.setFeedbackCTAShown)
  const showFeedbackCTA = useUIContext(s => s.showFeedbackCTA)
  const setShowFeedbackCTA = useUIContext(s => s.setShowFeedbackCTA)
  const setShowFeedbackForm = useUIContext(s => s.setShowFeedbackForm)

  const isInitialLoad = useUIContext(s => s.isInitialLoad)
  const displayIntro = useMemo(() => isInitialLoad && process.env.NODE_ENV !== 'development', [isInitialLoad])

  const { pathname } = useLocation()
  const staticData = useStaticQuery(FeedbackQuery)
  const { data } = useMergePrismicPreviewData(staticData)
  const spent = useFirebaseContext(s => s.spentVotes)
  const completedFeedbackForm = readFromLocalStorage('completedFeedbackForm')

  const prismicSiteSettings = getLocalizedData(pathname, data?.allPrismicSiteSettings)

  const html = useMemo(() => {
    if (!prismicSiteSettings?.data?.feedback_form_cta_image?.fixed?.base64) return ''
    const base64 = prismicSiteSettings.data?.feedback_form_cta_image?.fixed?.base64
    if (!base64.startsWith('data:image/svg+xml;base64')) return `<img src="${base64}" alt='preview svg' width="100%" />`
    return atob(getDecodedData(base64))
  }, [prismicSiteSettings?.data?.feedback_form_cta_image?.fixed?.base64])

  useEffect(() => {
    if (spent && completedFeedbackForm.length === 0 && !displayIntro && !feedbackCTAShown) {
      setShowFeedbackCTA(true)
      setFeedbackCTAShown(true) /* CTA button should be dismissable */
    }
  }, [spent, completedFeedbackForm, displayIntro, setShowFeedbackCTA, setFeedbackCTAShown, feedbackCTAShown])

  const handleScroll = throttle((_e: Event) => {
    const st = window.pageYOffset || document.documentElement.scrollTop

    /* Ignore initial scroll position to avoid interference with initial scroll event triggered by browser */
    if (initialScrollPosition?.current === undefined) initialScrollPosition.current = st
    if (initialScrollPosition.current === st) {
      lastScrollTop.current = st <= 0 ? 0 : st
      return
    }

    if (st > lastScrollTop.current) {
      scrollControls.start({ y: -120, opacity: 0, pointerEvents: 'none', transition: TRANSITION_OPTS })
    } else {
      scrollControls.start({ y: 0, opacity: 1, pointerEvents: 'auto', transition: TRANSITION_ENTER_OPTS })
    }

    lastScrollTop.current = st <= 0 ? 0 : st
  }, 300)

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Tab') {
      scrollControls.start({ y: 0, opacity: 1, pointerEvents: 'auto', transition: TRANSITION_ENTER_OPTS })
      e.stopPropagation()
    } else if (e.key === 'Enter' || e.key === 'Space') {
      setShowFeedbackForm(true)
      e.stopPropagation()
    }
  }

  useEffect(() => {
    if (typeof window === 'undefined' || !hideOnScroll) return
    window.addEventListener('scroll', handleScroll, false)
    return () => window.removeEventListener('scroll', handleScroll, false)
  }, [handleScroll, hideOnScroll])

  if (!html) return null

  return (
    <AnimatePresence>
      {showFeedbackCTA && (
        <motion.div
          tabIndex={0}
          role='button'
          onKeyDown={handleKeyDown}
          className={cn('container')}
          animate={scrollControls}
          drag='x'
          dragConstraints={{ right: 0 }}
          onDragEnd={(e, info) => {
            e.preventDefault()
            if (info.point.x < 0) setShowFeedbackCTA(false)
          }}
        >
          <motion.div variants={feedbackButtonVariants} initial='initial' animate='animate' exit='initial'>
            <SpinningButton
              imageClassName={cn('image')}
              spinningText={html}
              label={prismicSiteSettings?.data?.videos_cta_label || ''}
              onClick={() => setShowFeedbackForm(true)}
              aria-haspopup='dialog'
            >
              <div className={cn('iconContainer')}>
                <Feedback className={cn('icon')} />
              </div>
            </SpinningButton>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default SpinningButtonFeedback
