// Read stored data from localStorage
// 'readFacts'(uid array) or 'viewedVideos'(youtube id array)

const readFromLocalStorage = (str: 'readFacts' | 'viewedVideos' | 'returningUser' | 'completedFeedbackForm') => {
  if (typeof window === 'undefined') return []
  const stored = window?.localStorage?.getItem(str)
  if (!stored) return []
  return stored.replace(/\s/g, '').split(',')
}

export default readFromLocalStorage
