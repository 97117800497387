import React, { useState, FormEvent } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'
import { useLocation } from '@reach/router'
import { ref, update } from 'firebase/database'
import { motion, AnimatePresence } from 'framer-motion'
import classNames from 'classnames/bind'

import writeToLocalStorage from 'lib/writeToLocalStorage'
import { useDatabase, useUser } from 'lib/firebase'
import getLocalizedData from 'lib/getLocalizedData'
import useUIContext from 'context/ui'
import useIsDesktop from 'lib/useIsDesktop'

import Question1 from './Question1'
import Question2 from './Question2'
import Question3 from './Question3'
import Confirmation from './Confirmation'
import Container from './Container'
import { IFormState, IFormData } from './FeedbackFormTypes'
import { variants } from './variants'
import * as s from './FeedbackForm.module.scss'

const cn = classNames.bind(s)

const FeedbackFormQuery = graphql`
  query FeedbackFormQuery {
    allPrismicSiteSettings {
      edges {
        node {
          _previewable
          lang
          data {
            question_1_title {
              text
            }
            question_1_yes
            question_1_no
            question_2_placeholder
            question_2_send
            question_2_skip
            question_2_title {
              text
            }
            question_3_placeholder
            question_3_send
            question_3_skip
            question_3_title {
              text
            }
            confirmation_button_label
            confirmation_description
            confirmation_title {
              text
            }
          }
        }
      }
    }
  }
`

const popupProps = (isDesktop: boolean) => [
  { popupX: 5 * (isDesktop ? 2.5 : 1), popupY: 5 * (isDesktop ? 2.5 : 1), delay: 0.3 },
  { popupX: 0, popupY: 0, delay: 0.2, keepNoScrollOnClose: true },
  { popupX: -5 * (isDesktop ? 2.5 : 1), popupY: -5 * (isDesktop ? 2.5 : 1), delay: 0.1, keepNoScrollOnClose: true },
  { popupX: -10 * (isDesktop ? 2.5 : 1), popupY: -10 * (isDesktop ? 2.5 : 1), delay: 0, keepNoScrollOnClose: true },
]

const SKIPPED_ANSWER = 'skipped'

const initialState = {
  question1: true,
  question2: true,
  question3: true,
  confirmation: true,
}

const FeedbackForm = ({ lang }: { lang?: string }) => {
  const isDesktop = useIsDesktop()
  const showFeedbackForm = useUIContext(s => s.showFeedbackForm)
  const setShowFeedbackCTA = useUIContext(s => s.setShowFeedbackCTA)
  const setShowFeedbackForm = useUIContext(s => s.setShowFeedbackForm)
  const [formData, setFormData] = useState<IFormData>({})

  const [formState, setFormState] = useState<IFormState>(initialState)

  /* Firebase */
  const database = useDatabase()
  const user = useUser()

  const staticData = useStaticQuery(FeedbackFormQuery)
  const { data } = useMergePrismicPreviewData(staticData)
  const { pathname } = useLocation()
  const prismicSiteSettings = getLocalizedData(pathname, data.allPrismicSiteSettings)
  if (!prismicSiteSettings?.data) return null

  const handleClose = () => {
    setFormState({ ...formState, confirmation: false })
    setShowFeedbackForm(false)
    setShowFeedbackCTA(false)
  }

  const submitToDatabase = (data: IFormData) => {
    let hasAnswered

    for (const entry in data) {
      if (data[entry].answer !== SKIPPED_ANSWER) hasAnswered = true
      break
    }

    if (!hasAnswered || !database || !user?.uid) return

    update(ref(database), { [`feedbackForm/${lang}/${user.uid}`]: data })
    writeToLocalStorage('true', 'completedFeedbackForm')
  }

  const handleQuestionSubmit = (e: FormEvent, order: number, question: string, answer?: string) => {
    setFormState({ ...formState, [`question${order}`]: false })

    const next = { ...formData, [question]: { answer: answer || SKIPPED_ANSWER } }
    setFormData(next)
    if (order === 3) submitToDatabase(next)

    e.preventDefault()
  }

  const handleQuestionDismiss = (order: number, question: string) => {
    setFormState({ ...formState, [`question${order}`]: false })

    const next = { ...formData, [question]: { answer: SKIPPED_ANSWER } }
    setFormData(next)
    if (order === 3) submitToDatabase(next)
  }

  const dismissForm = () => {
    setShowFeedbackForm(false)
    setFormState(initialState)
  }

  const {
    question_1_title,
    question_1_no,
    question_1_yes,

    question_2_title,
    question_2_placeholder,
    question_2_send,
    question_2_skip,

    question_3_title,
    question_3_placeholder,
    question_3_skip,
    question_3_send,

    confirmation_button_label,
    confirmation_description,
    confirmation_title,
  } = prismicSiteSettings.data

  return (
    <>
      <AnimatePresence>
        {showFeedbackForm && (
          <motion.div
            className={cn('fullscreenBg')}
            onClick={dismissForm}
            variants={variants}
            initial='initial'
            animate='animate'
            exit='exit'
            role='button'
          />
        )}
      </AnimatePresence>

      {/* CONFIRMATION – THANK YOU */}
      <Container
        onClose={handleClose}
        title={confirmation_title.text}
        description={confirmation_description}
        open={showFeedbackForm && formState.confirmation}
        inFocus={!formState.question3}
        {...popupProps(isDesktop)?.[0]}
      >
        <Confirmation
          animateHearts={!formState.question3}
          closeLabel={confirmation_button_label}
          onClose={handleClose}
        />
      </Container>

      {/* QUESTION 3 */}
      <Container
        onClose={() => handleQuestionDismiss(3, question_3_title.text)}
        open={showFeedbackForm && formState.question3}
        inFocus={!formState.question2}
        title={question_3_title.text}
        {...popupProps(isDesktop)?.[1]}
      >
        <Question3
          skipLabel={question_3_skip}
          sendLabel={question_3_send}
          inputLabel={question_3_placeholder}
          onSubmit={(e, answer) => handleQuestionSubmit(e, 3, question_3_title.text, answer)}
        />
      </Container>

      {/* QUESTION 2 */}
      <Container
        onClose={() => handleQuestionDismiss(2, question_2_title.text)}
        open={showFeedbackForm && formState.question2}
        inFocus={!formState.question1}
        title={question_2_title.text}
        {...popupProps(isDesktop)?.[2]}
      >
        <Question2
          skipLabel={question_2_skip}
          sendLabel={question_2_send}
          textAreaLabel={question_2_placeholder}
          onSubmit={(e, answer) => handleQuestionSubmit(e, 2, question_2_title.text, answer)}
        />
      </Container>

      {/* QUESTION 1 */}
      <Container
        title={question_1_title.text}
        titleNarrow
        inFocus={formState.question1}
        onClose={() => handleQuestionDismiss(1, question_1_title.text)}
        open={showFeedbackForm && formState.question1}
        {...popupProps(isDesktop)?.[3]}
      >
        <Question1
          yes={question_1_yes}
          no={question_1_no}
          onSubmit={(e, answer) => handleQuestionSubmit(e, 1, question_1_title.text, answer)}
        />
      </Container>
    </>
  )
}

export default FeedbackForm
