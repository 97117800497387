import React, { useEffect, useRef, SyntheticEvent } from 'react'
import classNames from 'classnames/bind'
import { useWindowSize } from '@react-hook/window-size'
import { motion, useSpring, useTransform } from 'framer-motion'

import { ViewTypes } from './InteractiveTextTypes'
import * as s from './InteractiveText.module.scss'
const cn = classNames.bind(s)

const _ = {
  xAngle: 18,
  yAngle: 12,
  ampX: 0.9,
  ampY: 0.9,
  y: -0.022,
  x: -0.015,
}

const View = ({ children, viewWidth, viewHeight, multiplierX, multiplierY, intensity }: ViewTypes) => {
  const [width, height] = useWindowSize({ wait: 300 })
  const ref = useRef<HTMLDivElement>(null)
  const rect = useRef<DOMRect>()

  const x = useSpring(0, { damping: 9 })
  const y = useSpring(0, { damping: 9 })

  const angleXPrimary = useTransform(y, [0, viewHeight], [-_.xAngle * intensity, _.xAngle * intensity])
  const angleYPrimary = useTransform(x, [0, viewWidth], [_.yAngle * intensity, -_.yAngle * intensity])
  const angleXSecondary = useTransform(
    y,
    [0, viewHeight],
    [-_.xAngle * _.ampX * intensity, _.xAngle * _.ampX * intensity],
  )
  const angleYSecondary = useTransform(
    x,
    [0, viewWidth],
    [_.yAngle * _.ampY * intensity, -_.yAngle * _.ampY * intensity],
  )
  const translateX = useTransform(x, [0, viewWidth], [viewWidth * _.x * intensity, -viewWidth * _.x * intensity])
  const translateY = useTransform(y, [0, viewHeight], [viewHeight * _.y * intensity, -viewHeight * _.y * intensity])
  const scaleX = useTransform(x, [0, viewWidth * 0.5, viewWidth], [1, 1.01, 1])
  const scaleY = useTransform(y, [0, viewHeight * 0.5, viewHeight], [1, 1.01, 1])

  useEffect(() => {
    x.set(viewWidth * 0.5)
    y.set(viewHeight * 0.5)
  }, [x, y, width, height, viewWidth, viewHeight])

  const onActive = () => {
    if (!ref.current) return
    rect.current = ref.current.getBoundingClientRect()
    ref.current.classList.add(cn('isActive'))
  }

  const onMouseMove = (event: MouseEvent) => {
    if (!rect.current) return
    const deltaX = (event.clientX - rect.current.left) * multiplierX
    const deltaY = (event.clientY - rect.current.top) * multiplierY
    x.set(deltaX)
    y.set(deltaY)
  }

  const onTouchMove = (event: SyntheticEvent) => {
    if (!rect.current) return
    const deltaX = (event.nativeEvent.targetTouches[0].clientX - rect.current.left) * multiplierX
    const deltaY = (event.nativeEvent.targetTouches[0].clientY - rect.current.top) * multiplierY
    x.set(deltaX)
    y.set(deltaY)
  }

  const onIdle = () => {
    x.set(viewWidth * 0.5)
    y.set(viewHeight * 0.5)
    ref.current?.classList.remove(cn('isActive'))
  }

  useEffect(() => {
    if (!ref.current) return
    rect.current = ref.current.getBoundingClientRect()
  }, [ref])

  return (
    <div ref={ref}>
      <motion.div
        className={cn('primary')}
        style={{
          rotateX: angleXPrimary,
          rotateY: angleYPrimary,
        }}
      >
        {children}
      </motion.div>
      <motion.div
        className={cn('secondary')}
        style={{
          rotateX: angleXSecondary,
          rotateY: angleYSecondary,
          translateX,
          translateY,
          scaleX,
          scaleY,
          translateZ: -viewHeight * 0.25 * Math.min(multiplierX, multiplierY),
        }}
      >
        <div className={cn('label')} aria-hidden='true'>
          {children}
        </div>
      </motion.div>

      <div
        className={cn('trigger')}
        onMouseEnter={onActive}
        onTouchStart={onActive}
        onMouseMove={onMouseMove}
        onTouchMove={onTouchMove}
        onMouseLeave={onIdle}
        onTouchEnd={onIdle}
      />
    </div>
  )
}

export default View
