import React from 'react'
import classNames from 'classnames/bind'

import { BaseType } from './TextTypes'
import SCALES from './scales'
import * as s from './Text.module.scss'

const cn = classNames.bind(s)

const Base: BaseType = ({ as: Component = 'p', scale = 'circularBody', children, className, ...props }) => {
  const family = scale.includes('circular') ? 'circular' : 'monument'
  const weight =
    scale.includes(family) && props.weight
      ? family + props.weight.slice(0, 1).toUpperCase() + props.weight.slice(1)
      : ''

  // Delete any props that aren't meant to be added to the html element
  const sanitizedProps = { ...props }
  delete sanitizedProps.weight

  return (
    <Component className={cn(SCALES[scale], className, weight)} {...sanitizedProps}>
      {children}
    </Component>
  )
}

// Montument Extended Black styles - start (default to weight `black` but have the option to override)
export const MonumentHeading1: BaseType = props => <Base as='h1' scale='monumentH1' weight='black' {...props} />
export const MonumentHeading2: BaseType = props => <Base as='h2' scale='monumentH2' weight='black' {...props} />
export const MonumentSubtitle: BaseType = props => <Base as='h3' scale='monumentSubtitle' weight='black' {...props} />
export const MonumentCard: BaseType = props => <Base as='h3' scale='monumentCard' weight='black' {...props} />
export const MonumentHashtag: BaseType = props => <Base as='h4' scale='monumentHashtag' weight='black' {...props} />
export const MonumentProgress: BaseType = props => <Base as='p' scale='monumentProgress' weight='black' {...props} />
export const MonumentSteps: BaseType = props => <Base as='h4' scale='monumentSteps' weight='black' {...props} />
export const MonumentNavigation: BaseType = props => (
  <Base as='h1' scale='monumentNavigation' weight='black' {...props} />
)
export const MonumentCaption: BaseType = props => <Base as='h5' scale='monumentCaption' weight='black' {...props} />
// Montument Extended styles - end

// Circular Standard Bold/Book styles - start
export const CircularHeading1: BaseType = props => <Base as='h1' scale='circularH1' {...props} />
export const CircularHeading2: BaseType = props => <Base as='h2' scale='circularH2' {...props} />
export const CircularHeading3: BaseType = props => <Base as='h3' scale='circularH3' {...props} />
export const CircularHeading4: BaseType = props => <Base as='h4' scale='circularH4' {...props} />
export const CircularCaption: BaseType = props => <Base as='h5' scale='circularCaption' {...props} />
export const CircularSteps: BaseType = props => <Base as='p' weight='book' scale='circularSteps' {...props} />
export const CircularStory: BaseType = props => <Base as='h3' weight='bold' scale='circularStory' {...props} />
export const CircularBody: BaseType = props => <Base as='p' scale='circularBody' {...props} />
export const CircularBodyLarge: BaseType = props => <Base as='p' scale='circularBodyLarge' {...props} />
// Circular Standard Bold/Book styles - end
