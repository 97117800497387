import React from 'react'
import classNames from 'classnames/bind'
import { GatsbyImage } from 'gatsby-plugin-image'

import { MonumentCard, CircularBody } from 'components/ui/Text'

import { CardContentTypes } from './CardsLayoutTypes'
import * as s from './CardContent.module.scss'
const cn = classNames.bind(s)

const CardContent = ({ backgroundLayer, foregroundLayer, layout, textContent, isMobile = false }: CardContentTypes) => {
  return (
    <>
      <div className={cn('content', layout, { isMobile })}>
        <div className={cn('info')} {...(!isMobile && { 'aria-hidden': true })}>
          {layout === 'fullscreen' ? (
            <MonumentCard as='h2' className={cn('text', 'fullscreen')}>
              {textContent}
            </MonumentCard>
          ) : (
            <CircularBody as='h2' className={cn('text')}>
              {textContent}
            </CircularBody>
          )}
        </div>
      </div>
      <div className={cn('layers', layout, { isMobile })}>
        <div className={cn('back', 'layer')}>
          {backgroundLayer?.thumbnails?.card?.gatsbyImageData && (
            <GatsbyImage
              className={cn('image')}
              objectFit={layout === 'fullscreen' ? 'cover' : 'contain'}
              image={backgroundLayer.thumbnails.card.gatsbyImageData}
              alt={backgroundLayer?.alt || ''}
              {...(!foregroundLayer?.alt && { role: 'presentation' })}
            />
          )}
        </div>
        <div className={cn('front', 'layer')}>
          {foregroundLayer?.thumbnails?.card?.gatsbyImageData && (
            <GatsbyImage
              className={cn('image')}
              objectFit={layout === 'fullscreen' ? 'cover' : 'contain'}
              image={foregroundLayer.thumbnails.card.gatsbyImageData}
              alt={foregroundLayer?.alt || ''}
              {...(!foregroundLayer?.alt && { role: 'presentation' })}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default CardContent
