import React, { memo } from 'react'
import classNames from 'classnames/bind'
import { AnimatePresence, motion } from 'framer-motion'
import { useWindowSize } from '@react-hook/window-size'

import { MonumentHeading1 } from 'components/ui/Text'
import useUIContext from 'context/ui'

import { displayVariants } from './variants'
import * as s from './CardsLayout.module.scss'
const cn = classNames.bind(s)

const HashtagDisplay = ({ text }: { text: string }) => {
  const [width] = useWindowSize()
  const isActive = useUIContext(s => s.isCardsLayoutActive)

  return (
    <div className={cn('hashtagDisplay', { isActive })}>
      {width >= parseInt(s.breakpointDesktop) && (
        <AnimatePresence exitBeforeEnter>
          <motion.div
            key={text + isActive.toString()}
            variants={displayVariants}
            initial='initial'
            animate='animate'
            exit='exit'
          >
            {isActive && <MonumentHeading1 className={cn('hashtagDisplayTitle')}>{text}</MonumentHeading1>}
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  )
}

export default memo(HashtagDisplay)
