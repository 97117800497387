import React, { useState, FormEvent } from 'react'
import classNames from 'classnames/bind'

import Spacer from 'components/core/Spacer'
import Input from 'components/ui/Input'
import { PrimaryButton, VoteButton } from 'components/ui/Buttons'

import * as s from './FeedbackForm.module.scss'
import { IQuestion3 } from './FeedbackFormTypes'
import { validateEmail } from './utils'

const cn = classNames.bind(s)

const Question3 = ({ sendLabel, skipLabel, inputLabel, onSubmit }: IQuestion3) => {
  const [value, setValue] = useState<string>('')

  const handleSubmit = (e: FormEvent) => {
    onSubmit(e, value)
    e.preventDefault()
  }

  return (
    <form className={cn('container')} onSubmit={handleSubmit}>
      <Input label={inputLabel} id='email' type='email' onChange={e => setValue(e)} />
      <Spacer initial='1.75rem' desktop='1.4vw' />
      <div className={cn('buttons')}>
        <PrimaryButton type='submit' onClick={() => setValue('')} formNoValidate>
          {skipLabel}
        </PrimaryButton>
        <VoteButton
          className={cn('submit')}
          classNameLabel={cn('submitLabel')}
          disabled={!validateEmail(value)}
          type='submit'
        >
          {sendLabel}
        </VoteButton>
      </div>
      <Spacer initial='1.75rem' hideOnDesktop />
    </form>
  )
}

export default Question3
