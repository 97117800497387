import React, { useRef, useState, useEffect, useLayoutEffect } from 'react'
import classNames from 'classnames/bind'
import { GatsbyImage } from 'gatsby-plugin-image'
import { motion, AnimatePresence } from 'framer-motion'
import { createPortal } from 'react-dom'
import { useWindowSize } from '@react-hook/window-size'

import useCopyContext from 'context/copy'
import trapFocusInModal from 'lib/trapFocusInModal'
import { CircularBody, CircularCaption } from 'components/ui/Text'
import Cross from 'assets/svg/cross.react.svg'

import { containerVariantsMobile, containerVariantsDesktop, contentVariants, backgroundVariants } from './variants'
import * as s from './Popup.module.scss'
import { PopupProps } from './PopupTypes'

const cn = classNames.bind(s)
const mediumBreakpoint = parseInt(s.breakpointMedium)

const Popup = ({
  title = null,
  children,
  onClose,
  containerClassName,
  className,
  style,
  open,
  variant,
  isAbsoluteOnMobile = false,
  top = 'calc(100% + 1.5vw)',
  left = '50%',
  translateX = '-50%',
  translateY = '0',
  ariaLabel,
}: PopupProps) => {
  const [isDesktop, setIsDesktop] = useState<boolean>(true)
  const [isMouseOver, setIsMouseOver] = useState<boolean>(false)
  const [width, height] = useWindowSize()
  const copy = useCopyContext(s => s.copy)

  const ref = useRef<HTMLDivElement>(null)
  const container = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setIsDesktop(width >= mediumBreakpoint)
  }, [width, height])

  useEffect(() => {
    if (open && !isDesktop && !isAbsoluteOnMobile) {
      document.documentElement.classList.add(cn('noScroll'))
    } else {
      document.documentElement.classList.remove(cn('noScroll'))
    }
  }, [isAbsoluteOnMobile, isDesktop, open])

  useLayoutEffect(() => {
    if (ref?.current && open) ref.current.focus()
  }, [open])

  const hasImage = !!children?.gatsbyImageData
  let desc = copy.dialog_description
  if (hasImage && children?.alt) desc = children.alt
  else if (typeof children === 'string') desc = children

  const renderPopup = () => (
    <AnimatePresence>
      {(open || (isDesktop && isMouseOver)) && (
        <motion.div
          key={children?.toString()}
          className={cn(
            'container',
            { wide: variant === 'wide', narrow: variant === 'narrow', isAbsoluteOnMobile },
            containerClassName,
          )}
          style={
            {
              '--left': left,
              '--top': top,
              '--translateX': translateX,
              '--translateY': translateY,
              ...style,
            } as React.CSSProperties
          }
          // @ts-ignore
          variants={isDesktop ? containerVariantsDesktop : containerVariantsMobile}
          initial='initial'
          animate='animate'
          exit='exit'
          ref={container}
        >
          {!isDesktop && !isAbsoluteOnMobile && (
            <motion.div
              className={cn('background')}
              onKeyDown={onClose}
              onClick={onClose}
              variants={backgroundVariants}
              initial='initial'
              animate='animate'
              exit='exit'
            />
          )}

          <motion.div
            ref={ref}
            className={cn('popup', { hasImage: !!children?.gatsbyImageData }, className)}
            role='dialog'
            aria-label={ariaLabel || desc}
            variants={contentVariants}
            initial='exit'
            animate='animate'
            exit='exit'
            onKeyDown={e => trapFocusInModal(e, ref)}
            onMouseEnter={() => {
              if (isDesktop) {
                setIsMouseOver(true)
              }
            }}
            onMouseLeave={() => {
              if (isDesktop) {
                setIsMouseOver(false)
              }
            }}
          >
            <div className={cn('header', { hasTitle: title })}>
              {title && (
                <CircularCaption weight='book' className={cn('title')}>
                  {title}
                </CircularCaption>
              )}

              <span
                role='button'
                tabIndex={0}
                onKeyDown={e => {
                  if (e.key === 'Enter' || e.key === 'Space') {
                    setIsMouseOver(false)
                    container?.current?.parentElement?.focus()
                    e.preventDefault()
                    onClose()
                  }
                }}
                onClick={() => {
                  if (isDesktop) {
                    setIsMouseOver(false)
                  }

                  onClose()
                }}
                className={cn('button')}
                aria-label={copy.close}
              >
                <Cross className={cn('icon')} aria-hidden='true' />
              </span>
            </div>

            {children?.gatsbyImageData ? (
              <GatsbyImage image={children.gatsbyImageData} alt={children?.alt || ''} className={cn('image')} />
            ) : (
              <>
                <div className={cn('content', { narrow: variant === 'narrow' })}>
                  {typeof children === 'string' ? (
                    <CircularBody weight='book' className={cn('text')}>
                      {children}
                    </CircularBody>
                  ) : (
                    children
                  )}
                </div>
              </>
            )}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )

  return isDesktop || isAbsoluteOnMobile ? renderPopup() : createPortal(renderPopup(), document.body)
}

export default Popup
