import React from 'react'
import classNames from 'classnames/bind'

import useUIContext from 'context/ui'

import * as s from './BackgroundCover.module.scss'
const cn = classNames.bind(s)

const BackgroundCover = () => {
  const transition = useUIContext(s => s.factPageTransitionStart)
  const setFactPageTransitionEnd = useUIContext(s => s.setFactPageTransitionEnd)

  return (
    <div className={cn('bgCover', { fill: transition })} onTransitionEnd={() => setFactPageTransitionEnd(true)}>
      <div />
    </div>
  )
}

export default BackgroundCover
