import React, { useState, useEffect } from 'react'
import classNames from 'classnames/bind'
import { useLocation, navigate } from '@reach/router'
import { motion } from 'framer-motion'

import useUIContext from 'context/ui'
import { MonumentHashtag } from 'components/ui/Text'
import { CloseButton } from 'components/ui/Buttons'

import * as s from './Header.module.scss'

const cn = classNames.bind(s)

const removeTrailingSlash = (str: string) => str.replace(/\/$/, '')

const FactHeader = ({ category }: { category: string }) => {
  const location = useLocation()
  const facts = useUIContext(s => s.globalFactsData)
  const isCardsLayoutFullyVisible = useUIContext(s => s.isCardsLayoutFullyVisible)

  const [activeCategory, setActiveCategory] = useState(category)
  const [pathnameCache, setPathnameCache] = useState('')

  useEffect(() => {
    // Avoid page updates and redirects if filtering hashtags (only updating on pathname change)
    if (!location.hash || !facts.length || pathnameCache === removeTrailingSlash(location.href)) return

    // Sanitize URL if a non-matching hashtag
    if (!facts.find(f => f.uid === location.pathname.replaceAll('/', ''))?.categories.includes(location.hash)) {
      navigate(location.pathname)
      return
    }

    // Update fact header with active category/hashtag from the URL
    ;(location.hash !== category || pathnameCache !== removeTrailingSlash(location.href)) &&
      setActiveCategory(location.hash)
    // Set flag
    setPathnameCache(removeTrailingSlash(location.href))
  }, [category, facts, location, pathnameCache])

  return (
    <>
      <motion.header
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 0.3 } }}
        className={cn('header', 'headerFact', { show: !isCardsLayoutFullyVisible, hide: isCardsLayoutFullyVisible })}
      >
        <MonumentHashtag as='h2' className={cn('category')}>
          {activeCategory}
        </MonumentHashtag>
        <CloseButton className={cn('exit')} to={`/${activeCategory}`} state={{ category: activeCategory }} />
      </motion.header>
    </>
  )
}

export default FactHeader
