import React, { useState, useEffect, useCallback } from 'react'
import classNames from 'classnames/bind'
import { RichText } from 'prismic-reactjs'
import { motion } from 'framer-motion'

import useIsDesktop from 'lib/useIsDesktop'
import htmlSerializer, { serializerSmall } from 'prismic/htmlSerializer'
import Popup from 'components/ui/Popup'
import { VoteButton } from 'components/ui/Buttons'

import * as s from './VotesPopup.module.scss'
import { VotesPopupProps } from './VotesPopupTypes'

const cn = classNames.bind(s)

const VotesPopup = ({ show, children, label, to }: VotesPopupProps) => {
  const [showVotesPopup, setShowVotesPopup] = useState<boolean>(false)
  const [preventAutoClose, setPreventAutoClose] = useState<boolean>(false)
  const isDesktop = useIsDesktop()

  useEffect(() => {
    let timerId: NodeJS.Timer | undefined
    if (show) timerId = setTimeout(() => setShowVotesPopup(true), 1000)
    return () => {
      if (timerId) clearTimeout(timerId)
    }
  }, [show])

  useEffect(() => {
    let timerId: NodeJS.Timer | undefined
    if (showVotesPopup) timerId = setTimeout(() => setShowVotesPopup(false), 3000)
    if (preventAutoClose && timerId) clearTimeout(timerId)

    return () => {
      if (timerId) clearTimeout(timerId)
    }
  }, [showVotesPopup, preventAutoClose])

  const handleClose = useCallback(() => {
    setShowVotesPopup(false)
    setPreventAutoClose(false)
  }, [])

  return (
    <motion.div onMouseEnter={() => setPreventAutoClose(true)} onMouseLeave={() => setPreventAutoClose(false)}>
      <Popup open={showVotesPopup} onClose={handleClose} variant='narrow' isAbsoluteOnMobile>
        <>
          {children?.raw && (
            <RichText render={children.raw} htmlSerializer={isDesktop ? serializerSmall() : htmlSerializer} />
          )}
          <div className={cn('buttonContainer')}>
            <VoteButton to={to} narrow onClick={handleClose} classNameLabel={cn('secondary')}>
              {label || ''}
            </VoteButton>
          </div>
        </>
      </Popup>
    </motion.div>
  )
}

export default VotesPopup
