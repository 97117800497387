import React from 'react'
import classNames from 'classnames/bind'

import Spacer from 'components/core/Spacer'
import { VoteButton } from 'components/ui/Buttons'
import Heart from 'assets/svg/heart.react.svg'

import * as s from './FeedbackForm.module.scss'
import { IConfirmation } from './FeedbackFormTypes'

const cn = classNames.bind(s)

const Confirmation = ({ closeLabel, onClose, animateHearts }: IConfirmation) => (
  <>
    <form
      className={cn('confirmationContainer')}
      onSubmit={e => {
        onClose()
        e.preventDefault()
      }}
    >
      <div className={cn('hearts', { animateHearts })}>
        {new Array(3).fill('').map((_, index) => (
          <div className={cn('heartContainer')} key={`heart-${index}`}>
            <Heart className={cn('heart')} />
          </div>
        ))}
      </div>
      <div className={cn('buttons')}>
        <VoteButton className={cn('submit')} classNameLabel={cn('submitLabel')} type='submit'>
          {closeLabel}
        </VoteButton>
      </div>
    </form>
    <Spacer initial='0.5rem' hideOnDesktop />
  </>
)

export default Confirmation
