/**
 * Check if email is valid.
 *
 * Regex used by standard email inputs
 * More information: https://html.spec.whatwg.org/multipage/input.html#e-mail-state-(type=email)
 */
export const validateEmail = (email: string) => {
  const re =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
  return re.test(String(email).toLowerCase())
}
