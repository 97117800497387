import React, { useState, useRef, SyntheticEvent, useEffect, useCallback } from 'react'
import classNames from 'classnames/bind'
import { useMotionValue } from 'framer-motion'
import { useWindowSize } from '@react-hook/window-size'

import useUIContext from 'context/ui'
import { requestIdleCallback } from 'lib/requestIdleCallback'

import ViewMobile from './ViewMobile'
import ViewDesktop from './ViewDesktop'
import { CardsTypes } from './CardsLayoutTypes'
import * as s from './CardsLayout.module.scss'
const cn = classNames.bind(s)

const Cards = ({ categories, selectedCategory, lang, isActive, debug = false }: CardsTypes) => {
  const isCardsLayoutHovered = useUIContext(s => s.isCardsLayoutHovered)
  const setIsCardsLayoutHovered = useUIContext(s => s.setIsCardsLayoutHovered)

  const [allowAllToMount, setAllowAllToMount] = useState<boolean>(false) // Do not allow other categories to mount until the selected one has finished animating.
  const preloadCategory = useUIContext(s => s.preloadCategory)
  const [startLoading, setStartLoading] = useState<string[]>(preloadCategory ? [preloadCategory] : [])

  const mouseX = useMotionValue(null)
  const mouseY = useMotionValue(null)

  const wrapper = useRef<HTMLDivElement>(null)
  const wrapperWidth = useRef(0)
  const wrapperOffset = useRef(0)
  const [width, height] = useWindowSize()
  const horizontalCover = useRef<HTMLDivElement>(null)

  const [isDesktop, setIsDesktop] = useState(false)

  useEffect(() => {
    if (preloadCategory && startLoading.indexOf(preloadCategory) === -1) {
      setStartLoading([...startLoading, preloadCategory])
    }
  }, [preloadCategory, startLoading])

  useEffect(() => {
    setIsDesktop(width >= parseInt(s.breakpointDesktop))
  }, [width, height])

  const onMouseMove = (event: SyntheticEvent) => {
    mouseX.set(event.nativeEvent.x)
    mouseY.set(event.nativeEvent.y)
    !isCardsLayoutHovered && setIsCardsLayoutHovered(true)
  }

  const onMouseLeave = () => {
    mouseX.set(null)
    mouseY.set(null)
    setIsCardsLayoutHovered(false)
  }

  useEffect(() => {
    if (!wrapper.current) return
    wrapperOffset.current = wrapper.current.offsetLeft
    wrapperWidth.current = wrapper.current.clientWidth
  }, [width, height, wrapper])

  useEffect(() => {
    if (!categories || !selectedCategory || !horizontalCover.current) return

    const factsLength = categories.find(c => c.title === selectedCategory)?.facts?.length

    if (!factsLength) return

    const xCoverSize = ((10 - factsLength) / 2) * 10

    horizontalCover.current.style.setProperty('--xCoverSize', `${Math.min(xCoverSize, 40)}%`)
  }, [categories, selectedCategory, horizontalCover, width, height])

  const onSelectedReady = useCallback(() => {
    /* Give the browser some time to breath. */
    requestIdleCallback(() => setAllowAllToMount(true))
  }, [setAllowAllToMount])

  return (
    <>
      <div
        className={cn('cardsWrapper', { debug })}
        ref={wrapper}
        onMouseMove={isActive && isDesktop ? onMouseMove : undefined}
        onMouseLeave={isActive && isDesktop ? onMouseLeave : undefined}
      >
        <div className={cn('cardsInner')}>
          {categories.map(category => {
            const initialCategory = category.title === selectedCategory
            const isSelected = category.title === selectedCategory && isActive // using isActive prop to allow for intro to base animation on CardsLayoutTrigger actions

            if (!initialCategory && !allowAllToMount) return null // Wait with mounting all categories until browser is ready – avoids warning about excessive DOM size
            const visible = !!(initialCategory || (allowAllToMount && startLoading.indexOf(category.title) !== -1))

            return isDesktop ? (
              <ViewDesktop
                key={category.title}
                lang={lang}
                category={category}
                isSelected={isSelected || false}
                mouseX={isSelected ? mouseX : null}
                mouseY={isSelected ? mouseY : null}
                wrapperOffset={wrapperOffset.current}
                wrapperWidth={wrapperWidth.current}
                allowAllToMount={allowAllToMount}
                visible={visible}
                onSelectedReady={onSelectedReady}
              />
            ) : (
              <ViewMobile
                key={category.title}
                lang={lang}
                category={category}
                isSelected={isSelected || false}
                allowAllToMount={allowAllToMount}
                visible={visible}
                onSelectedReady={onSelectedReady}
              />
            )
          })}
        </div>
      </div>
      {/* Used to limit horizontal interactive area */}
      <div className={cn('horizontalCover')} ref={horizontalCover}>
        <div className={cn('coverLeft')} />
        <div className={cn('coverRight')} />
      </div>
    </>
  )
}

export default Cards
