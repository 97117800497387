export const containerVariantsMobile = {
  initial: {
    pointerEvents: 'none',
  },
  animate: {
    pointerEvents: 'auto',
  },
  exit: {
    pointerEvents: 'none',
    transition: {
      duration: 0.1,
    },
  },
}

export const containerVariantsDesktop = {
  initial: {
    pointerEvents: 'auto',
  },
  animate: {
    pointerEvents: 'auto',
  },
  exit: {
    pointerEvents: 'none',
    transition: {
      delay: 0.2,
    },
  },
}

export const contentVariants = {
  animate: {
    y: '0px',
    opacity: 1,
    transition: {
      y: {
        type: 'spring',
        stiffness: 170,
      },
    },
  },
  exit: {
    y: '50px',
    opacity: 0,
    transition: {
      y: {
        type: 'spring',
        stiffness: 170,
      },
    },
  },
}

const CONTENT_TRANSITION = {
  type: 'spring',
  stiffness: 100,
  duration: 0.75,
}
const CONTENT_TRANSITION_EXIT = {
  type: 'spring',
  stiffness: 100,
  duration: 0.5,
}

export const fullScreenContentVariants = {
  initial: (custom: { x?: number; y?: number }) => ({
    y: (custom?.y || 0) + 50 || 50,
    x: custom?.x || 0,
    opacity: 0,
    transition: { y: CONTENT_TRANSITION, x: CONTENT_TRANSITION },
  }),
  animate: (custom: { x?: number; y?: number; delay?: number }) => ({
    y: custom?.y || 0,
    x: custom?.x || 0,
    opacity: 1,
    transition: {
      y: {
        ...CONTENT_TRANSITION,
        delay: custom?.delay || 0,
      },
      x: {
        ...CONTENT_TRANSITION,
        delay: custom?.delay || 0,
      },
      opacity: {
        delay: custom?.delay || 0,
        duration: 0.2,
      },
    },
  }),
  exit: (custom: { x?: number; y?: number }) => ({
    y: (custom?.y || 0) + 60 || 60,
    opacity: 0,
    transition: { y: CONTENT_TRANSITION_EXIT, x: CONTENT_TRANSITION_EXIT, opacity: { delay: 0.19, duration: 0.2 } },
  }),
}

export const backgroundVariants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
    transition: {
      type: 'spring',
    },
  },
}
