import React from 'react'
import classNames from 'classnames/bind'

import * as s from './Input.module.scss'
import { IInput } from './InputTypes'

const cn = classNames.bind(s)

const Input = ({ label, id, type, onChange }: IInput) => (
  <div className={cn('container')}>
    <label className={cn('label')} htmlFor={id}>
      {label}
    </label>
    <input
      onChange={e => onChange && onChange(e.target.value)}
      className={cn('input')}
      id={id}
      placeholder={label}
      name={id}
      type={type}
      autoComplete='true'
    />
  </div>
)

export default Input
