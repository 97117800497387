import React from 'react'
import { RichText } from 'prismic-reactjs'
import classNames from 'classnames/bind'
import { GatsbyImage } from 'gatsby-plugin-image'

import { serializerSmall } from 'prismic/htmlSerializer'
import { linkResolver } from 'prismic/linkResolver'
import Facebook from 'assets/svg/facebook.react.svg'
import Instagram from 'assets/svg/instagram.react.svg'
import YouTube from 'assets/svg/youtube.react.svg'
import { Row, Column } from 'components/core/Grid'
import Clickable from 'components/core/Clickable'
import { CircularCaption } from 'components/ui/Text'
import Container from 'components/core/Container'

import * as s from './Footer.module.scss'
const cn = classNames.bind(s)
import { FooterProps } from './FooterTypes'

const icons = {
  instagram: <Instagram className={cn('icon')} aria-hidden='true' />,
  facebook: <Facebook className={cn('icon')} aria-hidden='true' />,
  youtube: <YouTube className={cn('icon')} aria-hidden='true' />,
}

const Footer = ({ data }: FooterProps) => (
  <footer className={cn('footer')}>
    <Container className={cn('content')}>
      <div className={cn('upper')}>
        <Row>
          <Column initial={0} medium={2} />
          <Column initial={6} medium={2} className={cn('logos')}>
            {data?.footer_logos?.map((l, index) => {
              if (!l?.footer_logo_image?.gatsbyImageData) return null
              return (
                <GatsbyImage
                  key={`logo-${index}`}
                  objectFit='contain'
                  className={cn('image')}
                  image={l.footer_logo_image.gatsbyImageData}
                  alt={l.footer_logo_image.alt || ''}
                />
              )
            })}
          </Column>
          <Column initial={0} medium={2} />
        </Row>
        <Row>
          <Column initial={0} medium={1} />
          <Column initial={6} medium={4}>
            <RichText render={data?.footer_description?.raw} htmlSerializer={serializerSmall(cn('description'))} />
          </Column>
          <Column initial={0} medium={1} />
        </Row>
      </div>
      <Row className={cn('below')}>
        <Column initial={0} large={1} />
        <Column initial={6} large={3} className={cn('meta')}>
          <CircularCaption className={cn('text', 'border')} as='p'>
            <span className={cn('copyright')}>{data?.footer_copyright}</span>
          </CircularCaption>
          {data.footer_bottom_links?.map((link, index) => {
            if (!link?.footer_bottom_link?.uid) return null
            return (
              <Clickable className={cn('text')} key={`bottomlink-${index}`} to={linkResolver(link.footer_bottom_link)}>
                <CircularCaption as='span' className={cn('text')}>
                  {link.footer_bottom_link_text}
                </CircularCaption>
              </Clickable>
            )
          })}
        </Column>
        <Column initial={6} large={1} className={cn('socmed')}>
          {data.footer_social_links?.map((link, index) => {
            if (!link.footer_social_link?.url) return null

            return (
              <Clickable
                key={`social-${index}`}
                to={link.footer_social_link.url}
                className={cn('link')}
                aria-label={link.footer_social_link_text}
              >
                {/* @ts-ignore */}
                {icons[link.footer_social_link_icon]}
              </Clickable>
            )
          })}
        </Column>
        <Column initial={6} large={1} />
      </Row>
    </Container>
  </footer>
)

export default Footer
