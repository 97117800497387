// extracted by mini-css-extract-plugin
export var colorBlack = "#000";
export var colorGray = "gray";
export var colorDarkGray = "#262626";
export var colorLightGray = "#e5e5e5";
export var colorWhite = "#fff";
export var colorElectricBlue = "#0000fe";
export var colorPastelBlue = "#bbe5fe";
export var colorCandyPink = "#f9709a";
export var colorPastelPink = "#ffcdd2";
export var colorDefaultLime = "#cef564";
export var colorPastelLime = "#eeffbe";
export var colorBlueLagoon = "#9cf0e1";
export var colorPastelLagoon = "#ceffec";
export var colorDefaultLavender = "#8738f6";
export var colorPastelLavender = "#eadcff";
export var colorBrightYellow = "#ffe500";
export var colorDefaultGreen = "#12b968";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var colCount = "6";
export var colWidth = "16.6666666667%";
export var container = "Popup-module--container--ZQEd8";
export var isAbsoluteOnMobile = "Popup-module--isAbsoluteOnMobile--15I5W";
export var isFullScreen = "Popup-module--isFullScreen--aDjTY";
export var background = "Popup-module--background--1v5vw";
export var popup = "Popup-module--popup--2NKNM";
export var fullScreenPopup = "Popup-module--fullScreenPopup--3zRKm";
export var hasImage = "Popup-module--hasImage--3_GJ0";
export var narrow = "Popup-module--narrow--2LqGx";
export var wide = "Popup-module--wide--2umjG";
export var header = "Popup-module--header--1lfYI";
export var headerImitation = "Popup-module--headerImitation--cOhej";
export var hasTitle = "Popup-module--hasTitle--2jDnZ";
export var button = "Popup-module--button--cdOaD";
export var icon = "Popup-module--icon--EEVFc";
export var image = "Popup-module--image--3d_ne";
export var content = "Popup-module--content--3nBJu";
export var fullScreenContent = "Popup-module--fullScreenContent--5_CHJ";
export var text = "Popup-module--text--1Rj_P";