import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useWindowSize } from '@react-hook/window-size'

import { ParallaxStickyType } from './ScrollParallaxTypes'

export const ParallaxSticky: ParallaxStickyType = ({ as = 'div', children, style, ...props }) => {
  const [beforeW, beforeH] = useWindowSize({ wait: 0 })
  // ScrollParallax has a debounce of 200ms and recalculates positions while it's relative
  const [afterW, afterH] = useWindowSize({ wait: 300 })
  const [position, setPosition] = useState('relative')
  const Component = as

  // Revert to relative on resize
  useLayoutEffect(() => {
    setPosition('relative')
  }, [beforeW, beforeH])

  // Reset to sticky after resize
  useEffect(() => {
    const timer = setTimeout(() => {
      setPosition('sticky')
    }) // important delay for when reloading while scrolled down on the page
    return () => clearTimeout(timer)
  }, [afterW, afterH])

  const visibility = position !== 'sticky' ? 'hidden' : 'visible'
  return (
    <Component style={{ position, visibility, ...style }} {...props}>
      {children}
    </Component>
  )
}

export default ParallaxSticky
