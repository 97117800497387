// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-about-tsx": () => import("./../../../src/templates/about.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-fact-tsx": () => import("./../../../src/templates/fact.tsx" /* webpackChunkName: "component---src-templates-fact-tsx" */),
  "component---src-templates-homepage-tsx": () => import("./../../../src/templates/homepage.tsx" /* webpackChunkName: "component---src-templates-homepage-tsx" */),
  "component---src-templates-privacy-tsx": () => import("./../../../src/templates/privacy.tsx" /* webpackChunkName: "component---src-templates-privacy-tsx" */),
  "component---src-templates-progress-tsx": () => import("./../../../src/templates/progress.tsx" /* webpackChunkName: "component---src-templates-progress-tsx" */),
  "component---src-templates-solutions-tsx": () => import("./../../../src/templates/solutions.tsx" /* webpackChunkName: "component---src-templates-solutions-tsx" */),
  "component---src-templates-videos-tsx": () => import("./../../../src/templates/videos.tsx" /* webpackChunkName: "component---src-templates-videos-tsx" */)
}

