import { defaultLanguage } from '../../prismic-configuration'

const getLocalizedPath = (uid: string, lang: string, category?: string) => {
  const cat = category || ''

  if (lang === defaultLanguage) return uid ? `/${uid}/${cat}` : `/${cat}`

  return uid ? `/${lang}/${uid}/${cat}` : `/${lang}/${cat}`
}

export default getLocalizedPath
