import React, { useEffect, useCallback, useMemo, useState, useLayoutEffect } from 'react'
import classNames from 'classnames/bind'
import { easeQuadOut } from 'd3-ease'
import { motion, useAnimation, useViewportScroll } from 'framer-motion'
import { useStaticQuery, graphql } from 'gatsby'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'
import { useLocation } from '@reach/router'
import { useWindowSize } from '@react-hook/window-size'

import writeToLocalStorage from 'lib/writeToLocalStorage'
import readFromLocalStorage from 'lib/readFromLocalStorage'
import useParallaxScroll from 'lib/useParallaxScroll'
import useIsDesktop from 'lib/useIsDesktop'
import getLocalizedData from 'lib/getLocalizedData'
import useUIContext from 'context/ui'

import Section1 from './Section1'
import Section2 from './Section2'
import Section3 from './Section3'
import Section4 from './Section4'
import Section1Mobile from './Section1Mobile'
import Section2Mobile from './Section2Mobile'
import Section3Mobile from './Section3Mobile'
import Section4Mobile from './Section4Mobile'
import * as s from './Intro.module.scss'
const cn = classNames.bind(s)

const introQuery = graphql`
  query introQuery {
    allPrismicSiteSettings {
      edges {
        node {
          _previewable
          lang
          data {
            scroll_text
            skip_text
          }
        }
      }
    }
  }
`

const Intro = () => {
  const scrollY = useParallaxScroll(0.1)
  const [visible, setVisible] = useState(true)
  const { scrollYProgress } = useViewportScroll()
  const controls = useAnimation()
  const isDesktop = useIsDesktop()
  const HEIGHT = useMemo(() => (isDesktop ? '1020vh' : '550vh'), [isDesktop])
  const setIsCardsLayoutActive = useUIContext(s => s.setIsCardsLayoutActive)
  const setIsCardsLayoutFullyVisible = useUIContext(s => s.setIsCardsLayoutFullyVisible)
  const [width, height] = useWindowSize({ wait: 200 })
  const [resized, setResized] = useState(false)
  const [initialWidth, setIntialWidth] = useState<number | null>(null)

  const displayIntro = useUIContext(s => s.isInitialLoad)
  const [complete, setComplete] = useState<boolean>(!displayIntro)

  // Scroll to top whenever user resize their window to avoid scroll bug
  useLayoutEffect(() => {
    if (complete) return

    /* Exit early first time since it's triggered by initial width */
    if (!initialWidth && width && height) {
      setIntialWidth(width)
      return
    }

    window.scrollTo(0, 0)
    scrollYProgress.set(0)
    setIsCardsLayoutActive(false)
    setIsCardsLayoutFullyVisible(false)
    setResized(true)

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [width, height, setIsCardsLayoutActive, setIsCardsLayoutFullyVisible, setResized, complete])

  const { pathname } = useLocation()
  const staticData = useStaticQuery(introQuery)
  const { data } = useMergePrismicPreviewData(staticData)

  const prismicSiteSettings = getLocalizedData(pathname, data.allPrismicSiteSettings)

  useEffect(() => {
    window.onbeforeunload = () => window.scrollTo(0, 0) // Always start at top if user reloads
  }, [])

  const fadeOut = useCallback(() => {
    controls.start({ opacity: 0, transition: { duration: 0.4, ease: easeQuadOut } }).then(() => {
      window.scrollTo(0, document.body.scrollHeight)
      setIsCardsLayoutActive(true)
      setIsCardsLayoutFullyVisible(true)
      setComplete(true)
      scrollYProgress.set(1) // Update value so that `CardsLayoutTrigger` have the right information, needed in Firefox
    })
  }, [controls, scrollYProgress, setIsCardsLayoutActive, setIsCardsLayoutFullyVisible, setComplete])

  const toggle = useCallback(
    (exit: boolean) => {
      // Scrolled to bottom
      if (exit && visible) {
        fadeOut()
        setVisible(false)
        // Scrolling back up
      } else if (!exit && !visible) {
        controls.start({ opacity: 1, transition: { duration: 0.4, ease: easeQuadOut } }).then(() => {
          setIsCardsLayoutActive(false)
        })
        setVisible(true)
      }
    },
    [visible, setIsCardsLayoutActive, setVisible, controls, fadeOut],
  )

  const showSkip = useMemo(() => {
    if (resized) return true
    const returning = readFromLocalStorage('returningUser')
    if (returning.length) {
      return !!readFromLocalStorage('returningUser')
    } else {
      writeToLocalStorage('true', 'returningUser')
      return false
    }
  }, [resized])

  if (!displayIntro) return null

  return (
    <motion.div className={cn('intro')} animate={controls}>
      <main className={cn('main')}>
        {isDesktop ? (
          <>
            <Section1
              scrollY={scrollY}
              showSkip={showSkip}
              onSkip={fadeOut}
              data={prismicSiteSettings?.data && prismicSiteSettings.data}
            />
            <Section2 scrollY={scrollY} />
            <Section3 scrollY={scrollY} />
            <Section4 scrollY={scrollY} toggle={toggle} />
          </>
        ) : (
          <>
            <Section1Mobile
              showSkip={showSkip}
              scrollY={scrollY}
              onSkip={fadeOut}
              data={prismicSiteSettings?.data && prismicSiteSettings.data}
            />
            <Section2Mobile scrollY={scrollY} />
            <Section3Mobile scrollY={scrollY} />
            <Section4Mobile scrollY={scrollY} toggle={toggle} />
          </>
        )}
      </main>
      <div style={{ height: HEIGHT }} />
    </motion.div>
  )
}

export default Intro
