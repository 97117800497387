import { useEffect, useState } from 'react'
import { getAuth, Auth } from 'firebase/auth'

import { useFirebase } from 'lib/firebase'

export default function useAuth() {
  const firebase = useFirebase()
  const [auth, setAuth] = useState<undefined | Auth>()

  useEffect(() => {
    if (!firebase) return
    setAuth(getAuth())
  }, [firebase])

  return auth
}
