export default {
  animate: { opacity: 1, y: '-50%', x: '40%' },
  initial: { opacity: 0, y: '0%', x: '40%' },
  exit: { opacity: 0, y: '0%', x: '40%' },
}

export const containerVariants = {
  animate: { opacity: 1, y: '-50%', x: '40%' },
  initial: { opacity: 0, y: '0%', x: '40%' },
  exit: { opacity: 0, y: '0%', x: '40%' },
}

export const plusOneVariants = {
  visible: {
    rotate: ['45deg', '45deg'],
    y: ['0vw', '-2vw'],
    x: ['-0.6vw', '1.2vw'],
    opacity: [0, 1, 0],
    scale: [0.2, 1],
  },
  hidden: {
    rotate: ['45deg', '45deg'],
    y: '-2vw',
    x: '1.2vw',
    scale: 1,
    opacity: 0,
  },
}
