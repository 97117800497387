import React, { useEffect, useLayoutEffect, KeyboardEvent, useRef } from 'react'
import classNames from 'classnames/bind'
import { motion, AnimatePresence } from 'framer-motion'

import { CircularCaption } from 'components/ui/Text'
import Cross from 'assets/svg/cross.react.svg'
import trapFocusInModal from 'lib/trapFocusInModal'

import { fullScreenContentVariants } from './variants'
import * as s from './Popup.module.scss'
import { IPopupFullscreen } from './PopupTypes'

const cn = classNames.bind(s)

const PopupFullscreen = ({
  title,
  children,
  onClose,
  open,
  keepNoScrollOnClose,
  inFocus,
  popupX = 0,
  popupY = 0,
  delay = 0,
}: IPopupFullscreen) => {
  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (open) document.documentElement.classList.add(cn('noScroll'))
    else if (!keepNoScrollOnClose) document.documentElement.classList.remove(cn('noScroll'))
  }, [open, keepNoScrollOnClose])

  useLayoutEffect(() => {
    if (inFocus && ref?.current && open) {
      ref.current.focus()
    }
  }, [inFocus, open])

  const closeOnEnter = (e: KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === 'Space') onClose()
  }

  return (
    <AnimatePresence>
      {open && (
        <div className={cn('container', 'isFullScreen')}>
          <motion.div
            ref={ref}
            className={cn('popup', 'fullScreenPopup')}
            role='dialog'
            aria-label='Dialog with extended information about the subject'
            variants={fullScreenContentVariants}
            initial='initial'
            animate='animate'
            exit='exit'
            onKeyDown={e => trapFocusInModal(e, ref)}
            custom={{ x: popupX, y: popupY, delay }}
            {...(inFocus && { tabIndex: 0 })}
          >
            <div className={cn('header', { hasTitle: title })}>
              {title && (
                <CircularCaption weight='book' className={cn('title')}>
                  {title}
                </CircularCaption>
              )}
              <button onKeyDown={closeOnEnter} onClick={onClose} className={cn('button')} aria-label='Close'>
                <Cross className={cn('icon')} aria-hidden='true' />
              </button>
            </div>
            <div className={cn('fullScreenContent')}>{children}</div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  )
}

export default PopupFullscreen
