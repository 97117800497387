// extracted by mini-css-extract-plugin
export var colorBlack = "#000";
export var colorGray = "gray";
export var colorDarkGray = "#262626";
export var colorLightGray = "#e5e5e5";
export var colorWhite = "#fff";
export var colorElectricBlue = "#0000fe";
export var colorPastelBlue = "#bbe5fe";
export var colorCandyPink = "#f9709a";
export var colorPastelPink = "#ffcdd2";
export var colorDefaultLime = "#cef564";
export var colorPastelLime = "#eeffbe";
export var colorBlueLagoon = "#9cf0e1";
export var colorPastelLagoon = "#ceffec";
export var colorDefaultLavender = "#8738f6";
export var colorPastelLavender = "#eadcff";
export var colorBrightYellow = "#ffe500";
export var colorDefaultGreen = "#12b968";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var colCount = "6";
export var colWidth = "16.6666666667%";
export var card = "CardDesktop-module--card--2ZSHX";
export var debug = "CardDesktop-module--debug--1Sn0o";
export var cardWrapper = "CardDesktop-module--cardWrapper--FZKT_";
export var cardInner = "CardDesktop-module--cardInner--1ce1d";
export var cardRef = "CardDesktop-module--cardRef--JirWi";
export var link = "CardDesktop-module--link--32zq7";
export var frontface = "CardDesktop-module--frontface--2d4Na";
export var skewer = "CardDesktop-module--skewer--2CcwG";
export var isRead = "CardDesktop-module--isRead--2Oz76";
export var checkmark = "CardDesktop-module--checkmark--2vy7l";
export var backface = "CardDesktop-module--backface--2LCgA";
export var white = "CardDesktop-module--white--23vCm";