// extracted by mini-css-extract-plugin
export var colorBlack = "#000";
export var colorGray = "gray";
export var colorDarkGray = "#262626";
export var colorLightGray = "#e5e5e5";
export var colorWhite = "#fff";
export var colorElectricBlue = "#0000fe";
export var colorPastelBlue = "#bbe5fe";
export var colorCandyPink = "#f9709a";
export var colorPastelPink = "#ffcdd2";
export var colorDefaultLime = "#cef564";
export var colorPastelLime = "#eeffbe";
export var colorBlueLagoon = "#9cf0e1";
export var colorPastelLagoon = "#ceffec";
export var colorDefaultLavender = "#8738f6";
export var colorPastelLavender = "#eadcff";
export var colorBrightYellow = "#ffe500";
export var colorDefaultGreen = "#12b968";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var colCount = "6";
export var colWidth = "16.6666666667%";
export var row = "Grid-module--row--8AgEU";
export var wide = "Grid-module--wide--3_Bi3";
export var verticalGutter = "Grid-module--verticalGutter--3U729";
export var col = "Grid-module--col--2hu0x";
export var col0 = "Grid-module--col--0--3zoku";
export var col1 = "Grid-module--col--1--XWMn9";
export var col2 = "Grid-module--col--2--222OX";
export var col3 = "Grid-module--col--3--2K-jq";
export var col4 = "Grid-module--col--4--17tjt";
export var col5 = "Grid-module--col--5--1ITPI";
export var col6 = "Grid-module--col--6--12I2d";
export var colMedium0 = "Grid-module--col--medium--0--1QC7F";
export var colMedium1 = "Grid-module--col--medium--1--3XM4Y";
export var colMedium2 = "Grid-module--col--medium--2--3pi5o";
export var colMedium3 = "Grid-module--col--medium--3--xWXHj";
export var colMedium4 = "Grid-module--col--medium--4--3GXWg";
export var colMedium5 = "Grid-module--col--medium--5--14CpP";
export var colMedium6 = "Grid-module--col--medium--6--3UKWK";
export var colDesktop0 = "Grid-module--col--desktop--0--3mX9L";
export var colDesktop1 = "Grid-module--col--desktop--1--_LwcC";
export var colDesktop2 = "Grid-module--col--desktop--2--100xE";
export var colDesktop3 = "Grid-module--col--desktop--3--zPWPH";
export var colDesktop4 = "Grid-module--col--desktop--4--2pAah";
export var colDesktop5 = "Grid-module--col--desktop--5--2Nho8";
export var colDesktop6 = "Grid-module--col--desktop--6--1vI76";
export var colLarge0 = "Grid-module--col--large--0--3kBDX";
export var colLarge1 = "Grid-module--col--large--1--M9Fdn";
export var colLarge2 = "Grid-module--col--large--2--2tULI";
export var colLarge3 = "Grid-module--col--large--3--2c1BB";
export var colLarge4 = "Grid-module--col--large--4--1hXTz";
export var colLarge5 = "Grid-module--col--large--5--pRqxF";
export var colLarge6 = "Grid-module--col--large--6--27YNj";