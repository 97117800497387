// Adds fact uid into localStorage array 'readFacts'
// Adds videos youtube id into localStorage array 'viewedVideos'
// Adds information about whether or not the user has visited before (used in Intro)

const writeToLocalStorage = (
  uid: string,
  key: 'readFacts' | 'viewedVideos' | 'returningUser' | 'completedFeedbackForm',
) => {
  if (typeof window === 'undefined') return
  if (localStorage.getItem(key) === null) {
    localStorage.setItem(key, uid)
    return
  }
  const entries = localStorage.getItem(key)
  if (!entries?.includes(uid)) {
    localStorage.setItem(key, `${entries}, ${uid}`)
  }
}

export default writeToLocalStorage
