/**
 * Takes a string and returns a decoded version for it, useful when wanting to render
 * special characters like `&shy;`
 */
const renderSpecialCharacters = (str: string) => {
  if (typeof window === 'undefined') return str.replace(/&shy;/g, '')
  const parser = DOMParser ? new DOMParser() : null
  if (!parser) return str.replace(/&shy;/g, '')
  const decodedString = parser.parseFromString(`<!doctype html><body>${str}`, 'text/html').body.textContent
  return decodedString
}

export default renderSpecialCharacters
