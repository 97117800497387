import React from 'react'
import classNames from 'classnames/bind'

import * as s from './TextArea.module.scss'
import { ITextArea } from './TextAreaTypes'

const cn = classNames.bind(s)

const TextArea = ({ label, id, onChange }: ITextArea) => (
  <div className={cn('container')}>
    <label className={cn('label')} htmlFor={id}>
      {label}
    </label>
    <textarea
      className={cn('textArea')}
      id={id}
      onChange={e => onChange && onChange(e.target.value)}
      placeholder={label}
    />
  </div>
)

export default TextArea
