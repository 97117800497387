import React, { useState, FormEvent } from 'react'
import classNames from 'classnames/bind'

import Clickable from 'components/core/Clickable'
import { MonumentSubtitle } from 'components/ui/Text'
import Yes from 'assets/svg/yes.react.svg'
import No from 'assets/svg/no.react.svg'

import * as s from './FeedbackForm.module.scss'
import { IQuestion1 } from './FeedbackFormTypes'

const cn = classNames.bind(s)

const Question1 = ({ no, yes, onSubmit }: IQuestion1) => {
  const [answer, setAnswer] = useState<string>('')

  const handleSubmit = (e: FormEvent) => {
    onSubmit(e, answer)
    e.preventDefault()
  }

  return (
    <form className={cn('buttonsWide')} onSubmit={handleSubmit}>
      <Clickable className={cn('button')} type='submit' onClick={() => setAnswer(no)}>
        <No className={cn('icon')} />
        <MonumentSubtitle className={cn('buttonText')}>{no}</MonumentSubtitle>
      </Clickable>
      <Clickable className={cn('button')} type='submit' onClick={() => setAnswer(yes)}>
        <Yes className={cn('icon')} />
        <MonumentSubtitle className={cn('buttonText')}>{yes}</MonumentSubtitle>
      </Clickable>
    </form>
  )
}

export default Question1
