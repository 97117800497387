import React, { useState, useRef, useEffect, useCallback, memo } from 'react'
import classNames from 'classnames/bind'
import { useWindowSize } from '@react-hook/window-size'
import { motion, useMotionValue } from 'framer-motion'

import { scrollToX } from 'lib/scrollTo'
import Bullets from 'components/ui/Bullets'

import CardMobile from './CardMobile'
import { viewVariants } from './variants'
import { ViewMobileTypes, FactType } from './CardsLayoutTypes'
import * as s from './CardsLayout.module.scss'
const cn = classNames.bind(s)

const ViewMobile = ({ category, isSelected, lang, onSelectedReady, allowAllToMount, visible }: ViewMobileTypes) => {
  const [params, setParams] = useState<{ cardWidth: number; indent: number; total: number }>()
  const [leftConstraint, setLeftConstraint] = useState(0)
  const [shouldHide, setShouldHide] = useState(false)
  const view = useRef<HTMLDivElement>(null)
  const list = useRef<HTMLUListElement>(null)

  const [width, height] = useWindowSize()

  const scrollProgress = useMotionValue(0)
  const [activeCardIndex, setActiveCardIndex] = useState(0)

  const onAnimationStart = useCallback(() => {
    if (!view.current) return
    isSelected && setShouldHide(false)
  }, [isSelected])

  const onAnimationComplete = useCallback(() => {
    if (!view.current) return
    if (isSelected && !allowAllToMount) onSelectedReady()
    !isSelected && setShouldHide(true)
  }, [isSelected, allowAllToMount, onSelectedReady])

  // Sets view parameters - card width, total number of cards, card spacing/indent
  useEffect(() => {
    if (!list.current || !list.current.firstChild || !isSelected) return
    // @ts-ignore
    const cardWidth = list.current.firstChild.clientWidth || 0
    const total = category.facts.length
    const indent =
      parseInt(getComputedStyle(list.current).getPropertyValue('--cardIndent')) * 0.01 * Math.max(width, height)

    setParams({ cardWidth, total, indent })
  }, [width, height, isSelected, category])

  // Calculates left horizontal constraint based on params
  useEffect(() => {
    if (!isSelected || !params) return
    setLeftConstraint((params.cardWidth + params.indent) * (params.total - 1))
  }, [isSelected, params])

  // // Sets active index on bullet click
  const onBulletClick = (i: number) => setActiveCardIndex(i)

  // Animates X on active index change
  useEffect(() => {
    if (!list.current || !list.current.firstChild || !isSelected || !params) return
    scrollToX(list.current, activeCardIndex * (params.cardWidth + params.indent), 600)
  }, [activeCardIndex, isSelected, params])

  const updateBullets = useCallback(() => {
    // @ts-ignore
    scrollProgress.set(list.current.scrollLeft / leftConstraint)
  }, [scrollProgress, leftConstraint])

  // Updating active index from the mobile card
  const updateActiveIndex = useCallback(i => setActiveCardIndex(i), [])

  return (
    <motion.div
      ref={view}
      className={cn('viewMobile', { hide: shouldHide, visible })}
      variants={viewVariants}
      animate='animate'
      initial='initial'
      custom={isSelected}
      onAnimationStart={onAnimationStart}
      onAnimationComplete={onAnimationComplete}
    >
      <ul ref={list} className={cn('listMobile', { hide: shouldHide })} onScroll={updateBullets}>
        {category.facts.map((fact: FactType, i: number) => (
          <CardMobile
            key={fact.uid}
            uid={fact.uid}
            index={i}
            total={category.facts.length}
            category={category.title}
            theme={fact.theme}
            backgroundLayer={fact.hero.primary.background_layer}
            foregroundLayer={fact.hero.primary.foreground_layer}
            titleLayer={fact.hero.primary.title_layer || null}
            text={fact.hero.primary.text || {}}
            lang={lang}
            isInCurrentView={isSelected}
            updateScroll={updateActiveIndex}
          />
        ))}
      </ul>
      <div className={cn('bullets')}>
        <Bullets progress={scrollProgress} total={category.facts.length} onBulletClick={onBulletClick} />
      </div>
    </motion.div>
  )
}

export default memo(ViewMobile)
