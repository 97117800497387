import { useCallback, useEffect, useRef } from 'react'
import { useViewportScroll, useMotionValue } from 'framer-motion'
import sync from 'framesync'
import lerp from 'lerp'

/**
 * Shared parallax lerp on the scrollY value
 * Use this so all parallax components feel the same
 *
 * Uses framesync which is also used by framer-motion to prevent layout thrashing
 */
const useParallaxScroll = (lerpFactor = 0.2, precision = 0.01) => {
  const { scrollY } = useViewportScroll()
  const y = useMotionValue(scrollY.get())
  const target = useRef(y.get())
  const animating = useRef(false)

  const animate = useCallback(() => {
    // lerp motion value towards target
    y.set(lerp(y.get(), target.current, lerpFactor))

    // calculate delta and queue another frame if needed
    const delta = Math.abs(y.get() - target.current)
    if (delta > precision) {
      sync.update(animate)
    } else {
      animating.current = false
    }
  }, [y, lerpFactor, precision])

  // Handle scroll "events" from framer motion
  const onScroll = useCallback(
    val => {
      target.current = val
      // Start new frame if not already running
      if (!animating.current) {
        animating.current = true
        sync.update(animate)
      }
    },
    [animate],
  )

  // subscribe to scroll events
  useEffect(() => scrollY.onChange(onScroll), [onScroll, scrollY])
  return y
}

export default useParallaxScroll
