import React from 'react'
import classNames from 'classnames/bind'

import Tooltip from 'assets/svg/tooltip-white.react.svg'
import Interactable from 'components/core/Interactable'
import { CircularBody } from 'components/ui/Text'

import { TriggerProps } from './TriggerTypes'
import * as s from './Trigger.module.scss'
const cn = classNames.bind(s)

const Trigger = ({ title, onEnter, onLeave, onClick, children }: TriggerProps) => {
  return (
    <Interactable
      className={cn('container')}
      onClick={onClick}
      onEnter={onEnter}
      onLeave={onLeave}
      aria-haspopup='dialog'
    >
      <Tooltip className={cn('icon')} />
      <CircularBody className={cn('title')} weight='book'>
        {title}
      </CircularBody>
      {children && children}
    </Interactable>
  )
}

export default Trigger
