import React from 'react'
import classNames from 'classnames/bind'

import { CircularBody } from 'components/ui/Text'
import Clickable from 'components/core/Clickable'

import * as s from './../Buttons.module.scss'
import { PrimaryButtonProps } from './PrimaryButtonTypes'
const cn = classNames.bind(s)

const PrimaryButton = ({
  onClick,
  to,
  children,
  inverted,
  className,
  classNameLabel,
  type,
  formNoValidate,
}: PrimaryButtonProps) => {
  return (
    <Clickable
      onClick={onClick}
      to={to}
      className={cn('container', 'primary', { inverted }, className)}
      type={type}
      formNoValidate={formNoValidate}
    >
      <div className={cn('content')}>
        <CircularBody
          as='span'
          role='presentation'
          className={cn('text', 'narrow', 'label', 'primary', { inverted }, classNameLabel)}
        >
          {children}
        </CircularBody>
      </div>
    </Clickable>
  )
}

export default PrimaryButton
