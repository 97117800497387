const SCALES = {
  // Montument Extended
  monumentH1: 'monumentH1',
  monumentH2: 'monumentH2',
  monumentSubtitle: 'monumentSubtitle',
  monumentHashtag: 'monumentHashtag',
  monumentProgress: 'monumentProgress',
  monumentSteps: 'monumentSteps',
  monumentCaption: 'monumentCaption',
  monumentNavigation: 'monumentNavigation',
  monumentCard: 'monumentCard',
  // Circular Standard
  circularH1: 'circularH1',
  circularH2: 'circularH2',
  circularH3: 'circularH3',
  circularH4: 'circularH4',
  circularCaption: 'circularCaption',
  circularSteps: 'circularSteps',
  circularStory: 'circularStory',
  circularBody: 'circularBody',
  circularBodyLarge: 'circularBodyLarge',
}

export default SCALES
