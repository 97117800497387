import React, { useState, FormEvent } from 'react'
import classNames from 'classnames/bind'

import Spacer from 'components/core/Spacer'
import TextArea from 'components/ui/TextArea'
import { PrimaryButton, VoteButton } from 'components/ui/Buttons'

import * as s from './FeedbackForm.module.scss'
import { IQuestion2 } from './FeedbackFormTypes'

const cn = classNames.bind(s)

const Question2 = ({ sendLabel, skipLabel, textAreaLabel, onSubmit }: IQuestion2) => {
  const [value, setValue] = useState<string>('')

  const handleSubmit = (e: FormEvent) => {
    onSubmit(e, value)
    e.preventDefault()
  }

  return (
    <form className={cn('container')} onSubmit={handleSubmit}>
      <TextArea label={textAreaLabel} id='improvements' onChange={e => setValue(e)} />
      <Spacer initial='1.75rem' desktop='1.4vw' />
      <div className={cn('buttons')}>
        <PrimaryButton type='submit' onClick={() => setValue('')}>
          {skipLabel}
        </PrimaryButton>
        <VoteButton disabled={!value} type='submit'>
          {sendLabel}
        </VoteButton>
      </div>
    </form>
  )
}

export default Question2
