// extracted by mini-css-extract-plugin
export var colorBlack = "#000";
export var colorGray = "gray";
export var colorDarkGray = "#262626";
export var colorLightGray = "#e5e5e5";
export var colorWhite = "#fff";
export var colorElectricBlue = "#0000fe";
export var colorPastelBlue = "#bbe5fe";
export var colorCandyPink = "#f9709a";
export var colorPastelPink = "#ffcdd2";
export var colorDefaultLime = "#cef564";
export var colorPastelLime = "#eeffbe";
export var colorBlueLagoon = "#9cf0e1";
export var colorPastelLagoon = "#ceffec";
export var colorDefaultLavender = "#8738f6";
export var colorPastelLavender = "#eadcff";
export var colorBrightYellow = "#ffe500";
export var colorDefaultGreen = "#12b968";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var colCount = "6";
export var colWidth = "16.6666666667%";
export var cardsLayout = "CardsLayout-module--cardsLayout--3V9c1";
export var open = "CardsLayout-module--open--12V17";
export var cardsWrapper = "CardsLayout-module--cardsWrapper--1fLxD";
export var debug = "CardsLayout-module--debug--gR1ST";
export var cardsInner = "CardsLayout-module--cardsInner--3KGEA";
export var viewDesktop = "CardsLayout-module--viewDesktop--2yj6J";
export var isActive = "CardsLayout-module--isActive--1bnVU";
export var visible = "CardsLayout-module--visible--2izVz";
export var viewMobile = "CardsLayout-module--viewMobile--3O3PJ";
export var listMobile = "CardsLayout-module--listMobile--2FJOG";
export var hide = "CardsLayout-module--hide--1BnIO";
export var coverRight = "CardsLayout-module--coverRight--18EA1";
export var coverLeft = "CardsLayout-module--coverLeft--1lg0M";
export var horizontalCover = "CardsLayout-module--horizontalCover--2hMag";
export var bullets = "CardsLayout-module--bullets--1Gi5w";
export var hashtagDisplay = "CardsLayout-module--hashtagDisplay--2Cfnc";