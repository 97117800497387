import React, { useState, useCallback, useEffect, useRef } from 'react'
import classNames from 'classnames/bind'

import trapFocusInModal from 'lib/trapFocusInModal'
import useUIContext from 'context/ui'
import useCopyContext from 'context/copy'
import Container from 'components/core/Container'
import Clickable from 'components/core/Clickable'
import Spacer from 'components/core/Spacer'
import Burger from 'assets/svg/burger.react.svg'
import Cross from 'assets/svg/cross.react.svg'

import Marquee from './Marquee'
import Hashtags from './Hashtags'
import { HashtagCollectionProps } from './HashtagCollectionTypes'
import * as s from './HashtagCollection.module.scss'

const cn = classNames.bind(s)

const HashtagCollection = ({
  hashtags,
  selected,
  onClick,
  onHover,
  handleOpen,
  isActive,
  isFactPage,
}: HashtagCollectionProps) => {
  const [isOpen, setOpen] = useState(false)
  const modal = useRef<HTMLDivElement>(null)
  const container = useRef<HTMLDivElement>(null)
  const [containerWidth, setContainerWidth] = useState(0)
  const opener = useRef<HTMLDivElement>(null)
  const [openerWidth, setOpenerWidth] = useState(0)
  const visible = useUIContext(s => s.isCardsLayoutFullyVisible)
  const isInitialLoad = useUIContext(s => s.isInitialLoad)
  const [initiallyHide, setInitiallyHide] = useState(isInitialLoad ? !visible : true)
  const copy = useCopyContext(s => s.copy)

  const onHashtagClick = useCallback(
    (nextHashtag: string) => {
      onClick(nextHashtag)
      handleOpen && handleOpen(false)
      setOpen(false)
    },
    [setOpen, onClick, handleOpen],
  )

  useEffect(() => {
    if (!container.current || containerWidth) return
    setContainerWidth(container.current.getBoundingClientRect().width)
  }, [container, containerWidth])

  useEffect(() => {
    if (!opener.current || openerWidth) return
    setOpenerWidth(opener.current.getBoundingClientRect().width)
  }, [opener, openerWidth])

  useEffect(() => {
    if (isOpen && modal?.current) {
      modal.current.scrollTo(0, 0)
      modal.current.focus()
    }
  }, [modal, isOpen])

  return (
    <div
      className={cn('container', { isOpen, visible, initiallyHide })}
      ref={container}
      onAnimationEnd={() => setInitiallyHide(false)}
    >
      <div className={cn('opener')} ref={opener}>
        <Clickable
          onClick={() => {
            handleOpen && handleOpen(!isOpen) /* Inform parent on open state */
            setOpen(!isOpen)
          }}
          className={cn('clickable')}
          aria-label={isOpen ? `${copy.close} ${copy.menu}` : `${copy.open} ${copy.menu}`}
          aria-expanded={isOpen ? 'true' : 'false'}
          aria-controls='hashtags-nav'
          id='burger-button'
          {...(isFactPage && { tabIndex: -1 })}
        >
          {isOpen ? <Cross className={'icon'} aria-hidden='true' /> : <Burger className={'icon'} aria-hidden='true' />}
        </Clickable>
      </div>
      <Marquee
        hashtags={hashtags}
        selected={selected}
        onClick={onHashtagClick}
        onHover={onHover}
        isActive={!isOpen && isActive}
        containerWidth={containerWidth}
        openerWidth={openerWidth}
        isFactPage={isFactPage}
      />
      <div className={cn('modalWrapper')}>
        {/* non-interactable elements are not really allowed to have a key down listener, but we need to trap the focus within the nav */}
        {/* eslint-disable */}
        <nav
          className={cn('modal')}
          ref={modal}
          id='hashtags-nav'
          aria-labelledby='burger-button hidden-close-button'
          onKeyDown={e => trapFocusInModal(e, modal)}
        >
          <Container>
            <Spacer initial='2.375rem' medium='4vw' />
            <Hashtags
              hashtags={hashtags}
              selected={selected}
              onClick={onHashtagClick}
              variant='modal'
              onHover={onHover}
              isFactPage={isFactPage}
            />
            <Spacer initial='2.375rem' medium='3vw' />
          </Container>
          <button
            id='hidden-close-button'
            onClick={() => {
              handleOpen && handleOpen(false) /* Inform parent on open state */
              setOpen(false)
            }}
            className={cn('srOnly')}
            aria-controls='hashtags-nav'
          >{`${copy.close} ${copy.menu}`}</button>
        </nav>
      </div>
    </div>
  )
}

export default HashtagCollection
