import React, { useCallback, useRef, useEffect } from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames/bind'
import { useLocation } from '@reach/router'

import { MonumentHashtag } from 'components/ui/Text'
import InteractiveText from 'components/ui/InteractiveText'

import { HashtagProps } from './HashtagCollectionTypes'
import * as s from './HashtagCollection.module.scss'

const cn = classNames.bind(s)

const Hashtags = ({
  hashtags,
  selected,
  onClick,
  onHover,
  setSelectedDimensions,
  variant,
  isFactPage,
}: HashtagProps) => {
  const location = useLocation()
  const selectedRef = useRef<HTMLLIElement>(null)

  const onLinkClick = useCallback(
    (e, title) => {
      if (location.pathname !== '/') e.preventDefault() // prevent hashtag upadate in the URL if on the fact page
      onClick(title)
    },
    [onClick, location],
  )

  useEffect(() => {
    let timerId: NodeJS.Timer | undefined
    if (!selectedRef.current) {
      timerId = setTimeout(() => {
        if (selectedRef.current && setSelectedDimensions) {
          const { width, height } = selectedRef.current.getBoundingClientRect()
          setSelectedDimensions({ width, height })
        }
      }, 1)
    }

    return () => {
      if (timerId) clearTimeout(timerId)
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedRef])

  return (
    <ul className={cn('list')}>
      {hashtags.map(({ title }, index: number) => {
        return (
          <li
            key={`category-${index}`}
            className={cn('item', { selected: selected === title })}
            ref={selected === title ? selectedRef : undefined}
          >
            <Link
              to={title}
              onClick={e => onLinkClick(e, title)}
              onMouseOver={() => onHover(title)}
              {...((isFactPage || variant === 'marquee') && { tabIndex: -1 })}
            >
              <InteractiveText
                idle={selected === title}
                triggerScaleY={variant === 'marquee' ? 3 : 1}
                triggerScaleX={1.25}
              >
                <MonumentHashtag as='h3' scale={variant === 'marquee' ? 'monumentHashtag' : 'monumentH2'}>
                  {title}
                </MonumentHashtag>
              </InteractiveText>
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

export default Hashtags
