import React, { useRef, useEffect, useMemo, useState, SyntheticEvent } from 'react'
import { Link, navigate } from 'gatsby'
import classNames from 'classnames/bind'
import { useWindowSize } from '@react-hook/window-size'
import { motion, useMotionValue, useSpring } from 'framer-motion'
import { easeCubicOut } from 'd3-ease'
import { useLocation } from '@reach/router'

import { scrollToWindowY } from 'lib/scrollTo'
import readFromLocalStorage from 'lib/readFromLocalStorage'
import useUIContext from 'context/ui'
import getLocalizedPath from 'lib/getLocalizedPath'
import renderSpecialCharacters from 'lib/renderSpecialCharacters'

import CardContent from './CardContent'
import { cardDesktopElementVariants } from './variants'
import { CardDesktopTypes } from './CardsLayoutTypes'
import * as s from './CardDesktop.module.scss'
const cn = classNames.bind(s)

const CardDesktop = ({
  uid,
  index,
  total,
  indexY,
  category,
  theme,
  foregroundLayer,
  backgroundLayer,
  titleLayer,
  text,
  lang,
  mouseX,
  mouseY,
  isInCurrentView,
  allowPointer,
  wrapperOffset,
  wrapperWidth,
  onAnimationComplete,
}: CardDesktopTypes) => {
  const layout = useMemo(() => (titleLayer?.raw ? 'fullscreen' : 'vertical'), [titleLayer])
  const formattedTheme = useMemo(() => theme?.replace(/\s+/g, '-').toLowerCase(), [theme])
  const textContent = useMemo(() => {
    const str = titleLayer?.raw[0].text || text?.raw[0].text || ''
    return renderSpecialCharacters(str)
  }, [titleLayer, text])
  const path = useMemo(() => getLocalizedPath(uid, lang, category), [uid, category, lang])

  const card = useRef<HTMLLIElement>(null)
  const rect = useRef<DOMRect>()
  const readStatusUpdated = useUIContext(s => s.readStatusUpdated)
  const [width, height] = useWindowSize({ wait: 300 })

  const activeFactId = useUIContext(s => s.activeFactId)
  const isCardsLayoutActive = useUIContext(s => s.isCardsLayoutActive)
  const isCardsLayoutFullyVisible = useUIContext(s => s.isCardsLayoutFullyVisible)
  const cardsLayoutMotionY = useUIContext(s => s.cardsLayoutMotionY)
  const isHovered = useUIContext(s => s.isCardsLayoutHovered)
  const activeCardMotionY = useSpring(0, { damping: 18 })
  const onActiveCardYChange = useRef<() => void>()

  const setTheme = useUIContext(s => s.setTheme)
  const setFactPageTransitionStart = useUIContext(s => s.setFactPageTransitionStart)
  const factPageTransitionEnd = useUIContext(s => s.factPageTransitionEnd)
  const [to, setTo] = useState<string | null>(null)
  const { pathname } = useLocation()

  // yAxis animation
  const dY = useMotionValue(0)
  const y = useSpring(dY, {
    damping: 10 + index * 10,
    stiffness: 150,
  })
  // xAxis animation
  const dX = useMotionValue(0)
  const x = useSpring(dX, { damping: 20 + index * 5, stiffness: 120 + Math.random() * index * 10 })
  // Card indent/overlap (matching CSS)
  const indent = useRef(0)

  // Calculate card offsets
  useEffect(() => {
    if (!card.current) return
    rect.current = card.current.getBoundingClientRect()
  }, [card, height, width, isHovered])

  // Calculate card indent/overlap
  useEffect(() => {
    indent.current = Math.max(width, height) * 0.08
  }, [width, height])

  // Reset on idle state/hover off
  useEffect(() => {
    if (isHovered && allowPointer) return
    dX.set(0)
    dY.set(0)
  }, [isHovered, allowPointer, dX, dY])

  // Horizontal mouse-dependant movement
  useEffect(() => {
    if (!mouseX) return
    const onXChange = mouseX.onChange(v => {
      if (!rect.current || !v) return

      // Default x movement based on vertical mouse position
      const xLinear = (v - (wrapperWidth + wrapperOffset)) / wrapperWidth + 1
      const mouseDelta = xLinear * rect.current.width * 0.2

      // Additional x movement when in 'active' state
      let deltaX = 0
      // Max movement to the left (special treatment for last card)
      const leftBoundry = index === total - 1 ? -rect.current.width * 0.25 : -rect.current.width * 0.75
      // Max movement to the right (special treatment for first card)
      const rightBoundry = index === 0 ? 0 : rect.current.width * 0.2
      const threshold = v > rect.current.x + indent.current
      deltaX = threshold ? Math.max(rect.current.x + indent.current - v, leftBoundry) : Math.min(v, rightBoundry)

      dX.set(deltaX + mouseDelta)
    })

    return onXChange
  }, [mouseX, dX, height, width, rect, index, total, wrapperWidth, wrapperOffset])

  // Vertical mouse-dependant movement
  useEffect(() => {
    if (!mouseY || !mouseX) return
    const onYChange = mouseY.onChange(v => {
      if (!rect.current || !v) return

      // Default y movement based on vertical mouse position
      const yLinear = v / height - 0.5
      const mouseDelta = (yLinear * rect.current.x) / wrapperWidth

      // Additional y movement when in 'active' state
      let deltaY = 0
      if (
        mouseX.get() > rect.current.x + indent.current &&
        mouseX.get() < rect.current.x + rect.current.width - mouseDelta
      ) {
        deltaY =
          Math.min(Math.max((mouseY.get() - rect.current.y) / rect.current.height, 0), 1) * -0.5 * (0.25 + index * 0.05)
      } else {
        deltaY = 0
      }

      dY.set(deltaY * rect.current.height * (index === total - 1 ? 0.25 : 1) - mouseDelta * rect.current.height * 0.25)
    })

    return onYChange
  }, [mouseX, mouseY, dY, height, width, rect, index, wrapperWidth, wrapperOffset, total])

  // Vertical motion of active card on the bottom of the fact
  useEffect(() => {
    if (!isInCurrentView) return
    if (activeFactId == null || isCardsLayoutFullyVisible) {
      activeCardMotionY.set(0)
    }
    if (activeFactId !== uid) return
    !isCardsLayoutActive && onActiveCardYChange.current && onActiveCardYChange.current()
    onActiveCardYChange.current = cardsLayoutMotionY?.onChange(y => {
      activeCardMotionY.set(-y * height * 0.5)
    })
  }, [
    cardsLayoutMotionY,
    activeFactId,
    uid,
    height,
    isCardsLayoutActive,
    isCardsLayoutFullyVisible,
    activeCardMotionY,
    isInCurrentView,
  ])

  useEffect(() => {
    if (!card.current) return
    const readUids = readFromLocalStorage('readFacts')
    card.current.classList.toggle(cn('isRead'), readUids.includes(uid))
  }, [readStatusUpdated, uid])

  /* add visual transition before actually navigating */
  const handleClick = (e: SyntheticEvent, path: string) => {
    e.preventDefault()

    /* scroll to top if user clicks on current fact */
    const curr = pathname.replace(/\/$/, '')
    const next = path.substring(0, path.indexOf('#')).replace(/\/$/, '')
    if (curr === next) {
      scrollToWindowY(0, 1200)
      return
    }
    // @ts-ignore
    setTheme(formattedTheme)
    setFactPageTransitionStart(true)
    setTo(path)
  }

  /* navigate once transition is complete */
  useEffect(() => {
    if (factPageTransitionEnd && to) {
      navigate(to)
      setTo(null)
    }
  }, [factPageTransitionEnd, to])

  return (
    <motion.li
      ref={card}
      className={cn('card', layout)}
      data-theme={formattedTheme}
      style={{ '--indexY': indexY, y: activeCardMotionY } as React.CSSProperties}
    >
      <motion.div className={cn('cardWrapper')} style={{ y, x }}>
        <Link
          className={cn('link', 'skewer')}
          to={path}
          onClick={e => handleClick(e, path)}
          {...(textContent && { 'aria-label': textContent })}
        />
        <motion.div
          className={cn('cardInner')}
          initial={{
            opacity: activeFactId === uid || isCardsLayoutFullyVisible || !activeFactId ? 0 : 1,
          }}
          animate={{
            opacity: activeFactId === uid || isCardsLayoutFullyVisible || !activeFactId ? [0, 1] : 0,
            y: activeFactId === uid || isCardsLayoutFullyVisible || !activeFactId ? [50 + 10 * index, 0] : 0,
            transition: {
              opacity: {
                duration: isCardsLayoutActive ? 0.25 : 0,
                delay: isCardsLayoutActive ? index * 0.1 + 0.2 : 0,
              },
              y: {
                duration: isCardsLayoutActive ? 0.7 : 0,
                delay: isCardsLayoutActive ? index * 0.1 + 0.2 : 0,
                ease: easeCubicOut,
              },
            },
          }}
        >
          <motion.div
            className={cn('backface')}
            variants={cardDesktopElementVariants}
            initial='initial'
            animate={isInCurrentView ? 'animate' : 'exit'}
            custom={{
              index: index + 0.1,
              total,
            }}
          >
            <div className={cn('skewer')}>
              <div className={cn('white')} />
            </div>
          </motion.div>
          <motion.div
            onAnimationComplete={() => {
              if (total - 1 === index) onAnimationComplete()
            }}
            className={cn('frontface')}
            variants={cardDesktopElementVariants}
            initial='initial'
            animate={isInCurrentView ? 'animate' : 'exit'}
            custom={{
              index,
              total,
            }}
          >
            <div className={cn('skewer')}>
              <CardContent
                foregroundLayer={foregroundLayer}
                backgroundLayer={backgroundLayer}
                layout={layout}
                textContent={textContent || ''}
              />
              <div className={cn('checkmark')} />
            </div>
          </motion.div>
        </motion.div>
      </motion.div>
    </motion.li>
  )
}

export default CardDesktop
