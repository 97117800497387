import { useLayoutEffect, useState, useMemo } from 'react'
import { useWindowSize } from '@react-hook/window-size'

import * as s from './useIsDesktop.module.scss'
const mediumBreakpoint = parseInt(s.breakpointMedium)

const useIsDesktop = (externalBreakpoint?: number) => {
  const [width] = useWindowSize({ wait: 200 })
  const breakpoint = useMemo(() => externalBreakpoint || mediumBreakpoint, [externalBreakpoint])
  const [isDesktop, setIsDesktop] = useState<boolean>(false)
  useLayoutEffect(() => setIsDesktop(width >= (breakpoint || mediumBreakpoint)), [width, breakpoint])

  return isDesktop
}

export default useIsDesktop
