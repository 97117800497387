// extracted by mini-css-extract-plugin
export var colorBlack = "#000";
export var colorGray = "gray";
export var colorDarkGray = "#262626";
export var colorLightGray = "#e5e5e5";
export var colorWhite = "#fff";
export var colorElectricBlue = "#0000fe";
export var colorPastelBlue = "#bbe5fe";
export var colorCandyPink = "#f9709a";
export var colorPastelPink = "#ffcdd2";
export var colorDefaultLime = "#cef564";
export var colorPastelLime = "#eeffbe";
export var colorBlueLagoon = "#9cf0e1";
export var colorPastelLagoon = "#ceffec";
export var colorDefaultLavender = "#8738f6";
export var colorPastelLavender = "#eadcff";
export var colorBrightYellow = "#ffe500";
export var colorDefaultGreen = "#12b968";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var colCount = "6";
export var colWidth = "16.6666666667%";
export var content = "CardContent-module--content--1KJdm";
export var fullscreen = "CardContent-module--fullscreen--1Iq41";
export var isMobile = "CardContent-module--isMobile--2KQ88";
export var vertical = "CardContent-module--vertical--2hDFN";
export var info = "CardContent-module--info--2qL9g";
export var text = "CardContent-module--text--1ASg7";
export var layers = "CardContent-module--layers--3jfyx";
export var image = "CardContent-module--image--MBW6z";
export var layer = "CardContent-module--layer--2WRzF";
export var front = "CardContent-module--front--34Gay";