import React from 'react'
import classNames from 'classnames/bind'

import * as s from './Popup.module.scss'
import { PopupImitationProps } from './PopupTypes'

const cn = classNames.bind(s)

/**
 * This component shares styling with popup but is not interactive.
 * It's just posing as a popup.
 */
const PopupImitation = ({ children }: PopupImitationProps) => {
  return (
    <div className={cn('popup', 'imitation')}>
      <div className={cn('header', 'headerImitation')}>
        <div className={cn('lineContainer')}>
          <div className={cn('lines')} />
        </div>
      </div>
      <div className={cn('content')}>{children}</div>
    </div>
  )
}

export default PopupImitation
