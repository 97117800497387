import React, { useRef, useMemo, useEffect, useState, SyntheticEvent } from 'react'
import { Link, navigate } from 'gatsby'
import classNames from 'classnames/bind'
import { motion } from 'framer-motion'
import { useLocation } from '@reach/router'

import { scrollToWindowY } from 'lib/scrollTo'
import readFromLocalStorage from 'lib/readFromLocalStorage'
import renderSpecialCharacters from 'lib/renderSpecialCharacters'
import useUIContext from 'context/ui'
import getLocalizedPath from 'lib/getLocalizedPath'

import CardButton from './CardButton'
import CardContent from './CardContent'
import { cardMobileElementVariants } from './variants'
import { CardMobileTypes } from './CardsLayoutTypes'
import * as s from './CardMobile.module.scss'
const cn = classNames.bind(s)

const CardMobile = ({
  uid,
  index,
  total,
  category,
  theme,
  backgroundLayer,
  foregroundLayer,
  titleLayer,
  text,
  lang,
  isInCurrentView,
  updateScroll,
}: CardMobileTypes) => {
  const layout = useMemo(() => (titleLayer?.raw ? 'fullscreen' : 'vertical'), [titleLayer])
  const textContent = useMemo(() => {
    const str = titleLayer?.raw[0].text || text?.raw[0].text || ''
    return renderSpecialCharacters(str)
  }, [titleLayer, text])
  const path = useMemo(() => getLocalizedPath(uid, lang, category), [uid, category, lang])
  const readStatusUpdated = useUIContext(s => s.readStatusUpdated)

  const card = useRef<HTMLLIElement>(null)

  const activeFactId = useUIContext(s => s.activeFactId)
  const isCardsLayoutFullyVisible = useUIContext(s => s.isCardsLayoutFullyVisible)
  const isFactPageScrollable = useUIContext(s => s.isFactPageScrollable)

  const formattedTheme = useMemo(() => theme?.replace(/\s+/g, '-').toLowerCase(), [theme])
  const setTheme = useUIContext(s => s.setTheme)

  const setFactPageTransitionStart = useUIContext(s => s.setFactPageTransitionStart)
  const factPageTransitionEnd = useUIContext(s => s.factPageTransitionEnd)
  const [to, setTo] = useState<string | null>(null)
  const { pathname } = useLocation()

  useEffect(() => {
    if (activeFactId !== uid) return
    updateScroll(index)
    isFactPageScrollable && isCardsLayoutFullyVisible && index + 1 < total && updateScroll(index + 1)
    return () => updateScroll(index)
  }, [activeFactId, uid, index, total, updateScroll, isCardsLayoutFullyVisible, isFactPageScrollable])

  useEffect(() => {
    if (!card.current) return
    const readUids = readFromLocalStorage('readFacts')
    card.current.classList.toggle(cn('isRead'), readUids.includes(uid))
  }, [readStatusUpdated, uid])

  /* add visual transition before actually navigating */
  const handleClick = (e: SyntheticEvent, path: string) => {
    /* scroll to top if user clicks on current fact */
    const curr = pathname.replace(/\/$/, '')
    const next = path.substring(0, path.indexOf('#')).replace(/\/$/, '')
    if (curr === next) {
      scrollToWindowY(0, 1200)
      return
    }

    e.preventDefault()
    setFactPageTransitionStart(true)
    setTo(path)

    // @ts-ignore
    setTheme(formattedTheme)
  }

  /* navigate once transition is complete */
  useEffect(() => {
    if (factPageTransitionEnd && to) {
      navigate(to)
      setTo(null)
    }
  }, [factPageTransitionEnd, to, formattedTheme])

  return (
    <motion.li ref={card} className={cn('card', layout)} data-theme={formattedTheme}>
      <motion.div className={cn('cardWrapper')}>
        <Link className={cn('link')} to={path} onClick={e => handleClick(e, path)}>
          <div className={cn('cardInner')}>
            <motion.div
              className={cn('backface')}
              variants={cardMobileElementVariants}
              initial='initial'
              animate={isInCurrentView ? 'animate' : 'exit'}
              custom={{
                index,
                total,
              }}
            >
              <div className={cn('white')} />
            </motion.div>
            <motion.div
              className={cn('frontface')}
              variants={cardMobileElementVariants}
              initial='initial'
              animate={isInCurrentView ? 'animate' : 'exit'}
              custom={{
                index,
                total,
              }}
            >
              <CardContent
                foregroundLayer={foregroundLayer}
                backgroundLayer={backgroundLayer}
                layout={layout}
                textContent={textContent || ''}
                isMobile
              />
              <CardButton />
            </motion.div>
          </div>
        </Link>
      </motion.div>
    </motion.li>
  )
}

export default CardMobile
