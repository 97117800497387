// extracted by mini-css-extract-plugin
export var colorBlack = "#000";
export var colorGray = "gray";
export var colorDarkGray = "#262626";
export var colorLightGray = "#e5e5e5";
export var colorWhite = "#fff";
export var colorElectricBlue = "#0000fe";
export var colorPastelBlue = "#bbe5fe";
export var colorCandyPink = "#f9709a";
export var colorPastelPink = "#ffcdd2";
export var colorDefaultLime = "#cef564";
export var colorPastelLime = "#eeffbe";
export var colorBlueLagoon = "#9cf0e1";
export var colorPastelLagoon = "#ceffec";
export var colorDefaultLavender = "#8738f6";
export var colorPastelLavender = "#eadcff";
export var colorBrightYellow = "#ffe500";
export var colorDefaultGreen = "#12b968";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var colCount = "6";
export var colWidth = "16.6666666667%";
export var intro = "Intro-module--intro--ubVCJ";
export var srOnly = "Intro-module--srOnly--2XS4E";
export var main = "Intro-module--main--3dBD0";
export var section = "Intro-module--section--2wtXG";
export var center = "Intro-module--center--65yOI";
export var fullscreen = "Intro-module--fullscreen--y1XxE";
export var wrapper = "Intro-module--wrapper--yhF8-";
export var heroLetter = "Intro-module--heroLetter--UKENS";
export var heroInfo = "Intro-module--heroInfo--3gMtj";
export var mobile = "Intro-module--mobile--2zVFS";
export var mask = "Intro-module--mask--1-w_C";
export var bg = "Intro-module--bg--7V9VT";
export var section1 = "Intro-module--section1--2Z_Sf";
export var inner = "Intro-module--inner--Q5z6r";
export var siteTitle = "Intro-module--siteTitle--1yNhS";
export var credits = "Intro-module--credits--1VRgI";
export var scrollIndicator = "Intro-module--scrollIndicator--1iKEq";
export var middle = "Intro-module--middle--14HkX";
export var skip = "Intro-module--skip--2j4uE";
export var button = "Intro-module--button--lp-FS";
export var logo = "Intro-module--logo--38izS";
export var section2 = "Intro-module--section2--fUXxT";
export var section3 = "Intro-module--section3--2CXvf";
export var heroInfoContent = "Intro-module--heroInfoContent--1qoTH";
export var section4 = "Intro-module--section4--mGj5d";
export var text = "Intro-module--text--YkSLv";
export var top = "Intro-module--top--KVLJn";
export var clipped1 = "Intro-module--clipped1--eDYz4";
export var clipped2 = "Intro-module--clipped2--2bFmp";
export var clipped3 = "Intro-module--clipped3--10pSr";
export var clipped4 = "Intro-module--clipped4--3MxFl";
export var clipped5 = "Intro-module--clipped5--tNgsj";
export var clipped6 = "Intro-module--clipped6--QYQ77";
export var clipped7 = "Intro-module--clipped7--lRq9u";
export var section5 = "Intro-module--section5--2WMBx";
export var padSafari = "Intro-module--padSafari--2SJJ5";