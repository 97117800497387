import { useEffect, useState } from 'react'
import { onValue, ref } from 'firebase/database'

import { useDatabase, useUser } from 'lib/firebase'
import useFirebaseContext from 'context/firebase'
import removeFromLocalStorage from 'lib/removeFromLocalStorage'

import compareData from './compareData'

/**
 * Component that keeps track of changes in the Firebase database
 * and updates the FirebaseContext accordingly.
 */
const FirebaseListener = ({ lang }: { lang: string }) => {
  const database = useDatabase()
  const user = useUser()

  const setTotalVotes = useFirebaseContext(s => s.setTotalVotes)
  const totalVotes = useFirebaseContext(s => s.totalVotes)
  const [externalTotalVotes, setExternalTotalVotes] = useState(null)

  const setSpent = useFirebaseContext(s => s.setSpentVotes)
  const setEarned = useFirebaseContext(s => s.setEarnedVotes)
  const setUserReactions = useFirebaseContext(s => s.setUserReactions)
  const userReactionCount = useFirebaseContext(s => s.userReactionCount)
  const setUserReactionCount = useFirebaseContext(s => s.setUserReactionCount)

  const setTotalReactions = useFirebaseContext(s => s.setTotalReactions)
  const totalReactions = useFirebaseContext(s => s.totalReactions)
  const [externalTotalReactions, setExternalTotalReactions] = useState(null)

  /* TOTAL VOTES LISTENER */
  useEffect(() => {
    if (!user || !database) return

    onValue(ref(database, `solutions/${lang}`), snapshot => {
      const data = snapshot.val()
      setExternalTotalVotes(data)
    })
  }, [setExternalTotalVotes, database, user, lang])

  /* TOTAL REACTIONS TRACKER/UPDATER
   * Total votes are only updated if external data from Firebase is higher than
   * client's internal data. The comparison is simple, looping through all solutions
   * and if any count is higher in Firebase, set `update` to true and return a new object
   * with the highest count of each and every solution.
   */
  useEffect(() => {
    if (externalTotalVotes) {
      if (!totalVotes) {
        setTotalVotes(externalTotalVotes)
      } else {
        // @ts-ignore
        const { update, data } = compareData(totalVotes, externalTotalVotes)
        // @ts-ignore
        if (update && data) setTotalVotes(data)
      }
    }
  }, [externalTotalVotes, setExternalTotalVotes, totalVotes, setTotalVotes])

  /* TOTAL REACTIONS LISTENER */
  useEffect(() => {
    if (!user || !database) return
    onValue(ref(database, 'reactions'), snapshot => {
      const data = snapshot.val()
      setExternalTotalReactions(data)
    })
  }, [database, user, setUserReactionCount])

  /**
   * TOTAL REACTIONS TRACKER/UPDATER
   * Reactions are a bit more tricky to keep track of than total votes since
   * values are also decremented if user has reacted earlier. Due to this,
   * reactions are tracked by internally keeping track of the user's reaction count
   * (incremented by one each time the user press any of the reactions buttons).
   * When count is more than 0, any next event from Firebase is ignored. Count is then adjusted.
   *
   * In very rare occassions this could mean that an event triggered by another user is temporarily
   * ignored, but it should re-sync once the event triggered by current user is handled.
   */
  useEffect(() => {
    if (!totalReactions && externalTotalReactions) {
      setTotalReactions(externalTotalReactions)
      setExternalTotalReactions(null)
    } else if (externalTotalReactions) {
      if (userReactionCount) setUserReactionCount(-1)
      else setTotalReactions(externalTotalReactions)

      setExternalTotalReactions(null) /* Reset to avoid falling into this if statement next round */
    }
  }, [
    externalTotalReactions,
    setExternalTotalReactions,
    totalReactions,
    setTotalReactions,
    userReactionCount,
    setUserReactionCount,
  ])

  /* USER'S SPENT VOTES */
  useEffect(() => {
    if (!user || !database) return
    onValue(
      ref(database, `users/${user.uid}/spent`),
      snapshot => {
        const data = snapshot.val()
        setSpent(data)
      },
      {
        onlyOnce: true /* 1. User related and not affected by other events */,
      },
    )
  }, [setSpent, user, database])

  /* USER'S EARNED VOTES */
  useEffect(() => {
    if (!user || !database) return
    onValue(
      ref(database, `users/${user.uid}/earned`),
      snapshot => {
        const data = snapshot.val()
        setEarned(data)

        /*
         * User is empty, clear local storage just in case.
         * Discrepancy can occur if someone empties the database
         */
        if (!data) {
          removeFromLocalStorage('readFacts')
          removeFromLocalStorage('viewedVideos')
        }
      },
      {
        onlyOnce: true /* 1. */,
      },
    )
  }, [setEarned, user, database])

  /* USER'S REACTIONS */
  useEffect(() => {
    if (!database || !user) return
    onValue(
      ref(database, `users/${user.uid}/reactions`),
      snapshot => {
        const data = snapshot.val()
        setUserReactions(data)
      },
      {
        onlyOnce: true /* 1. */,
      },
    )
  }, [database, user, setUserReactions])

  return null
}

export default FirebaseListener
