export const defaultVariants = {
  animate: {
    y: '0px',
    opacity: 1,
    transition: {
      y: {
        type: 'spring',
        stiffness: 170,
      },
    },
  },
  exit: {
    y: '20px',
    opacity: 0,
    transition: {
      y: {
        type: 'spring',
        stiffness: 170,
      },
    },
  },
}
