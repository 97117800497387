import React, { useMemo } from 'react'
import classNames from 'classnames/bind'
import { MotionValue } from 'framer-motion'
import { easeCircleOut, easeCircleIn } from 'd3-ease'

import { MonumentHeading1, MonumentSubtitle } from 'components/ui/Text'
import ScrollParallax from 'components/core/ScrollParallax'

import * as s from './Intro.module.scss'
const cn = classNames.bind(s)

const Section2 = ({ scrollY }: { scrollY: MotionValue }) => {
  const keyframes = useMemo(
    () => ({
      heroInfo: { x: ['-100%', '0%'], scale: [0.5, 1] },
      first: { x: ['0%', '-6vw'] },
      second: { x: ['0%', '7vw'] },
    }),
    [],
  )

  return (
    <section className={cn('section', 'center', 'section2')}>
      <ScrollParallax
        absolute
        className={cn('fullscreen')}
        motionValue={scrollY}
        keyframes={{ opacity: [0, 1] }}
        times={{ opacity: [0, 0.5] }}
        startOffset='350vh'
        stopOffset='350vh'
        ease={easeCircleOut}
      >
        <div className={cn('bg', 'fullscreen')} />
      </ScrollParallax>
      <ScrollParallax
        absolute
        className={cn('fullscreen', 'center')}
        motionValue={scrollY}
        keyframes={{ x: ['0', '-100%'], skewX: [0, -8], opacity: [1, 0] }}
        times={{
          x: [0, 0.75],
          skewX: [0, 0.5],
          opacity: [0.2, 0.7],
        }}
        startOffset='555vh'
        stopOffset='555vh'
        ease={easeCircleIn}
      >
        <div className={cn('wrapper', 'center')} aria-hidden='true'>
          <ScrollParallax
            motionValue={scrollY}
            keyframes={keyframes.first}
            times={{ x: [0, 0.5] }}
            startOffset='390vh'
            stopOffset='490vh'
          >
            <div className={cn('left', 'center')}>
              <MonumentHeading1 as='span' className={cn('heroLetter')}>
                <ScrollParallax
                  motionValue={scrollY}
                  keyframes={{ y: ['40vh', '0vh'], opacity: [0, 1] }}
                  times={{ y: [0, 1], opacity: [0, 1] }}
                  startOffset='155vh'
                  stopOffset='155vh'
                >
                  J
                </ScrollParallax>
              </MonumentHeading1>
            </div>
          </ScrollParallax>
          <ScrollParallax
            motionValue={scrollY}
            keyframes={keyframes.second}
            times={{ x: [0, 0.5] }}
            startOffset='390vh'
            stopOffset='490vh'
          >
            <div className={cn('right', 'center')}>
              <MonumentHeading1 as='span' className={cn('heroLetter')}>
                <ScrollParallax
                  motionValue={scrollY}
                  keyframes={{ y: ['60vh', '0vh'], opacity: [0, 1] }}
                  times={{
                    y: [0, 1],
                    opacity: [0, 1],
                  }}
                  startOffset='155vh'
                  stopOffset='155vh'
                >
                  a
                </ScrollParallax>
              </MonumentHeading1>
              <MonumentHeading1 as='span' className={cn('heroLetter')}>
                <ScrollParallax
                  motionValue={scrollY}
                  keyframes={{ y: ['100vh', '0vh'], opacity: [0, 0.5, 1], rotateX: [-360, 360] }}
                  times={{ y: [0, 1], opacity: [0, 0.5, 1], rotateX: [0, 1] }}
                  startOffset='180vh'
                  stopOffset='180vh'
                >
                  !
                </ScrollParallax>
              </MonumentHeading1>
            </div>
          </ScrollParallax>
          <MonumentSubtitle as='div' className={cn('heroInfo', 'mask')}>
            <ScrollParallax
              motionValue={scrollY}
              keyframes={keyframes.heroInfo}
              times={{ x: [0, 1], scale: [0.25, 1] }}
              startOffset='360vh'
              stopOffset='360vh'
            >
              <div>
                <p>Vi lever </p>
                <p>längre,</p>
                <p>friskare</p>
                <p>och</p>
                <p>rikare liv </p>
              </div>
            </ScrollParallax>
          </MonumentSubtitle>
        </div>
        <p className={cn('srOnly')}>Ja! Vi lever längre, friskare och rikare liv.</p>
      </ScrollParallax>
    </section>
  )
}

export default Section2
