interface Data {
  [key: string]: number
}

const compareData = (internal: Data, external: Data) => {
  let update = false

  /* Only update internal state if external is higher */
  for (const key in external) {
    if ((external[key] || 0) > (internal[key] || 0)) {
      update = true
      break
    }
  }

  if (!update) {
    return { update: false, data: null }
  } else {
    const data: Data = {}
    for (const key in internal) {
      if ((internal[key] || 0) >= (external[key] || 0)) data[key] = internal[key] || 0
      else data[key] = external[key] || 0
    }

    return { update, data }
  }
}

export default compareData
