import create from 'zustand'
import { DataSnapshot } from 'firebase/database'

export type TotalVotes = DataSnapshot | null
export type SpentVotes = number | undefined
export type EarnedVotes = number | undefined
/* eslint-disable  @typescript-eslint/no-explicit-any */
export type TotalReactions = any
export type UserReactions = any
/* eslint-enable  @typescript-eslint/no-explicit-any */
export type userReactionsCount = number

export interface Store {
  totalVotes: TotalVotes
  setTotalVotes: (obj: TotalVotes) => void
  userReactions: UserReactions
  setUserReactions: (obj: UserReactions) => void
  userReactionCount: userReactionsCount
  setUserReactionCount: (number: userReactionsCount) => void
  totalReactions: TotalReactions
  setTotalReactions: (obj: UserReactions) => void
  spentVotes: SpentVotes
  setSpentVotes: (number: SpentVotes) => void
  earnedVotes: EarnedVotes
  setEarnedVotes: (number: EarnedVotes) => void
}

const useFirebaseContext = create<Store>((set, get) => ({
  totalVotes: null,
  setTotalVotes: totalVotes => set({ totalVotes }),
  userReactions: null,
  setUserReactions: userReactions => set({ userReactions }),
  userReactionCount: 0,
  setUserReactionCount: next => set({ userReactionCount: get().userReactionCount + next }),
  totalReactions: null,
  setTotalReactions: totalReactions => set({ totalReactions }),
  spentVotes: undefined,
  setSpentVotes: spentVotes => set({ spentVotes }),
  earnedVotes: undefined,
  setEarnedVotes: earnedVotes => set({ earnedVotes }),
}))

export default useFirebaseContext
