import { useEffect, useState } from 'react'
import { FirebaseApp } from 'firebase/app'

import getFirebase from './firebase'

export default function useFirebase() {
  const [instance, setInstance] = useState<null | FirebaseApp>(null)

  useEffect(() => {
    setInstance(getFirebase())
  }, [])

  return instance
}
