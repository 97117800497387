// extracted by mini-css-extract-plugin
export var colorBlack = "#000";
export var colorGray = "gray";
export var colorDarkGray = "#262626";
export var colorLightGray = "#e5e5e5";
export var colorWhite = "#fff";
export var colorElectricBlue = "#0000fe";
export var colorPastelBlue = "#bbe5fe";
export var colorCandyPink = "#f9709a";
export var colorPastelPink = "#ffcdd2";
export var colorDefaultLime = "#cef564";
export var colorPastelLime = "#eeffbe";
export var colorBlueLagoon = "#9cf0e1";
export var colorPastelLagoon = "#ceffec";
export var colorDefaultLavender = "#8738f6";
export var colorPastelLavender = "#eadcff";
export var colorBrightYellow = "#ffe500";
export var colorDefaultGreen = "#12b968";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var colCount = "6";
export var colWidth = "16.6666666667%";
export var container = "HashtagCollection-module--container--3cLfd";
export var initiallyHide = "HashtagCollection-module--initiallyHide--2Iz3H";
export var visible = "HashtagCollection-module--visible--1SjFr";
export var show = "HashtagCollection-module--show--3FcZ9";
export var isOpen = "HashtagCollection-module--isOpen--7Cgg8";
export var opener = "HashtagCollection-module--opener--3FUOC";
export var clickable = "HashtagCollection-module--clickable--bk2dg";
export var marquee = "HashtagCollection-module--marquee--3o7wu";
export var isDragging = "HashtagCollection-module--isDragging--2HPIH";
export var list = "HashtagCollection-module--list--1rcfi";
export var item = "HashtagCollection-module--item--1SjWc";
export var modal = "HashtagCollection-module--modal--3KqR-";
export var modalWrapper = "HashtagCollection-module--modalWrapper--278us";
export var selected = "HashtagCollection-module--selected--3Aycj";
export var display = "HashtagCollection-module--display--bljgi";
export var srOnly = "HashtagCollection-module--srOnly--2aHFt";