import create from 'zustand'
import FontFaceObserver from 'fontfaceobserver'
import { useEffect } from 'react'
import { MotionValue } from 'framer-motion'

import useCalculatedVH from 'lib/useCalculatedVH'
import useCalculatedVW from 'lib/useCalculatedVW'

export type ThemeValue =
  | 'dark'
  | 'lavender'
  | 'electric-blue'
  | 'blue-lagoon'
  | 'candy-pink'
  | 'lime'
  | 'black'
  | 'white'
  | undefined

interface Store {
  isFontAvailable: boolean
  setIsFontAvailable: (isFontAvailable: boolean) => void
  isInitialLoad: boolean
  setIsInitialLoad: (isInitialLoad: boolean) => void
  theme: ThemeValue
  themeSecondary: ThemeValue
  setTheme: (prev: ThemeValue) => void
  setThemeSecondary: (prev: ThemeValue) => void
  factPageTransitionStart: boolean
  setFactPageTransitionStart: (factPageTransitionStart: boolean) => void
  factPageTransitionEnd: boolean
  setFactPageTransitionEnd: (factPageTransitionStart: boolean) => void
  globalFactsData: { uid: string; categories: string[] }[]
  setGlobalFactsData: (globalFactsData: { uid: string; categories: string[] }[]) => void
  activeFactId: null | string
  setActiveFactId: (activeFactId: null | string) => void
  activeFactFirebaseId: null | string
  setActiveFactFirebaseId: (activeFactFirebaseId: null | string) => void
  isCardsLayoutActive: boolean
  setIsCardsLayoutActive: (isCardsLayoutActive: boolean) => void
  cardsLayoutMotionY: MotionValue | null
  setCardsLayoutMotionY: (cardsLayoutMotionY: MotionValue) => void
  isCardsLayoutFullyVisible: boolean
  setIsCardsLayoutFullyVisible: (isCardsLayoutFullyVisible: boolean) => void
  isFactPageScrollable: boolean
  setIsFactPageScrollable: (isFactPageScrollable: boolean) => void
  activeVideoProgress: number | null
  votesAnimation: { animate: boolean; votingPossible: boolean }
  triggerVotesAnimation: (obj: { animate?: boolean; votingPossible?: boolean }) => void
  readStatusUpdated: boolean
  setReadStatusUpdated: (readStatusUpdated: boolean) => void
  isCardsLayoutHovered: boolean
  setIsCardsLayoutHovered: (isCardsLayoutHovered: boolean) => void
  viewedVoteNotification: boolean
  setViewedVoteNotification: (viewedVoteNotification: boolean) => void
  preloadCategory: string | null
  setPreloadCategory: (prloadCategory: string) => void
  showFeedbackForm: boolean
  setShowFeedbackForm: (showFeedbackForm: boolean) => void
  showFeedbackCTA: undefined | boolean
  setShowFeedbackCTA: (showFeedbackCTA: boolean) => void
  feedbackCTAShown: boolean
  setFeedbackCTAShown: (feedbackCTAShown: boolean) => void
}

const useUIContext = create<Store>((set, get) => ({
  isInitialLoad: true,
  setIsInitialLoad: isInitialLoad => set({ isInitialLoad }),
  isFontAvailable: false,
  setIsFontAvailable: isFontAvailable => set({ isFontAvailable }),
  theme: undefined,
  themeSecondary: undefined,
  setTheme: theme => set({ theme }),
  setThemeSecondary: themeSecondary => set({ themeSecondary }),
  factPageTransitionStart: false,
  setFactPageTransitionStart: factPageTransitionStart => set({ factPageTransitionStart }),
  factPageTransitionEnd: false,
  setFactPageTransitionEnd: factPageTransitionEnd => set({ factPageTransitionEnd }),
  globalFactsData: [],
  setGlobalFactsData: globalFactsData => set({ globalFactsData }),
  activeFactId: null,
  setActiveFactId: activeFactId => set({ activeFactId }),
  activeFactFirebaseId: null,
  setActiveFactFirebaseId: activeFactFirebaseId => set({ activeFactFirebaseId }),
  isCardsLayoutActive: false,
  setIsCardsLayoutActive: isCardsLayoutActive => set({ isCardsLayoutActive }),
  cardsLayoutMotionY: null,
  setCardsLayoutMotionY: cardsLayoutMotionY => set({ cardsLayoutMotionY }),
  isCardsLayoutFullyVisible: false,
  setIsCardsLayoutFullyVisible: isCardsLayoutFullyVisible => set({ isCardsLayoutFullyVisible }),
  isFactPageScrollable: false,
  setIsFactPageScrollable: isFactPageScrollable => set({ isFactPageScrollable }),
  activeVideoProgress: null,
  votesAnimation: { animate: false, votingPossible: false },
  triggerVotesAnimation: next => set({ votesAnimation: { ...get().votesAnimation, ...next } }),
  readStatusUpdated: false,
  setReadStatusUpdated: readStatusUpdated => set({ readStatusUpdated }),
  isCardsLayoutHovered: false,
  setIsCardsLayoutHovered: isCardsLayoutHovered => set({ isCardsLayoutHovered }),
  viewedVoteNotification: false,
  setViewedVoteNotification: viewedVoteNotification => set({ viewedVoteNotification }),
  preloadCategory: null,
  setPreloadCategory: preloadCategory => set({ preloadCategory }),
  showFeedbackForm: false,
  setShowFeedbackForm: showFeedbackForm => set({ showFeedbackForm }),
  showFeedbackCTA: undefined,
  setShowFeedbackCTA: showFeedbackCTA => set({ showFeedbackCTA }),
  feedbackCTAShown: false,
  setFeedbackCTAShown: feedbackCTAShown => set({ feedbackCTAShown }),
}))

export default useUIContext

export const UISideEffects = () => {
  const setIsFontAvailable = useUIContext(s => s.setIsFontAvailable)
  const isInitialLoad = useUIContext(s => s.isInitialLoad)

  useEffect(() => {
    const font = new FontFaceObserver('MonumentExtended-Black')
    font.load(null, 5000).then(() => setIsFontAvailable(true))
  }, [setIsFontAvailable])

  // calculate --vh css var
  useCalculatedVH()
  // calculate --vw css var
  useCalculatedVW()

  useEffect(() => {
    !isInitialLoad && document.documentElement.classList.remove('isInitialLoad')
  }, [isInitialLoad])

  return null
}
