// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */

import React, { memo, useRef, useState, useEffect, useMemo, useCallback } from 'react'
import { motion, useViewportScroll, useTransform, useSpring, transform } from 'framer-motion'
import { useWindowSize } from '@react-hook/window-size'

/**
 * Parallax element while scrolling. Uses framer-motion springs for each property
 *
 * NOTE: `keyframes` and `times` are intentionally left out of hook dependecies since we have `useTransform` hook inside a loop.
 *        This breaks the rules of React but as long as the keyframes dont change per render it's ok.
 *
 */
const ScrollParallax = ({
  children,
  as = 'div',
  style,
  keyframes = {},
  ease,
  transition,
  horizontal = false,
  disabled = false,
  times = [0, 1],
  startOffset = 0,
  stopOffset = 0,
  debug = false,
  detectOverflow = true,
  clamp = true,
  speed,
  motionValue: externalMotionValue,
  absolute = false,
  onChange = null,
  useWillChange = true,
  flex = 1,
  ...props
}: ScrollParallaxProps) => {
  const [viewportWidth, viewportHeight] = useWindowSize({ initialHeight: 0, initialWidth: 0, wait: 200 })
  const { scrollY } = useViewportScroll()
  const motionValue = externalMotionValue || scrollY

  const ref = useRef()
  const calcEl = useRef()

  const springs = useRef({}).current
  const [bounds, setBounds] = useState([0, 1])

  // deep copy keyframes for initial shape
  const inputRange = useMemo(() => {
    return JSON.parse(JSON.stringify(keyframes))
  }, [])

  const progress = useTransform(motionValue, bounds, [0, 1], { clamp })
  const springProgress = useSpring(progress, transition) // FIX can't allow overflow on each value when using one spring

  // onChange listener
  useEffect(() => {
    if (!onChange || disabled) return
    const motionValue = transition ? springProgress : progress
    return motionValue.onChange(onChange)
  }, [onChange, disabled])

  // generate easings from shortcut value
  const easings = useMemo(() => {
    const easings = {}
    if (!ease) return easings
    Object.keys(keyframes).forEach(prop => {
      if (Array.isArray(ease[prop])) {
        easings[prop] = ease[prop]
      } else if (typeof ease === 'function' || typeof ease[prop] === 'function') {
        const value = ease[prop] || ease
        // apply to all frames
        easings[prop] = [...Array(keyframes[prop].length - 1)].map(() => value)
      }
    })
    return easings
  }, [ease])

  const outputRange = useMemo(() => {
    const outputRange = JSON.parse(JSON.stringify(keyframes)) // deep clone

    // speed shorthand
    if (speed) {
      if (horizontal) {
        outputRange.x = [
          transform(speed, [0.5, 1, 2], ['-50vw', '0vw', '50vw'], { clamp: false }),
          transform(speed, [0.5, 1, 2], ['50vw', '0vw', '-50vw'], { clamp: false }),
        ]
      } else {
        outputRange.y = [
          transform(speed, [0.5, 1, 2], ['-50vh', '0vh', '50vh'], { clamp: false }),
          transform(speed, [0.5, 1, 2], ['50vh', '0vh', '-50vh'], { clamp: false }),
        ]
      }
    }

    // Generate times/inputRange for all props in keyframe
    Object.keys(outputRange).forEach(prop => {
      // check if keyframe times provided, if not autogenerate with equal spacing
      if (!times[prop]) {
        if (Array.isArray(times) && times.length === outputRange[prop].length) {
          times[prop] = times
        } else {
          // auto gen inputRage for number of keyframes
          const firstProp = outputRange[prop]
          const l = firstProp.length
          times[prop] = firstProp.map((_, index) => (1 / (l - 1)) * index)
        }
      }

      // create input range for each time
      times[prop].forEach((_keyframe, i) => {
        if (!inputRange[prop]) inputRange[prop] = []
        inputRange[prop][i] = _keyframe
      })
    })

    return outputRange
  }, [inputRange, horizontal, speed])

  const transformer = prop => val => {
    const ease = easings[prop]
    return transform(val, inputRange[prop], outputRange[prop], { clamp, ease })
  }

  // create a motionvalue spring for each prop to keyframes
  Object.keys(outputRange).forEach(prop => {
    springs[prop] = useTransform(transition ? springProgress : progress, transformer(prop))
  })

  // evaluate css to pixels using DOM and getComputedStyle
  const toPixels = useCallback(
    val => {
      const axis = horizontal ? 'left' : 'top'
      calcEl.current.style[axis] = isNaN(val) ? val : `${val}px`
      const px = parseFloat(window.getComputedStyle(calcEl.current)[axis])
      return px
    },
    [horizontal],
  )

  // Calculate position and overflow
  useEffect(() => {
    const rect = ref.current.getBoundingClientRect()
    const scrollPos = horizontal ? motionValue.get() : window.pageYOffset

    let startOffsetPx = toPixels(startOffset, rect)
    let stopOffsetPx = toPixels(stopOffset, rect)

    if (detectOverflow) {
      if (!horizontal && outputRange?.y) {
        startOffsetPx = startOffsetPx || Math.min(0, toPixels(outputRange?.y[0], rect))
        stopOffsetPx = stopOffsetPx || Math.max(0, toPixels(outputRange?.y[outputRange.y.length - 1], rect))
      }
      if (horizontal && outputRange?.x) {
        startOffsetPx = startOffsetPx || toPixels(outputRange?.x[0], rect)
        stopOffsetPx = stopOffsetPx || toPixels(outputRange?.x[outputRange.x.length - 1], rect)
      }
    }

    // Y bounds
    const top = rect.top + startOffsetPx + scrollPos - viewportHeight
    const bottom = rect.top + scrollPos + rect.height + stopOffsetPx
    const left = rect.left + startOffsetPx + scrollPos - viewportWidth
    const right = rect.left + scrollPos + rect.width + stopOffsetPx
    setBounds(horizontal ? [left, right] : [top, bottom])
  }, [
    viewportWidth,
    viewportHeight,
    horizontal,
    motionValue,
    detectOverflow,
    outputRange.x,
    outputRange.y,
    startOffset,
    stopOffset,
    toPixels,
  ]) // fix snap on mobile

  const springStyles = disabled ? {} : springs

  const MotionElement = motion[as]
  // conditional will-change (if used with position:fixed for example)
  const willChange = useWillChange ? { willChange: 'transform' } : ''

  return (
    <div
      ref={ref}
      style={{ position: absolute ? ' absolute' : 'relative', outline: debug && '1px dashed orange', ...style }}
      {...props}
    >
      <MotionElement style={{ ...willChange, flex: flex, ...springStyles }}>{children}</MotionElement>

      {/* El used to evaluate css sizes to pixels */}
      <div
        ref={calcEl}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          pointerEvents: 'none',
          visibility: 'hidden',
          zIndex: -1,
        }}
      />
    </div>
  )
}

export default memo(ScrollParallax)
