export const feedbackButtonVariants = {
  animate: {
    y: '0px',
    opacity: 1,
    transition: {
      y: {
        type: 'spring',
        stiffness: 170,
        delay: 1,
      },
      delay: 1,
    },
  },
  initial: {
    y: '50px',
    opacity: 0,
    transition: {
      delay: 0.65,
      y: {
        type: 'spring',
        stiffness: 170,
        delay: 0.65,
      },
    },
  },
}
