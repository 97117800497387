import React, { useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { motion, AnimatePresence } from 'framer-motion'
import classNames from 'classnames/bind'

import Clickable from 'components/core/Clickable'
import { TooltipBox } from 'components/ui/Tooltip'
import { HeaderNotification } from 'components/ui/Header'
import useUIContext from 'context/ui'

import { variants } from './variants'
import { NavigationLinkProps } from './NavigationLinkTypes'
import * as s from './NavigationLink.module.scss'

const cn = classNames.bind(s)

const NavigationLink = ({ item, animate, availableVotes, to, onClick, tabIndex }: NavigationLinkProps) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false)
  const solutionsPage = item?.link?.type === 'solutions_page'
  const viewedVoteNotification = useUIContext(s => s.viewedVoteNotification)
  const setTheme = useUIContext(s => s.setTheme)

  return (
    <Clickable
      onClick={(e: React.MouseEvent) => onClick(e)}
      className={cn('link')}
      to={to}
      tabIndex={tabIndex}
      aria-label={item.label}
    >
      <motion.div
        className={cn('icon')}
        onHoverStart={() => {
          setTheme('dark')
          setShowTooltip(true)
        }}
        onHoverEnd={() => setShowTooltip(false)}
      >
        {item?.icon?.gatsbyImageData && (
          <GatsbyImage
            aria-hidden='true'
            className={cn('image')}
            objectFit='contain'
            image={item.icon.gatsbyImageData}
            alt={item.icon?.alt || ''}
          />
        )}
        <AnimatePresence>
          {solutionsPage && availableVotes !== 0 && (
            <HeaderNotification animate={animate} availableVotes={availableVotes} viewed={viewedVoteNotification} />
          )}
        </AnimatePresence>
      </motion.div>
      <AnimatePresence>
        {showTooltip && (
          <TooltipBox className={cn('tooltip')} variants={variants}>
            {item?.label || ''}
          </TooltipBox>
        )}
      </AnimatePresence>
    </Clickable>
  )
}

export default NavigationLink
