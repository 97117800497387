import React from 'react'
import { Elements } from 'prismic-richtext'

import { linkResolver } from 'prismic/linkResolver'
import { CircularHeading3, CircularHeading4, CircularBody, CircularCaption } from 'components/ui/Text'
import Highlight from 'components/ui/Highlight'
import Clickable from 'components/core/Clickable'
import Interactable from 'components/core/Interactable'

const htmlSerializer = (type, _element, content, children, key) => {
  switch (type) {
    case Elements.heading3:
      return (
        <CircularHeading3 key={key} as='p'>
          {children}
        </CircularHeading3>
      )

    case Elements.heading4:
      return (
        <CircularHeading4 key={key} as='p'>
          {children}
        </CircularHeading4>
      )

    case Elements.paragraph:
      return <CircularBody key={key}>{children}</CircularBody>

    case Elements.preformatted:
      return <span /> /* Hack to be able to do soft line breaks */

    case Elements.span:
      if (content) {
        return content.split('\\n').reduce((acc, p) => {
          if (acc.length === 0) {
            return [p]
          } else {
            const brIndex = (acc.length + 1) / 2 - 1
            return acc.concat([<br key={brIndex} />, p])
          }
        }, [])
      } else {
        return null
      }
    default:
      return null
  }
}

/* Used on pages such as 404 were the body should be of weight `book` */
export const htmlSerializerLight = () => {
  return (type, _element, content, children, key) => {
    switch (type) {
      case Elements.paragraph:
        return (
          <CircularBody key={key} weight='book'>
            {children}
          </CircularBody>
        )

      default:
        return htmlSerializer(type, _element, content, children, key)
    }
  }
}

export const interactiveHighlightSerializer = (onInteraction, highlightColor) => {
  return (type, _element, content, children, key) => {
    switch (type) {
      /*
       * `strong` is used into highlight word.
       */
      case Elements.strong:
        return (
          <Interactable
            onClick={() => onInteraction(true)}
            onEnter={() => onInteraction(true)}
            onLeave={() => onInteraction(false)}
            key={key}
            aria-haspopup='dialog'
          >
            <Highlight highlightColor={highlightColor}>{children}</Highlight>
          </Interactable>
        )

      default:
        return htmlSerializer(type, _element, content, children, key)
    }
  }
}

export const serializerSmall = className => {
  const renderer = (type, element, _content, children, key) => {
    switch (type) {
      case Elements.paragraph:
        return (
          <CircularCaption weight='book' key={key} as='p' className={className}>
            {children}
          </CircularCaption>
        )
      case Elements.strong:
        return (
          <CircularCaption weight='bold' key={key} as='span' className={className}>
            {children}
          </CircularCaption>
        )
      case Elements.hyperlink:
        return (
          <Clickable to={element.data.link_type === 'Web' ? element.data.url : linkResolver(element.data)} key={key}>
            {children}
          </Clickable>
        )
      default:
        return null
    }
  }
  return renderer
}

export default htmlSerializer
