import React from 'react'
import classNames from 'classnames/bind'
import { motion } from 'framer-motion'

import Clickable from 'components/core/Clickable'
import Spacer from 'components/core/Spacer'
import { CircularCaption } from 'components/ui/Text'
import Arrow from 'assets/svg/arrow.react.svg'

import * as s from './ScrollIndicator.module.scss'

const cn = classNames.bind(s)

const bounceTransition = {
  y: {
    duration: 0.9,
    repeat: Infinity,
    repeatType: 'reverse',
    ease: 'easeOut',
  },
}

const ScrollIndicator = ({
  children,
  to,
  onClick,
  hideLabelOnMobile = false,
  absolute = false,
  alignToTop,
  isFactPage,
}: {
  children: React.ReactNode
  to?: string
  onClick?: () => void
  hideLabelOnMobile?: boolean
  absolute?: boolean
  alignToTop?: boolean
  isFactPage?: boolean
}) => (
  <div className={cn('container', { absolute })}>
    <div>
      <Clickable
        className={cn('link', { isFactPage })}
        to={to}
        onClick={onClick}
        {...(alignToTop && { alignToTop: true })}
      >
        <CircularCaption as='p' weight='book' className={cn('label', { hideOnMobile: hideLabelOnMobile })}>
          {children}
        </CircularCaption>
        <Spacer initial='9.3vw' medium='1.8vw' hideOnMobile={hideLabelOnMobile || false} />
        <motion.div
          aria-hidden='true'
          className={cn('iconContainer')}
          transition={bounceTransition}
          animate={{
            y: ['0%', '-100%'],
          }}
        >
          <Arrow className={cn('icon')} />
        </motion.div>
      </Clickable>
      <Spacer hideOnMobile medium='2.4vw' />
    </div>
  </div>
)

export default ScrollIndicator
