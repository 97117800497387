import React, { useRef, useCallback, useEffect, useState } from 'react'
import classNames from 'classnames/bind'
import { useWindowSize } from '@react-hook/window-size'
import { useRafLoop } from 'react-use'
import { useSpring } from 'framer-motion'

import { normalizeWheel } from 'lib/normalizeWheel'
import { MonumentHashtag } from 'components/ui/Text'

import { MarqueeTypes, HashtagCollectionProps } from './HashtagCollectionTypes'
import Hashtags from './Hashtags'
import * as s from './HashtagCollection.module.scss'

const cn = classNames.bind(s)

const ACTIVESPEED = 2

const MarqueeItem = ({
  speed,
  hashtags,
  selected,
  onClick,
  onHover,
  isActive,
  containerWidth,
  openerWidth,
  isFactPage,
}: MarqueeTypes) => {
  const ref = useRef<HTMLDivElement>(null)
  const rect = useRef<DOMRect>()
  const x = useRef(0)
  const [width, height] = useWindowSize({ wait: 300 })
  const [sorted, setSorted] = useState<{ title: string }[] | null>(null)
  const [selectedWidth, setSelectedWidth] = useState<number | null>(null)

  /* Initially place selected hashtag first in the array */
  useEffect(() => {
    if (!sorted && hashtags && selected) {
      const index = hashtags.findIndex(h => h.title === selected)
      const part1 = hashtags.slice(0, index)
      const part2 = hashtags.slice(index)
      setSorted([...part2, ...part1])
    }
  }, [sorted, hashtags, selected])

  const setX = useCallback(() => {
    if (!ref.current || !rect.current) return
    const xPercentage = (x.current / rect.current.width) * 100
    if (xPercentage < -100) x.current = 0
    if (xPercentage > 0) x.current = -rect.current.width
    ref.current.style.transform = `translate3d(${xPercentage}%, 0, 0)`
  }, [])

  /* Set initial position */
  useEffect(() => {
    /* 1. setTimeout is used to make sure width is calculated properly (bug found in development).
     * Old but related bug: https://github.com/facebook/react/issues/13108#issuecomment-491111789
     */
    setTimeout(() => {
      if (!ref || !rect.current || !containerWidth || !openerWidth || !selectedWidth || !sorted) return
      x.current -= rect.current.width - (containerWidth || 0) / 2 + selectedWidth / 2 + (openerWidth || 0)
      setX()
    }, 0)
  }, [containerWidth, openerWidth, selectedWidth, sorted, setX])

  const buffer = useRef(0)
  const loop = useCallback(
    e => {
      const delta = (e - buffer.current) / 1000
      const c = Math.max(1 / 60 / delta, 1)
      buffer.current = e

      x.current -= speed.get() / c
      setX()
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [setX],
  )

  const [loopStop, loopStart] = useRafLoop(loop, false)

  useEffect(() => {
    isActive && width >= parseInt(s.breakpointDesktop) ? loopStart() : loopStop()
  }, [isActive, loopStart, loopStop, width])

  useEffect(() => {
    rect.current = ref?.current?.getBoundingClientRect()
  }, [width, height])

  return (
    <div ref={ref}>
      <Hashtags
        hashtags={sorted || hashtags}
        selected={selected}
        onClick={onClick}
        onHover={onHover}
        variant='marquee'
        isFactPage={isFactPage}
        setSelectedDimensions={({ width }) => {
          if (!selectedWidth) setSelectedWidth(width)
        }}
      />
    </div>
  )
}

const Marquee = (props: JSX.IntrinsicAttributes & HashtagCollectionProps) => {
  const marquee = useRef<HTMLDivElement>(null)
  const speed = useSpring(0, {
    damping: 40,
    stiffness: 100,
    mass: 4,
  })

  const slowDown = useRef(false)
  const y = useRef(0)
  const touchX = useRef({ start: 0, end: 0, move: 0 })

  const onWheel = useCallback(
    e => {
      const { pixelY } = normalizeWheel(e)
      y.current = pixelY * 2
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [speed],
  )

  const onTouchStart = useCallback(e => {
    speed.set(0)
    touchX.current.start = e.changedTouches[0].pageY
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  const onTouchMove = useCallback(e => {
    if (touchX.current.start === 0) return
    touchX.current.move = e.changedTouches[0].pageY
    y.current = -2 * (touchX.current.move - touchX.current.start)
    touchX.current.start = touchX.current.move
  }, [])

  const onMouseDown = (e: React.MouseEvent) => {
    speed.set(0)
    slowDown.current = true
    touchX.current.start = e.clientX
    marquee?.current?.classList.add(cn('isDragging'))
  }

  const onMouseMove = (e: React.MouseEvent) => {
    if (slowDown.current) {
      touchX.current.move = e.clientX
      speed.set(2 * (-touchX.current.move + touchX.current.start))
      touchX.current.start = touchX.current.move
    }
  }

  const reset = () => {
    slowDown.current = false
    y.current = ACTIVESPEED
    marquee?.current?.classList.remove(cn('isDragging'))
  }

  const loop = () => {
    if (slowDown.current) return
    if (Math.abs(y.current) < 0.01) return
    y.current *= 0.7
    if (y.current < 0) {
      y.current = Math.min(y.current, 0)
    } else {
      y.current = Math.max(y.current, 0)
    }
    speed.set(y.current)
  }

  useRafLoop(loop, true)

  return (
    <>
      <div
        className={cn('marquee')}
        ref={marquee}
        onWheel={onWheel}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onMouseMove={onMouseMove}
        onMouseDown={onMouseDown}
        onMouseUp={reset}
        onMouseLeave={reset}
        aria-hidden='true'
      >
        <MarqueeItem speed={speed} {...props} />
        <MarqueeItem speed={speed} {...props} />
      </div>
      {/* used for mobile */}
      <div className={cn('display')}>
        <MonumentHashtag as='h3'>{props.selected}</MonumentHashtag>
      </div>
    </>
  )
}

export default Marquee
