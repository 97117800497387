// extracted by mini-css-extract-plugin
export var colorBlack = "#000";
export var colorGray = "gray";
export var colorDarkGray = "#262626";
export var colorLightGray = "#e5e5e5";
export var colorWhite = "#fff";
export var colorElectricBlue = "#0000fe";
export var colorPastelBlue = "#bbe5fe";
export var colorCandyPink = "#f9709a";
export var colorPastelPink = "#ffcdd2";
export var colorDefaultLime = "#cef564";
export var colorPastelLime = "#eeffbe";
export var colorBlueLagoon = "#9cf0e1";
export var colorPastelLagoon = "#ceffec";
export var colorDefaultLavender = "#8738f6";
export var colorPastelLavender = "#eadcff";
export var colorBrightYellow = "#ffe500";
export var colorDefaultGreen = "#12b968";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var colCount = "6";
export var colWidth = "16.6666666667%";
export var headerMain = "Header-module--headerMain--1uiv_";
export var initiallyHide = "Header-module--initiallyHide--qDhpa";
export var visible = "Header-module--visible--3bUnj";
export var show = "Header-module--show--1pH5c";
export var header = "Header-module--header--1_ENv";
export var fixed = "Header-module--fixed--3ECj4";
export var headerFact = "Header-module--headerFact--1doqc";
export var content = "Header-module--content--3bLnj";
export var icons = "Header-module--icons--2wIOU";
export var indicator = "Header-module--indicator--2BF7t";
export var logo = "Header-module--logo--2ox-l";
export var exit = "Header-module--exit--squV-";
export var category = "Header-module--category--3bggh";