import React, { useEffect, useState } from 'react'
import TagManager from 'react-gtm-module'
import classNames from 'classnames/bind'
import { graphql, useStaticQuery } from 'gatsby'
import { parseCookies, setCookie } from 'nookies'
import { RichText } from 'prismic-reactjs'
import { useLocation } from '@reach/router'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'

import Popup from 'components/ui/Popup'
import { serializerSmall } from 'prismic/htmlSerializer'
import CookieIcon from 'assets/svg/cookie.react.svg'
import getLocalizedData from 'lib/getLocalizedData'

import { CookieBarConsentType } from './CookieBarTypes'
import * as s from './CookieBar.module.scss'

const cn = classNames.bind(s)

const cookieBarQuery = graphql`
  query cookieBarQuery {
    allPrismicSiteSettings {
      edges {
        node {
          _previewable
          lang
          data {
            cookie_bar_text {
              raw
              text
            }
          }
        }
      }
    }
  }
`

const CookieBar = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false)

  const staticData = useStaticQuery(cookieBarQuery)
  const { data } = useMergePrismicPreviewData(staticData)
  const { pathname } = useLocation()

  const prismicSiteSettings = getLocalizedData(pathname, data.allPrismicSiteSettings)

  const cookies = parseCookies()

  useEffect(() => {
    if (!cookies.UN_COOKIES_CONSENT) {
      setIsVisible(true)
    } else if (
      cookies.UN_COOKIES_CONSENT === 'ACCEPTED' &&
      process.env.NODE_ENV === 'production' &&
      process.env.GATSBY_GTM_ID
    ) {
      TagManager.initialize({
        gtmId: process.env.GATSBY_GTM_ID,
      })
    }
  }, [cookies.UN_COOKIES_CONSENT])

  const handleAcceptCookies = (status: CookieBarConsentType) => {
    setCookie(null, 'UN_COOKIES_CONSENT', status)
    setIsVisible(false)
  }

  return (
    <Popup
      open={isVisible}
      containerClassName={cn('cookieBar', 'popupContainer')}
      className={cn('popup')}
      onClose={() => handleAcceptCookies('ACCEPTED')}
      translateX='0'
      isAbsoluteOnMobile
      ariaLabel={prismicSiteSettings?.data?.cookie_bar_text?.text}
    >
      <CookieIcon className={cn('icon')} />
      <RichText render={prismicSiteSettings?.data?.cookie_bar_text?.raw} htmlSerializer={serializerSmall()} />
    </Popup>
  )
}

export default CookieBar
