import React from 'react'
import classNames from 'classnames/bind'

import useCopyContext from 'context/copy'
import { CircularBody } from 'components/ui/Text'

import * as s from './CardMobile.module.scss'
const cn = classNames.bind(s)

const CardButton = () => {
  const copy = useCopyContext(s => s.copy)

  return (
    <button className={cn('button')}>
      <p className={cn('label')}>
        <CircularBody as='span'>{copy.mobile_cards_label}</CircularBody>
      </p>
    </button>
  )
}

export default CardButton
