import React from 'react'
import classNames from 'classnames/bind'
import { MotionValue } from 'framer-motion'

import { MonumentHeading2 } from 'components/ui/Text'
import ScrollParallax from 'components/core/ScrollParallax'

import * as s from './Intro.module.scss'
const cn = classNames.bind(s)

const Section4Mobile = ({ scrollY, toggle }: { scrollY: MotionValue; toggle: (exit: boolean) => void }) => (
  <section className={cn('section', 'center', 'section4')}>
    <ScrollParallax
      absolute
      className={cn('fullscreen')}
      motionValue={scrollY}
      keyframes={{ opacity: [0, 1] }}
      times={{
        opacity: [0, 0.5],
      }}
      startOffset='400vh'
      stopOffset='400vh'
    >
      <div className={cn('bg', 'fullscreen')} />
    </ScrollParallax>
    <ScrollParallax
      absolute
      className={cn('fullscreen', 'center')}
      motionValue={scrollY}
      keyframes={{ y: ['0vh', '-20vh'] }}
      times={{ y: [0, 1] }}
      startOffset='520vh'
      stopOffset='520vh'
      onChange={(progress: number) => {
        if (progress > 0.5) toggle(true)
        else if (progress < 0.5) toggle(false)
      }}
    >
      <div className={cn('wrapper', 'center')} aria-hidden='true'>
        <ScrollParallax
          motionValue={scrollY}
          keyframes={{ opacity: [0, 1], scale: [0.7, 1] }}
          times={{
            opacity: [0, 0.3],
            scale: [0, 0.4],
          }}
          startOffset='410vh'
          stopOffset='410vh'
        >
          <div className={cn('top', 'padSafari')}>
            <MonumentHeading2 className={cn('text')}>Förstå världen</MonumentHeading2>
            <div className={cn('clipped1')} role='presentation'>
              <ScrollParallax
                motionValue={scrollY}
                keyframes={{ opacity: [0, 1] }}
                times={{ opacity: [0, 0.5] }}
                startOffset='475vh'
                stopOffset='475vh'
              >
                <MonumentHeading2 className={cn('text')}>Förstå världen</MonumentHeading2>
              </ScrollParallax>
            </div>
            <div className={cn('clipped2')} role='presentation'>
              <ScrollParallax
                motionValue={scrollY}
                keyframes={{ opacity: [0, 1] }}
                times={{
                  opacity: [0, 0.5],
                }}
                startOffset='480vh'
                stopOffset='480vh'
              >
                <MonumentHeading2 className={cn('text')}>Förstå världen</MonumentHeading2>
              </ScrollParallax>
            </div>
            <div className={cn('clipped3')} role='presentation'>
              <ScrollParallax
                motionValue={scrollY}
                keyframes={{ opacity: [0, 1] }}
                times={{
                  opacity: [0, 0.5],
                }}
                startOffset='485vh'
                stopOffset='485vh'
              >
                <MonumentHeading2 className={cn('text')}>Förstå världen</MonumentHeading2>
              </ScrollParallax>
            </div>
            <div className={cn('clipped4')} role='presentation'>
              <ScrollParallax
                motionValue={scrollY}
                keyframes={{ opacity: [0, 1] }}
                times={{
                  opacity: [0, 0.5],
                }}
                startOffset='490vh'
                stopOffset='490vh'
              >
                <MonumentHeading2 className={cn('text')}>Förstå världen</MonumentHeading2>
              </ScrollParallax>
            </div>
            <div className={cn('clipped5')} role='presentation'>
              <ScrollParallax
                motionValue={scrollY}
                keyframes={{ opacity: [0, 1] }}
                times={{
                  opacity: [0, 0.5],
                }}
                startOffset='495vh'
                stopOffset='495vh'
              >
                <MonumentHeading2 className={cn('text')}>Förstå världen</MonumentHeading2>
              </ScrollParallax>
            </div>
            <div className={cn('clipped6')} role='presentation'>
              <ScrollParallax
                motionValue={scrollY}
                keyframes={{ opacity: [0, 1] }}
                times={{
                  opacity: [0, 0.5],
                }}
                startOffset='500vh'
                stopOffset='500vh'
              >
                <MonumentHeading2 className={cn('text')}>Förstå världen</MonumentHeading2>
              </ScrollParallax>
            </div>
            <div className={cn('clipped7')} role='presentation'>
              <ScrollParallax
                motionValue={scrollY}
                keyframes={{ opacity: [0, 1] }}
                times={{
                  opacity: [0, 0.5],
                }}
                startOffset='505vh'
                stopOffset='505vh'
              >
                <MonumentHeading2 className={cn('text')}>Förstå världen</MonumentHeading2>
              </ScrollParallax>
            </div>
          </div>
        </ScrollParallax>
        <ScrollParallax
          motionValue={scrollY}
          keyframes={{ opacity: [1, 0.25, 0], y: ['0%', '100%'], scale: [1, 0.8] }}
          times={{
            opacity: [0, 0.3, 0.45],
            scale: [0, 0.75],
            y: [0, 0.5],
          }}
          startOffset='470vh'
          stopOffset='470vh'
        >
          <div>
            <ScrollParallax
              motionValue={scrollY}
              keyframes={{ opacity: [0, 1], scale: [0.7, 1] }}
              times={{
                opacity: [0, 0.3],
                scale: [0, 0.4],
              }}
              startOffset='415vh'
              stopOffset='410vh'
            >
              <MonumentHeading2 className={cn('text')}>bättre och var med och forma</MonumentHeading2>
            </ScrollParallax>
            <ScrollParallax
              motionValue={scrollY}
              keyframes={{ opacity: [0, 1], scale: [0.7, 1] }}
              times={{
                opacity: [0, 0.3],
                scale: [0, 0.4],
              }}
              startOffset='420vh'
              stopOffset='410vh'
            >
              <MonumentHeading2 className={cn('text')}>framtiden.</MonumentHeading2>
            </ScrollParallax>
          </div>
        </ScrollParallax>
      </div>
    </ScrollParallax>
    <p className={cn('srOnly')}>Förstå världen bättre och var med och forma framtiden.</p>
  </section>
)

export default Section4Mobile
