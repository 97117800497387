import { initializeApp, FirebaseApp } from 'firebase/app'

const config = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_FIREBASE_APP_ID,
  measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
}

/* Use staging database while testing, and don't use the measurement ID */
const stagingConfig = {
  databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL_STAGING,
  measurementId: undefined,
}

let instance: FirebaseApp | null = null

export default function getFirebase() {
  if (typeof window !== 'undefined') {
    const settings = { ...config, ...(process.env.GATSBY_FIREBASE_USE_STAGING_DB && { ...stagingConfig }) }

    if (instance) return instance
    instance = initializeApp(settings)
    return instance
  }

  return null
}
