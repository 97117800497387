import React from 'react'
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'
import 'gatsby-plugin-prismic-previews/dist/styles.css'
import smoothscroll from 'smoothscroll-polyfill'

import useUIContext, { UISideEffects } from 'context/ui'
import Header from 'components/ui/Header'
import FeedbackForm from 'components/ui/FeedbackForm'
import Footer from 'components/ui/Footer'
import ThemeMode from 'components/ui/ThemeMode'
import CardsLayout from 'components/ui/CardsLayout'
import FirebaseListener from 'components/core/FirebaseListener'
import CookieBar from 'components/ui/CookieBar'
import BackgroundCover from 'components/ui/BackgroundCover'
import { SpinningButtonFeedback } from 'components/ui/SpinningButton'
import Intro from 'components/ui/Intro'

import { defaultLanguage } from './prismic-configuration'

import 'wipe.css'
import 'styles/styles.scss'

const queryString = require('query-string')

export const onRouteUpdate = ({ location, prevLocation }) => {
  // set isInitialLoad to false after first page transition
  prevLocation !== null &&
    prevLocation.pathname !== location.pathname &&
    useUIContext.setState({ isInitialLoad: false })
}

export const wrapRootElement = ({ element }) => {
  return (
    <PrismicPreviewProvider>
      <ThemeMode />
      <UISideEffects />
      {element}
    </PrismicPreviewProvider>
  )
}

export const wrapPageElement = ({ element, props }) => {
  const factPage = props.pageContext.type === 'fact'
  const homePage = props.pageContext.type === 'homepage'
  const hasCards = ['fact', 'homepage'].some(p => p === props.pageContext.type)
  const fixedHeader = ['fact', 'homepage', 'videos_page'].some(p => p === props.pageContext.type)
  const hasFooter = props.pageContext.type === 'about_page' || props.pageContext.type === 'privacy_page'
  const footerData = props.data?.prismicSiteSettings?.data
  const hasFeedbackForm = props.pageContext.type !== 'videos_page'
  const lang = props.data?.prismicSiteSettings?.lang || defaultLanguage

  smoothscroll.polyfill()

  let category
  if (factPage) {
    category = props.data.prismicHomepage?.data.body.find(cat => {
      return cat.items?.find(item => {
        return item?.category_fact?.uid === props.data.prismicFact.uid
      })
    })
  }

  return (
    <>
      <CookieBar />
      {homePage && <Intro />}
      <Header fixed={fixedHeader} pageType={props.pageContext.type} />
      <BackgroundCover />
      {hasCards && (
        <CardsLayout preselectedCategory={factPage ? category?.primary.category_title : null} isFactPage={factPage} />
      )}
      {element}
      {hasFooter && footerData && <Footer data={footerData} />}
      <FirebaseListener lang={lang} />
      {hasFeedbackForm && <SpinningButtonFeedback hideOnScroll={props.pageContext.type !== 'fact'} />}
      <FeedbackForm lang={lang} />
    </>
  )
}

const qs = queryString.parse(window.location.search)

if (typeof qs.fps !== 'undefined') {
  const script = document.createElement('script')
  script.onload = function () {
    // eslint-disable-next-line no-undef
    const stats = new Stats()
    document.body.appendChild(stats.dom)
    window.requestAnimationFrame(function loop() {
      stats.update()
      window.requestAnimationFrame(loop)
    })
  }
  script.src = '//mrdoob.github.io/stats.js/build/stats.min.js'
  document.head.appendChild(script)
}

if (typeof qs.clearls !== 'undefined') {
  window.localStorage.clear()
}
