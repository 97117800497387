import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { navigate, graphql, useStaticQuery } from 'gatsby'
import { motion } from 'framer-motion'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'
import classNames from 'classnames/bind'
import { useLocation } from '@reach/router'

import useFirebaseContext from 'context/firebase'
import useUIContext from 'context/ui'
import useCopyContext from 'context/copy'
import getLocalizedPath from 'lib/getLocalizedPath'
import { MonumentNavigation } from 'components/ui/Text'
import Container from 'components/core/Container'
import Clickable from 'components/core/Clickable'
import getLocalizedData from 'lib/getLocalizedData'

import { defaultLanguage } from '../../../../prismic-configuration'
import * as s from './Header.module.scss'
import { DataType } from './HeaderTypes'
import NavigationLink from './NavigationLink'
import VotesPopup from './VotesPopup'

const cn = classNames.bind(s)

const headerQuery = graphql`
  query headerQuery {
    allPrismicSiteSettings {
      edges {
        node {
          lang
          data {
            available_votes
            navigation_title
            navigation_popup_button_label
            navigation_popup_text {
              raw
            }
            navigation_links {
              label
              icon {
                gatsbyImageData
              }
              link {
                uid
                type
              }
            }
          }
        }
      }
    }
  }
`

const Header = ({ fixed = false, pageType = '' }) => {
  const staticData = useStaticQuery(headerQuery)
  const { data: externalData }: DataType = useMergePrismicPreviewData(staticData)
  const isCardsLayoutFullyVisible = useUIContext(s => s.isCardsLayoutFullyVisible)
  const isInitialLoad = useUIContext(s => s.isInitialLoad)
  const hasCards = ['fact', 'homepage'].some(p => p === pageType)
  const [initiallyHide, setInitiallyHide] = useState(pageType === 'homepage' && isInitialLoad)
  const factPage = pageType === 'fact'
  const setCopy = useCopyContext(s => s.setCopy)

  const { pathname } = useLocation()

  const spent = useFirebaseContext(s => s.spentVotes)
  const earned = useFirebaseContext(s => s.earnedVotes)

  const [animate, setAnimate] = useState<boolean | undefined | number>(earned)
  /* Internal state to make sure that `+1` is only animated when its supposed to */
  const [hasAnimated, setHasAnimated] = useState<boolean | undefined | number>(earned)

  const availableVotes = useMemo(() => {
    return earned !== undefined ? earned - (spent || 0) : 0
  }, [spent, earned])

  let prev
  if (typeof window !== 'undefined') prev = window?.history?.state?.prev

  const prismicSiteSettings = getLocalizedData(pathname, externalData?.allPrismicSiteSettings)

  useEffect(() => {
    if (!prismicSiteSettings) return
    setCopy({ available_votes: prismicSiteSettings.data.available_votes })
  }, [setCopy, prismicSiteSettings])

  const { navigationLinks, lang, title, popupText, popupButton } = useMemo(() => {
    if (!prismicSiteSettings) {
      return {
        navigationLinks: [],
        lang: defaultLanguage,
        title: undefined,
        popupText: undefined,
        popupButton: undefined,
      }
    }

    return {
      navigationLinks: prismicSiteSettings.data.navigation_links,
      lang: prismicSiteSettings.lang,
      title: prismicSiteSettings.data.navigation_title,
      popupText: prismicSiteSettings.data.navigation_popup_text,
      popupButton: prismicSiteSettings.data.navigation_popup_button_label,
    }
  }, [prismicSiteSettings])

  useEffect(() => {
    if (isInitialLoad && earned && !hasAnimated) setHasAnimated(earned)
  }, [isInitialLoad, earned, hasAnimated])

  useEffect(() => {
    let timerId: NodeJS.Timer | undefined
    if (animate) timerId = setTimeout(() => setAnimate(false), 1000)

    return () => {
      if (timerId) clearTimeout(timerId)
    }
  }, [animate])

  useEffect(() => {
    if (earned !== undefined) {
      if (animate === undefined) {
        setAnimate(false)
      } else if ((isCardsLayoutFullyVisible || !hasCards) && hasAnimated !== earned) {
        setHasAnimated(earned)
        setAnimate(true)
      }
    }
  }, [earned, hasAnimated, isCardsLayoutFullyVisible, hasCards, animate])

  const activeIndex = useMemo(
    () =>
      navigationLinks.findIndex(i => {
        const uid = i.link.type === 'homepage' ? '' : i.link.uid
        const path = getLocalizedPath(uid, lang)
        /* Fact pages should be treated as `homepage` */
        const currentPath = pageType === 'fact' ? '' : pathname.replace(/\/$/, '')
        return path.replace(/\/$/, '') === currentPath /* Match with/without trailing slash */
      }),
    [navigationLinks, pathname, lang, pageType],
  )

  const handleClick = useCallback(
    (e, to) => {
      navigate(to, { state: { prev: activeIndex } })
      e.preventDefault()
    },
    [activeIndex],
  )

  const solutions = navigationLinks?.find(l => l.link?.type === 'solutions_page')?.link?.uid

  return (
    <Container
      as='header'
      className={cn('header', 'headerMain', {
        fixed,
        visible: isCardsLayoutFullyVisible,
        initiallyHide,
      })}
      onAnimationEnd={() => setInitiallyHide(false)}
      {...(factPage && { 'aria-hidden': 'true' })}
    >
      <nav className={cn('content')}>
        <Clickable to='/' className={cn('logo')} {...(factPage && { tabIndex: -1 })}>
          <MonumentNavigation as='h2'>{title}</MonumentNavigation>
        </Clickable>
        <div
          className={cn('icons')}
          style={
            {
              '--indicator-width': `${100 / navigationLinks.length}%`,
            } as React.CSSProperties
          }
        >
          {navigationLinks.map((item, index) => {
            const to = getLocalizedPath(item.link.type === 'homepage' ? '' : item.link.uid, lang)
            return (
              <NavigationLink
                key={`icon-${index}`}
                to={to}
                item={item}
                onClick={e => handleClick(e, to)}
                animate={animate}
                availableVotes={availableVotes}
                {...(factPage && { tabIndex: -1 })}
              />
            )
          })}
          {activeIndex !== -1 && (
            <motion.div
              className={cn('indicator')}
              animate={{
                x: `calc(${activeIndex} * 100%)`,
              }}
              {...(prev && {
                initial: {
                  x: `calc(${prev} * 100%)`,
                },
              })}
            >
              <div />
            </motion.div>
          )}
          <VotesPopup
            show={!!animate && availableVotes !== 0 && (earned || 0) <= 3}
            to={getLocalizedPath(solutions || '', lang)}
            label={popupButton}
          >
            {popupText}
          </VotesPopup>
        </div>
      </nav>
    </Container>
  )
}

export default Header
